import theme from "../../Theme"

export const styles = {

    DropBox: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
        },
        gap: "20px",
        padding: {
            xs: "20px",
            sm: "20px",
            md: "20px",
            lg: "20px 30px",
        },
        borderRadius: "20px",
        border: `1px solid ${theme.palette.primary.borderColor}`,
        backgroundColor: theme.palette.primary.Lightbg2,
        margin: "20px 0px",
    },
    DropLeftArea: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "20px",
    },
    DropImg: {
        width: {
            xs: '100px',
            sm: '100px',
            md: '130px',
            lg: '130px',
        },
        height: {
            xs: '100px',
            sm: '100px',
            md: '120px',
            lg: '120px',
        },
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
    },
    DropContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "space-between",
        flexDirection: "column",
        gap: "10px",
    },
    DropBoxTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    DropBoxSubTitle: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },
    DropSubTIme: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },
    DropBtn: {
        width: {
            xs: '100%',
            sm: '100%',
            md: 'auto',
            lg: 'auto',
        },
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: {
            xs: '8px 10px',
            sm: '8px 10px',
            md: '10px 20px',
            lg: '10px 20px',
        },
        borderRadius: '8px',
        display: "flex",
        justifyContent: "center",
        border: `1px solid ${theme.palette.primary.logoColor}`,
        gap: '10px',
        alignItems: "center",
        "&:hover": {
            backgroundColor: 'transparent',

        },
    },
    DropBoxBtnText: {
        color: theme.palette.primary.logoColor,
        fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
        },
        fontWeight: '600',
    },
    DropBtnIcon: {
        fontSize: {
            xs: '16px',
            sm: '16px',
            md: '20px',
            lg: '20px',
        },
        color: theme.palette.primary.white,
        height: {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '30px',
        },
        width: {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '30px',
        },
        borderRadius: {
            xs: '5px',
            sm: '5px',
            md: '5px',
            lg: '8px',
        },
        backgroundColor: theme.palette.primary.logoColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },


}