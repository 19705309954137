import React, { useEffect, useState } from 'react';
import CheckoutForm from '../pages/CheckOutForm/CheckOutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Loader, getClientSecret } from '../utils/common';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const PaymentForm = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [amount, setAmount] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("paymentCreate")) {
            navigate("/onboarding");
        }
    }, [navigate]);

    useEffect(() => {
        if (localStorage.getItem("paymentCreate") && !localStorage.getItem("stripeInitialized")) {
            const initializeStripe = async () => {
                try {
                    const stripe = await loadStripe('pk_test_CIptLw3IEqegLCqMu1FGcMHC');
                    setStripePromise(stripe);
                    const { client_secret, amount } = await getClientSecret();
                    setClientSecret(client_secret);
                    setAmount(amount);
                } catch (error) {
                    console.error("Error fetching client secret:", error);
                } finally {
                    setIsLoading(false);
                }
                localStorage.setItem("stripeInitialized", true);
            };
            initializeStripe();
        }
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm amount={amount} clientSecret={clientSecret} />
            </Elements>
        </div>
    );
};

export default PaymentForm;
