
import theme from "../../Theme"

export const styles = {
    paymentFormBoxOuter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
    },
    paymentFormBox: {
        width: {
            xs: "95%",
            sm: "60%",
            md: "60%",
            lg: "30%",
            xl: "30%",
        },
        padding: "30px",
        borderRadius: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        backgroundColor: '#ffffff36',
        backdropFilter: "blur(5px)",
    },

    submitBtn: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
        padding: "10px",
        width: "100%",
        marginTop: "25px",
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
            color: theme.palette.primary.main,
        }
    }

}