import React, { useState } from 'react';
import { Button, Box, Typography, Modal } from '@mui/material';
import { Icon } from '@iconify/react';
// import { FaShareAlt } from 'react-icons/fa'; // Example icon
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FaFacebook, FaTwitter, FaInstagram, FaTelegram } from 'react-icons/fa';
import SharePopup from './SharePopUp';

const ShareButton = ({ url, title }) => {
    const [open, setOpen] = useState(false);

    const handleShareClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button variant="contained" onClick={handleShareClick}>
                <Box>
                    {/* Your icon here */}
                </Box>
                <Typography>Share</Typography>
            </Button>
            <SharePopup url={url} open={open} onClose={handleClose} />
        </div>
    );
};

const Share = () => {
    return (
        <div>
            <ShareButton url="https://example.com" title="Example Title" />
        </div>
    );
};

export default Share;
