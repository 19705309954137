import React from 'react'
import { Box, Typography } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';

function CollectionsSliderItem({ CollectionSrc, CollectionAltText, CollectionItemTitle, CollectionItemSubTitle }) {
    return (
        <Box className="revealator-zoomin revealator-duration15 revealator-delay8" >
            <Box sx={styles.CollectionSliderItem}>
                <Box sx={styles.CollectionItem}>
                    <Box sx={styles.CollectionItemImg}>
                        <Box
                            component={"img"}
                            alt={CollectionAltText}
                            src={CollectionSrc}
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                        <Box sx={styles.pleyIcon}>
                            <Icon icon="gravity-ui:play-fill" />
                        </Box>
                    </Box>
                    <Box sx={styles.CollectionItemInner}>
                        <Typography variant="h6" sx={styles.CollectionItemTitle}>{CollectionItemTitle}</Typography>
                        <Typography variant="p" component={"p"} sx={styles.CollectionItemSubTitle}>{CollectionItemSubTitle}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CollectionsSliderItem