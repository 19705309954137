import { IconButton } from '@mui/material'
import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import theme from '../Theme';

const SocialLink = ({ artist }) => {
    return (
        <>
            {artist?.artist?.facebook_link &&
                <a href={artist.artist.facebook_link} target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `1px solid ${theme.palette.primary.logoColor}`,
                        borderRadius: '50%',
                        height: {
                            xs: '40px',
                            sm: '40px',
                            md: '50px',
                            lg: '50px',
                        },
                        width: {
                            xs: '40px',
                            sm: '40px',
                            md: '50px',
                            lg: '50px',
                        },
                        padding: '10px',
                        color: theme.palette.primary.logoColor,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.logoColor,
                            color: "#fff"
                        }
                    }}>
                        <FacebookOutlinedIcon sx={{
                            fontSize: {
                                xs: '20px',
                                sm: '20px',
                                md: '30px',
                                lg: '30px',
                            },
                        }} />
                    </IconButton>
                </a>
            }
            {artist?.artist?.instagram_link &&
                <a href={artist.artist.instagram_link} target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `1px solid ${theme.palette.primary.logoColor}`,
                        borderRadius: '50%',
                        height: {
                            xs: '40px',
                            sm: '40px',
                            md: '50px',
                            lg: '50px',
                        },
                        width: {
                            xs: '40px',
                            sm: '40px',
                            md: '50px',
                            lg: '50px',
                        },
                        padding: '10px',
                        color: theme.palette.primary.logoColor,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.logoColor,
                            color: "#fff"
                        }
                    }}>
                        <InstagramIcon sx={{
                            fontSize: {
                                xs: '20px',
                                sm: '20px',
                                md: '30px',
                                lg: '30px',
                            },
                        }} />
                    </IconButton>
                </a>
            }
            {artist?.artist?.twitter_link &&
                <a href={artist.artist.twitter_link} target="_blank" rel="noopener noreferrer">
                    <IconButton sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `1px solid ${theme.palette.primary.logoColor}`,
                        borderRadius: '50%',
                        height: {
                            xs: '40px',
                            sm: '40px',
                            md: '50px',
                            lg: '50px',
                        },
                        width: {
                            xs: '40px',
                            sm: '40px',
                            md: '50px',
                            lg: '50px',
                        },
                        padding: '10px',
                        color: theme.palette.primary.logoColor,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.logoColor,
                            color: "#fff"
                        }
                    }}>
                        <TelegramIcon sx={{
                            fontSize: {
                                xs: '20px',
                                sm: '20px',
                                md: '30px',
                                lg: '30px',
                            },
                        }} />
                    </IconButton>
                </a>
            }
            {/* <IconButton sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${theme.palette.primary.logoColor}`,
            borderRadius: '50%',
            height: {
              xs: '40px',
              sm: '40px',
              md: '50px',
              lg: '50px',
            },
            width: {
              xs: '40px',
              sm: '40px',
              md: '50px',
              lg: '50px',
            },
            padding: '10px',
            color: theme.palette.primary.logoColor,
            "&:hover": {
              backgroundColor: theme.palette.primary.logoColor,
              color: "#fff"
            }
          }}>
            <WhatsAppIcon sx={{
              fontSize: {
                xs: '20px',
                sm: '20px',
                md: '30px',
                lg: '30px',
              },
            }} />
          </IconButton> */}
        </>
    )
}

export default SocialLink