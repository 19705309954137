import { createSlice } from '@reduxjs/toolkit';


const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            // console.log('setCurrentUser dispatched with payload:', action.payload);
            // console.log('Current user set ===> ', state.currentUser);
            localStorage.setItem("currentUser", JSON.stringify(state?.currentUser))
        },
        clearCurrentUser: (state) => {
            state.currentUser = null;
            localStorage.removeItem("currentUser")
            // console.log(state.currentUser)
            // console.log('clearCurrentUser dispatched');
        },
        updateCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            // console.log('updateCurrentUser dispatched with payload:', action.payload);
        },
        updateCurrentUserAlbum: (state, action) => {
            state.currentUser = {
                ...state.currentUser,
                album: action.payload,
            };
        },
    }
});

export const { setCurrentUser, clearCurrentUser, updateCurrentUser, updateCurrentUserAlbum } = userSlice.actions;
export const selectCurrentUser = (state) => state.user.currentUser;


export default userSlice.reducer;
