import React from "react"
import { Box, Button, CircularProgress, Container, Typography } from "@mui/material"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import theme from "../../Theme"
import MusicPlayer from "../../components/MusicPlayer"
import { useLocation } from "react-router-dom"
import { GET_ARTIST_ALBUM, IMAGE_URL } from "../../url"
import axios from "axios"
import { Loader } from "../../utils/common"

const Mint = () => {

  const [isLoading, setIsLoading] = React.useState(true);

  const [artistAlbumTrackDetails, setArtistAlbumTrackDetails] = React.useState(null);
  const [time, setTime] = React.useState({ hours: 0, minutes: 0, seconds: 0 });
  const musicPlayerRef = React.useRef(null);

  // console.log('musicPlayerRef', musicPlayerRef);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const artistAlbumId = queryParams.get('id');

  // console.log('artistAlbumId', artistAlbumId);

  React.useEffect(() => {
    const _getArtist = async () => {
      await axios.get(GET_ARTIST_ALBUM + artistAlbumId)
        .then(res => {
          console.log("Artist Album and Track Details ==> ", res.data);
          setArtistAlbumTrackDetails(res.data);
          if (res.data.album.is_launch_date == 1) {
            setTime(res.data.album.remaining_time);
          }
        })
        .catch(err => {
          // console.log(err)
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
    _getArtist();
    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime) {
          let { hours, minutes, seconds } = prevTime;

          // Decrement seconds
          seconds--;

          // Adjust minutes and seconds if needed
          if (seconds < 0) {
            seconds = 59;
            minutes--;

            if (minutes < 0) {
              minutes = 59;
              hours--;

              if (hours < 0) {
                // Timer reached zero, clear interval
                clearInterval(timer);
              }
            }
          }

          return { hours, minutes, seconds };

        }

      });
    }, 1000);

    // Cleanup function to clear interval
    return () => clearInterval(timer);
  }, [artistAlbumId])

  const getAlbumImage = (artist_image) => {
    if (artist_image) {

      const coverImage = artist_image.split('/');
      coverImage.splice(0, 1);
      // console.log("coverImage ==> ", coverImage);
      //  coverImage.splice(1, 0, "public");

      const coverImageStr = coverImage.join('/');
      // console.log("coverImageStr ==> ", coverImageStr);
      return IMAGE_URL + coverImageStr
    }

    return null
  }


  return (
    <>
      {isLoading ?
        <Loader /> :
        <>
          {artistAlbumTrackDetails ?
            <Container>
              <Header />
              <Box sx={{
                padding: {
                  xs: "50px 0px 150px",
                  sm: "100px 0px",
                },
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
                component={"section"}
              >
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "73%",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "28px",
                        sm: "28px",
                        md: "54px",
                        lg: "54px",

                      },
                      fontWeight: "700",
                      lineHeight: {
                        xs: "39.5px",
                        sm: "39.5px",
                        md: "75.5px",
                        lg: "75.5px",
                      },
                      color: theme.palette.primary.logoColor,
                      textAlign: "center",
                      letterSpacing: "-2px",
                    }}
                  >
                    <span className="textLine">Earn $STAGE</span> tokens and get access to live performances
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "22px",
                        lg: "22px",
                      },
                      fontWeight: "400",
                      lineHeight: "28px",
                      color: theme.palette.primary.textColor,
                      textAlign: "center",
                      marginTop: {
                        xs: "20px",
                        sm: "40px",
                      },

                    }}
                  >Gain access to live events, concerts, festivals and other perks, simply by purchasing music from your favourite artists.</Typography>

                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "22px",
                        lg: "20px",
                      },
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: theme.palette.primary.logoColor,
                      textAlign: "center",
                      marginTop: {
                        xs: "0px",
                        sm: "40px",
                      },
                      position: {
                        xs: "fixed",
                        sm: "initial",
                      },
                      top: {
                        xs: "10px",
                        sm: "initial",
                      },
                      left: {
                        xs: "50%",
                        sm: "initial",
                      },
                      transform: {
                        xs: "translateX(-50%)",
                        sm: "initial",
                      },
                      zIndex: "100",
                    }}
                  >
                    {artistAlbumTrackDetails?.album?.is_launch_date == 1 ?
                      <>
                        {artistAlbumTrackDetails?.album?.remaining_time?.days > 0 && <>{artistAlbumTrackDetails?.album?.remaining_time?.days} days and </>}
                        {time != null && <>{time.hours} : {time.minutes} : {time.seconds} </>}
                        Left
                      </>
                      :
                      <>No expiration time</>
                    }
                  </Typography>
                  <Box
                    className="TopLeftStar"
                    component={"img"}
                    src={"/assets/images/starts.png"}
                    alt="TopStar"
                    sx={{
                      width: {
                        xs: "40px",
                        sm: "60px",
                      },
                      height: [
                        "40px",
                        "60px",
                      ],
                      objectFit: "contain",
                      position: "absolute",
                      top: "0",
                      left: "3%",
                      rotate: {
                        xs: "180deg",
                        sm: "0deg",
                      },
                    }}
                  />
                  <Box
                    className=" bottomLeftStar"
                    component={"img"}
                    src={"/assets/images/starts.png"}
                    alt="TopStar"
                    sx={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      position: "absolute",
                      bottom: "5%",
                      left: "15%",
                      display: {
                        xs: "none",
                        sm: "block"
                      }

                    }}
                  />
                  <Box
                    className=" bottomLeftStar"
                    component={"img"}
                    src={"/assets/images/mobStars.png"}
                    alt="TopStar"
                    sx={{
                      width: {
                        xs: "50px",
                        sm: "60px",
                      },
                      height: {
                        xs: "100px",
                        sm: "60px",
                      },
                      objectFit: "contain",
                      position: "absolute",
                      bottom: "10%",
                      left: "3%",
                      display: {
                        xs: "block",
                        sm: "none"
                      }
                    }}
                  />
                  <Box
                    className="bottomRightStar"
                    component={"img"}
                    src={"/assets/images/starts.png"}
                    alt="TopStar"
                    sx={{
                      width: "60px",
                      height: "60px",
                      objectFit: "contain",
                      position: "absolute",
                      bottom: "25%",
                      right: "0%",
                      display: {
                        xs: "none",
                        sm: "block"
                      }
                    }}
                  />
                  <Box
                    className="bottomRightStar"
                    component={"img"}
                    src={"/assets/images/mobStars.png"}
                    alt="TopStar"
                    sx={{
                      width: {
                        xs: "50px",
                        sm: "60px",
                      },
                      height: {
                        xs: "100px",
                        sm: "60px",
                      },
                      objectFit: "contain",
                      position: "absolute",
                      bottom: "18%",
                      right: "0%",
                      display: {
                        xs: "block",
                        sm: "none"
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{
                padding: {
                  xs: "50px 0px",
                  sm: "100px 0px",
                },
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
                component={"section"}
                ref={musicPlayerRef}
              >
                <MusicPlayer
                  artistAlbumTrackDetails={artistAlbumTrackDetails}
                  className={"MintMusicPlayer"}
                  id={"MintMusicPlayer"}
                  time={time}
                />
              </Box>
              <Box sx={{
                padding: {
                  xs: "50px 0px",
                  sm: "100px 0px",
                },
                position: "relative",
                flexDirection: {
                  xs: "column",
                  sm: "row",

                },
                display: "flex",
                justifyContent: "center",
                gap: {
                  xs: "20px",
                  sm: "50px",
                },
              }}
                component={"section"}
              >
                <Box sx={{
                  width: {
                    xs: "100%",
                    sm: "40%",
                  },
                }} >
                  <Box
                    className="BenefitsImg"
                    component={"img"}
                    src={artistAlbumTrackDetails?.artist?.picture ? getAlbumImage(artistAlbumTrackDetails.artist.picture) : "/assets/images/album.png"}
                    alt="TopStar"
                    sx={{
                      width: "100%",
                      height: {
                        xs: "566px",
                        sm: "100%",

                      },
                      objectFit: "cover",
                      borderRadius: "30px"
                    }}
                  />
                </Box>
                <Box sx={{
                  width: {
                    xs: "100%",
                    sm: "60%",
                  },
                  height: "100%",
                }} >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "22px",
                        sm: "22px",
                        md: "54px",
                        lg: "54px",
                      },
                      fontWeight: "700",
                      lineHeight: {
                        xs: "31.79px",
                        sm: "31.79px",
                        md: "75.5px",
                        lg: "75.5px",
                      },
                      color: theme.palette.primary.logoColor,
                      letterSpacing: "-2px",
                      textAlign: {
                        xs: "center",
                        sm: "left",
                      },
                    }}
                  >
                    Exclusive $STAGE Benefits
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "22px",
                        lg: "22px",
                      },
                      fontWeight: "400",
                      lineHeight: {
                        xs: "24px",
                        sm: "28px",

                      },
                      textAlign: {
                        xs: "center",
                        sm: "left",
                      },
                      color: theme.palette.primary.textColor,
                      marginTop: "10px",
                    }}
                  >Gain exclusive access to live events, concerts, festivals, and a host of other premium perks simply by purchasing music from your beloved artists.</Typography>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "30px",
                    gap: "20px",
                    width: "100%",
                  }} component={"ul"}>
                    <Box sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "40px 1fr",
                        sm: "40px 1fr",
                        md: "80px 1fr",
                        lg: "80px 1fr",
                      },
                      alignItems: "center",
                      gap: "20px",
                    }}
                      component={"li"}>
                      <Box sx={{
                        width: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        height: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.tickBg,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <Box
                          component={"img"}
                          src={"/assets/images/tick.png"}
                          alt="checked"
                          sx={{
                            width: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            height: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "22px",
                            lg: "22px",
                          },
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: theme.palette.primary.textColor,

                        }}
                      >Gain priority access to meet-and-greet sessions with your favourite artists.</Typography>

                    </Box>
                    <Box sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "40px 1fr",
                        sm: "40px 1fr",
                        md: "80px 1fr",
                        lg: "80px 1fr",
                      },
                      alignItems: "center",
                      gap: "20px",
                    }}
                      component={"li"}>
                      <Box sx={{
                        width: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        height: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.tickBg,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <Box
                          component={"img"}
                          src={"/assets/images/tick.png"}
                          alt="checked"
                          sx={{
                            width: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            height: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "22px",
                            lg: "22px",
                          },
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: theme.palette.primary.textColor,

                        }}
                      >Receive early access to ticket sales for upcoming concerts and events.</Typography>

                    </Box>
                    <Box sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "40px 1fr",
                        sm: "40px 1fr",
                        md: "80px 1fr",
                        lg: "80px 1fr",
                      },
                      alignItems: "center",
                      gap: "20px",
                    }}
                      component={"li"}>
                      <Box sx={{
                        width: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        height: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.tickBg,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <Box
                          component={"img"}
                          src={"/assets/images/tick.png"}
                          alt="checked"
                          sx={{
                            width: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            height: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "22px",
                            lg: "22px",
                          },
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: theme.palette.primary.textColor,

                        }}
                      >Enjoy special discounts on artist merchandise and limited edition releases.</Typography>

                    </Box>
                    <Box sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "40px 1fr",
                        sm: "40px 1fr",
                        md: "80px 1fr",
                        lg: "80px 1fr",
                      },
                      alignItems: "center",
                      gap: "20px",
                    }}
                      component={"li"}>
                      <Box sx={{
                        width: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        height: {
                          xs: "40px",
                          sm: "40px",
                          md: "80px",
                          lg: "80px",
                        },
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.tickBg,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <Box
                          component={"img"}
                          src={"/assets/images/tick.png"}
                          alt="checked"
                          sx={{
                            width: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            height: {
                              xs: "20px",
                              sm: "20px",
                              md: "40px",
                              lg: "40px",
                            },
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                            sm: "16px",
                            md: "22px",
                            lg: "22px",
                          },
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: theme.palette.primary.textColor,

                        }}
                      >Receive special invitations to intimate acoustic sessions, private listening parties, or exclusive album launches.</Typography>

                    </Box>

                  </Box>
                  <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "flex-start",
                    },
                    alignItems: "center",
                    marginTop: {
                      xs: "30px",
                      sm: "40px",
                    },
                  }}>
                    <Button
                      sx={{
                        padding: "15px 40px",
                        borderRadius: "10px",
                        backgroundColor: theme.palette.primary.logoColor,
                        color: theme.palette.primary.textColor,
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        textTransform: "none",
                        maxWidth: {
                          xs: "245px",
                          sm: "280px",
                        },
                        minWidth: {
                          xs: "auto",
                          sm: "280px",
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.primary.logoColor,
                        }
                      }}
                      onClick={() => {
                        musicPlayerRef.current.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: theme.palette.primary.white,
                        }}
                      >
                        Earn $STAGE tokens
                      </Typography>
                    </Button>
                  </Box>

                </Box>
              </Box>
            </Container> :
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}>
              <Typography sx={{
                fontSize: {
                  xs: '20px',
                  sm: '20px',
                  md: '26px',
                  lg: '26px',
                },
                fontWeight: '600',
                lineHeight: "1",
                color: theme.palette.primary.logoColor,
                marginTop: '20px'
              }} component={"h2"}>No Album Found</Typography>
            </Box>


          }

          <Footer />
        </>
      }

    </>
  )
}

export default Mint
