// Desc: Artist Information Page
import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../Theme";
import { ArtistInformationFormSingle, ArtistInformationFormAlbum } from "../../components/ArtistInformationForm";
import { selectCurrentUser } from "../../redux/reducers/userSlice";
import { selectCurrentArtist } from "../../redux/reducers/artistSlice";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../url";
import { Loader } from "../../utils/common";
import { styles } from "./style"

const ArtistInformation = () => {

  const [artistInformation, setArtistInformation] = useState(false);
  const [packageInformation, setPackageInformation] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [singleTrack, setSingleTrack] = useState(null);
  const [multipleTracks, setMultipleTracks] = useState({});

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    releaseType: "single",
    tracks: [{
      track_no: 1,
      track_name: "",
      track_duration: "",
      track_url: ""
    }],
    albumImage: null,
    albumName: "",
    songTitle: "",
    songDuration: "",
    bio: "",
    genre: "",
    copyrightHolder: "",
    musicMetadataVersion: "",
  });

  const getAlbumImage = (album_image) => {
    if (album_image) {
      // console.log("albumImage", album_image);
      const coverImage = album_image.split('/');
      // console.log("coverImage ==> ", coverImage);
      // coverImage.splice(1, 0, "storage");

      const coverImageStr = coverImage.join('/');
      // console.log("coverImageStr ==> ", IMAGE_URL + coverImageStr);
      return IMAGE_URL + coverImageStr
    }

    return null
  }

  const currentUser = useAppSelector(selectCurrentUser);

  const navigate = useNavigate();

  const currentArtist = useAppSelector(selectCurrentArtist);
  // console.log("current Artist", currentArtist)
  // console.log(IMAGE_URL + currentArtist?.artist?.picture)
  // console.log('tracks in Artist Information ==> ', tracks);
  useEffect(() => {
    // console.log("artist Information Page Loaded.........")
  }, [])
  useEffect(() => {
    if (currentArtist) {
      // console.log("Current Artist in Artist Information ==> ", currentArtist);
      if (currentArtist?.album?.album_type === 0) {
        setFormData(prevFormData => ({
          ...prevFormData,
          releaseType: "single",
        }));

        if (currentArtist?.tracks[0]?.track_name) {
          // console.log("name===> ", currentArtist?.tracks[0]?.track_name)
          setFormData(prevFormData => ({
            ...prevFormData,
            songTitle: currentArtist?.tracks[0]?.track_name,
          }));
        }

        if (currentArtist?.tracks[0]?.track_duration) {
          setFormData(prevFormData => ({
            ...prevFormData,
            songDuration: currentArtist?.tracks[0]?.track_duration,
          }));
        }

        if (currentArtist?.album?.genre) {
          // console.log(currentArtist?.album?.genre)
          // console.log(currentArtist?.tracks[0]?.genre)
          setFormData(prevFormData => ({
            ...prevFormData,
            genre: currentArtist?.album?.genre,
          }));
        }

        if (currentArtist?.album?.copyright_holder) {
          setFormData(prevFormData => ({
            ...prevFormData,
            copyrightHolder: currentArtist?.album?.copyright_holder,
          }));
        }

        if (currentArtist?.album?.music_metadata) {
          setFormData(prevFormData => ({
            ...prevFormData,
            musicMetadataVersion: currentArtist?.album?.music_metadata,
          }));
        }

        

        if (currentArtist?.tracks[0]?.track_file) {
          setSingleTrack(currentArtist?.tracks[0]?.track_file);
        }

      } else if (currentArtist?.album?.album_type === 1) {
        setFormData(prevFormData => ({
          ...prevFormData,
          releaseType: "album",
        }));

        if (currentArtist?.tracks.length > 0) {
          setFormData(prevFormData => ({
            ...prevFormData,
            tracks: currentArtist?.tracks.map((track, index) => ({
              track_no: index + 1,
              track_name: track.track_name,
              track_duration: track.track_duration,
            })),

          }));
        }

        if (currentArtist?.album?.genre) {
          setFormData(prevFormData => ({
            ...prevFormData,
            genre: currentArtist?.album?.genre,
          }));
        }

        if (currentArtist?.album?.copyright_holder) {
          setFormData(prevFormData => ({
            ...prevFormData,
            copyrightHolder: currentArtist?.album?.copyright_holder,
          }));
        }

        if (currentArtist?.album?.music_metadata) {
          setFormData(prevFormData => ({
            ...prevFormData,
            musicMetadataVersion: currentArtist?.album?.music_metadata,
          }));
        }

        if (currentArtist?.tracks.length > 0) {
          currentArtist.tracks.forEach((track, index) => {
            if (track.track_file) {
              setMultipleTracks(prevTracks => ({
                ...prevTracks,
                [index]: track.track_file,
              }));
            }
          });
        }

        if (currentArtist?.album?.album_name) {
          setFormData(prevFormData => ({
            ...prevFormData,
            albumName: currentArtist.album.album_name,
          }));
        }

      }

      if (currentArtist?.album?.album_image) {
        setFormData(prevFormData => ({
          ...prevFormData,
          albumImage: getAlbumImage(currentArtist.album.album_image),
        }));
      }

      if (currentArtist?.album?.bio) {
        // console.log("bio >>>>> ",currentArtist?.album?.bio)
        setFormData(prevFormData => ({
          ...prevFormData,
          bio: currentArtist.album.bio,
        }));
      };


    }
  }, [currentArtist]);

  useEffect(() => {
    // console.log("currentUser in Artist Information ==> ", currentUser);
    if (!currentUser) {
      navigate('/');
    } else {
      setArtistInformation(true);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (packageInformation)
      navigate('/package');

    // console.log("packageInformation in onboarding ==> ", packageInformation);
  }, [artistInformation, packageInformation, navigate])

  const handleFormDataChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio" && !checked) {
      return;
    } else if (type === "radio") {
      // console.log("Radio button clicked", formData);
      setFormData({
        tracks: [{
          track_no: 1,
          track_name: "",
          track_duration: "",
        }],
        songTitle: "",
        albumName: "",
        songDuration: "",
        genre: "",
        bio: "",
        copyrightHolder: "",
        musicMetadataVersion: "",
        releaseType: value,
      })

      const updatedErrors = {};

      setErrors(updatedErrors);

      return;
    }

    setFormData({ ...formData, [name]: value });
  }


  return (
    <>
      {isLoading ?
        <Loader /> :
        <>
          <Box>
            <Container maxWidth="xl" sx={{ height: "100%" }}>
              <Box sx={styles.Header}>
                <Box sx={styles.logoContainer} href="#" component={"a"}
                // className="revealator-slideright revealator-duration15 revealator-delay5"
                >
                  <Box sx={styles.logo}
                    component={"img"}
                    src={"/assets/images/logo.png"} alt="Logo"
                  />
                </Box>

              </Box>

            </Container >
          </Box>

          <Container maxWidth="xl"
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box
              sx={styles.artistInfoPageContainer}
            >
              {/* Upload your Metadata Part  */}
              <Box>
                <Box
                  sx={styles.UploadMetaDataText}
                >
                  <Typography
                    variant="h4"
                    sx={styles.UploadMetaDataText}
                  >
                    Upload your Metadata
                  </Typography>
                </Box>

                <Box
                  sx={styles.pageInfoSection}
                >
                  {/* buttons here */}
                  <Box
                    sx={styles.topInfoButtonsContainer}
                  >
                    <Button
                      variant="outlined"
                      sx={styles.artistInformationButton}
                    >
                      <Box
                        sx={styles.pageNumContainer}>
                        <Typography
                          sx={styles.pageNumberText}
                        >1</Typography>
                      </Box>

                      <Typography
                        sx={styles.artistInfoText}>
                        Artist Information
                      </Typography>
                    </Button>
                    <Box
                      sx={{
                        width: "50px",
                        height: "0px",
                        borderBottom: "2px dashed",
                        borderColor: theme.palette.primary.logoColor,
                        opacity: 0.5,
                      }}>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={styles.headerPageInfo}
                    >
                      <Box
                        sx={styles.pageNumContainer}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.logoColor,
                            fontWeight: 600,
                            fontSize: '16px',
                          }}
                        >2</Typography>
                      </Box>

                      <Typography
                        sx={styles.paymentText}>Payment</Typography>
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={styles.mainContentbox}>
                  <Box
                    sx={styles.enterAllYourInfoContainer}>
                    <Typography
                      variant="h4"
                      component={"h3"}
                      sx={styles.enterAllYourInfoText}
                    >
                      Enter all your information
                    </Typography>
                  </Box>

                  {/* form box  */}
                  <Box
                    sx={styles.formContainer}>
                    <Box sx={styles.formContainerInnerBox}>
                      <FormControl fullWidth
                      >
                        <FormLabel sx={styles.releaseTypeFormLable} id="demo-row-radio-buttons-group-label">Select Release Type</FormLabel>
                        <RadioGroup
                          sx={styles.artistInfoRadioGroup}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="releaseType"
                        >
                          <FormControlLabel className="radioButton" sx={[styles.formControlLable, {
                            borderColor: formData.releaseType === 'album' ? theme.palette.primary.logoColor : theme.palette.primary.logoColor30,
                            color: formData.releaseType === 'album' ? theme.palette.primary.logoColor : theme.palette.primary.LightText3,
                          }]}
                            value="single"
                            checked={formData.releaseType === "single"}
                            onChange={handleFormDataChange}
                            control={<Radio sx={{
                              color: formData.releaseType === 'single' ? theme.palette.primary.logoColor : theme.palette.primary.deselectedRadioColor,
                              '& .MuiSvgIcon-root': {
                                fontSize: 35,
                              },
                              '&.Mui-checked': {
                                color: theme.palette.primary.logoColor,
                              },
                              '&:hover': {
                                backgroundColor: 'transparent',
                              }
                            }} />}
                            label="Single"
                          />
                          <FormControlLabel className="radioButton"
                            sx={[styles.formControlLable, {
                              borderColor: formData.releaseType === 'album' ? theme.palette.primary.logoColor : theme.palette.primary.logoColor30,
                              color: formData.releaseType === 'album' ? theme.palette.primary.logoColor : theme.palette.primary.LightText3,
                            }]}

                            value="album"
                            checked={formData.releaseType === "album"}
                            onChange={handleFormDataChange}
                            control={<Radio sx={{
                              color: formData.releaseType === 'album' ? theme.palette.primary.logoColor : theme.palette.primary.deselectedRadioColor,
                              '& .MuiSvgIcon-root': {
                                fontSize: 35,
                              },
                              '&.Mui-checked': {
                                color: theme.palette.primary.logoColor,
                              },
                              '&:hover': {
                                backgroundColor: 'transparent',
                              }
                            }} />} label="Album"
                          />
                        </RadioGroup>
                      </FormControl>
                      {formData.releaseType === "single" ?
                        <ArtistInformationFormSingle
                          currentUser={currentUser}
                          formData={formData}
                          setFormData={setFormData}
                          setPackageInformation={setPackageInformation}
                          currentArtist={currentArtist}
                          singleTrack={singleTrack}
                          setSingleTrack={setSingleTrack}
                          setIsLoading={setIsLoading}
                          errors={errors}
                          setErrors={setErrors}
                        /> :
                        <ArtistInformationFormAlbum
                          currentUser={currentUser}
                          formData={formData}
                          setFormData={setFormData}
                          setPackageInformation={setPackageInformation}
                          currentArtist={currentArtist}
                          multipleTracks={multipleTracks}
                          setMultipleTracks={setMultipleTracks}
                          initialTracksLength={currentArtist?.tracks?.length}
                          setIsLoading={setIsLoading}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      }
                    </Box>


                  </Box>

                </Box>

              </Box>
              {/* Upload your Metadata part end */}

            </Box>
          </Container>
        </>
      }


    </>
  );
};


export default ArtistInformation;
