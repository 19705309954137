import React from 'react'
import { styles } from './style'
import { Box, Container, Typography } from '@mui/material'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import theme from "../../Theme";
import { Icon } from '@iconify/react';
import Header from '../../components/Header';

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <Box sx={styles.nextArrow}
            onClick={onClick}
        ><Icon icon="flowbite:angle-right-outline" /></Box>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <Box style={styles.prevArrow}
            onClick={onClick}
        ><Icon icon="flowbite:angle-left-outline" /></Box>
    );
}
const FanLogin = () => {
    const sliderOptions1 = {
        dots: true,
        arrows: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],

    }
    return (
        <>  <Header />
            <Box sx={{
                padding: {
                    xs: '20px 0',
                    sm: '20px 0',
                    md: '20px 0',
                    lg: '70px 0',
                },
            }
            
            }>
                <Container maxWidth="xl" sx={{ height: '100%' }}>
                    <Box className="" sx={styles.MainRow}>
                        <Box sx={{
                            width:
                            {
                                xs: '100%',
                                sm: '80%',
                                md: '50%',
                                lg: '50%',
                            },
                            padding:
                            {
                                xs: '0px',
                                sm: '0px',
                                md: '30px',
                                lg: '50px',
                            },
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'block',
                                lg: 'block',
                            }

                        }}>
                            <Slider {...sliderOptions1}>

                                <Box
                                    sx={{

                                    }}
                                // className="revealator-zoomin revealator-duration15 revealator-delay8"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '50px 20px 25px',
                                            // width: '335px',
                                            borderRadius: '3px',
                                            // minHeight: '440px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '400px',
                                                height: '400px',
                                                borderRadius: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: theme.palette.primary.Lightbg2,

                                            }}
                                        >

                                            <Box
                                                component={"img"}
                                                src={"/assets/images/slider1.png"} alt="Badge"
                                                sx={{
                                                    width: '265px',
                                                    height: '265px',
                                                }}
                                            />
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    lineHeight: '30px',
                                                    color: theme.palette.primary.logoColor,
                                                    textAlign: 'center',
                                                    margin: '18px 0 11px'
                                                }}
                                            >
                                                Easily make your music available to your fans around the world
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    color: theme.palette.primary.LightText3,
                                                    textAlign: 'center',
                                                    marginBottom: '24px'
                                                }}
                                            >
                                                Explore changes we’ve made.
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{

                                    }}
                                // className="revealator-zoomin revealator-duration15 revealator-delay8"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '50px 20px 25px',
                                            // width: '335px',
                                            borderRadius: '3px',
                                            // minHeight: '440px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '400px',
                                                height: '400px',
                                                borderRadius: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: theme.palette.primary.Lightbg2,

                                            }}
                                        >

                                            <Box
                                                component={"img"}
                                                src={"/assets/images/slider1.png"} alt="Badge"
                                                sx={{
                                                    width: '265px',
                                                    height: '265px',
                                                }}
                                            />
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    lineHeight: '30px',
                                                    color: theme.palette.primary.logoColor,
                                                    textAlign: 'center',
                                                    margin: '18px 0 11px'
                                                }}
                                            >
                                                Easily make your music available to your fans around the world
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    color: theme.palette.primary.LightText3,
                                                    textAlign: 'center',
                                                    marginBottom: '24px'
                                                }}
                                            >
                                                Explore changes we’ve made.
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{

                                    }}
                                // className="revealator-zoomin revealator-duration15 revealator-delay8"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '50px 20px 25px',
                                            // width: '335px',
                                            borderRadius: '3px',
                                            // minHeight: '440px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '400px',
                                                height: '400px',
                                                borderRadius: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: theme.palette.primary.Lightbg2,

                                            }}
                                        >

                                            <Box
                                                component={"img"}
                                                src={"/assets/images/slider1.png"} alt="Badge"
                                                sx={{
                                                    width: '265px',
                                                    height: '265px',
                                                }}
                                            />
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    lineHeight: '30px',
                                                    color: theme.palette.primary.logoColor,
                                                    textAlign: 'center',
                                                    margin: '18px 0 11px'
                                                }}
                                            >
                                                Easily make your music available to your fans around the world
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    color: theme.palette.primary.LightText3,
                                                    textAlign: 'center',
                                                    marginBottom: '24px'
                                                }}
                                            >
                                                Explore changes we’ve made.
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{


                                    }}
                                // className="revealator-zoomin revealator-duration15 revealator-delay8"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '50px 20px 25px',
                                            // width: '335px',
                                            borderRadius: '3px',
                                            // minHeight: '440px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '400px',
                                                height: '400px',
                                                borderRadius: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: theme.palette.primary.Lightbg2,

                                            }}
                                        >

                                            <Box
                                                component={"img"}
                                                src={"/assets/images/slider1.png"} alt="Badge"
                                                sx={{
                                                    width: '265px',
                                                    height: '265px',
                                                }}
                                            />
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    lineHeight: '30px',
                                                    color: theme.palette.primary.logoColor,
                                                    textAlign: 'center',
                                                    margin: '18px 0 11px'
                                                }}
                                            >
                                                Easily make your music available to your fans around the world
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    color: theme.palette.primary.LightText3,
                                                    textAlign: 'center',
                                                    marginBottom: '24px'
                                                }}
                                            >
                                                Explore changes we’ve made.
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{

                                    }}
                                // className="revealator-zoomin revealator-duration15 revealator-delay8"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '50px 20px 25px',
                                            // width: '335px',
                                            borderRadius: '3px',
                                            // minHeight: '440px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '400px',
                                                height: '400px',
                                                borderRadius: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: theme.palette.primary.Lightbg2,

                                            }}
                                        >

                                            <Box
                                                component={"img"}
                                                src={"/assets/images/slider1.png"} alt="Badge"
                                                sx={{
                                                    width: '265px',
                                                    height: '265px',
                                                }}
                                            />
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    lineHeight: '30px',
                                                    color: theme.palette.primary.logoColor,
                                                    textAlign: 'center',
                                                    margin: '18px 0 11px'
                                                }}
                                            >
                                                Easily make your music available to your fans around the world
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    color: theme.palette.primary.LightText3,
                                                    textAlign: 'center',
                                                    marginBottom: '24px'
                                                }}
                                            >
                                                Explore changes we’ve made.
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Box>
                            </Slider>
                        </Box>
                        <Box sx={{
                            width:
                            {
                                xs: '100%',
                                sm: '100%',
                                md: '50%',
                                lg: '50%',
                            },
                            padding:
                            {
                                xs: '20px',
                                sm: '20px',
                                md: '30px',
                                lg: '50px',
                            },

                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}>
                                <Box
                                    component={"img"}
                                    src={"/assets/images/logo.png"}
                                    alt="Logo"
                                    sx={{
                                        width: {
                                            xs: "104px",
                                            sm: "104px",
                                            md: "104px",
                                            lg: "139px",
                                        },
                                        height: {
                                            xs: "32px",
                                            sm: "32px",
                                            md: "32px",
                                            lg: "43px",
                                        },
                                        margin: '0 auto 30px'
                                    }}
                                />
                                <Typography
                                    sx={styles.Dashtitle}>Let’s Get You Started</Typography>

                                <Typography
                                    sx={styles.DashSubTitle}> Welcome! Login or Sign up to create a wallet </Typography>
                            </Box>
                            <Box component={"ul"} sx={styles.DashLIst}>
                                {/* <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon1.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon2.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon3.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon4.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box> */}
                                <Box component={"li"} sx={styles.DashLIstItem} >
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon5.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                {/* <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon6.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon7.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon8.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon9.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon10.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon11.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box> */}
                                <Box component={"li"} sx={styles.DashLIstItem}  >
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon12.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                {/* <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon13.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon14.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box>
                                <Box component={"li"} sx={styles.DashLIstItem}>
                                    <Box
                                        component={"img"}
                                        src={"/assets/images/icon15.png"}
                                        alt="Logo"
                                        sx={styles.DashLIstItemIcon}
                                    />
                                </Box> */}
                            </Box>

                        </Box>
                    </Box>

                </Container >
            </Box >

        </>
    )
}

export default FanLogin