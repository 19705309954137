import React from 'react'
import { styles } from './style'
import { Box, Button, Container, Typography } from '@mui/material'
import theme from "../../Theme";
import { Icon } from '@iconify/react';
import FansHeader from '../../components/FansHeader';
import MusicPlayerDemo from '../../components/MusicPlayerDemo';
import CollectionsSlider from '../../components/CollectionsSlider/CollectionsSlider';
import SwapModal from '../../components/SwapModal/SwapModal';



const FansDashboard = () => {


    return (
        <Box>
            {/* <SwapModal /> */}
            <Box sx={styles.headerSection}>
                <Container maxWidth="xl">
                    <FansHeader />
                </Container>
            </Box>
            <Box sx={styles.MainRow}>
                <Box sx={styles.sideDashboard}>
                    <Box sx={styles.dashBoardBx}>
                        <Box sx={styles.dashBoardIconBox}>
                            <Icon icon="mage:dashboard-fill" />
                        </Box>
                        <Typography variant="h6" sx={{ color: theme.palette.primary.logoColor }}>Dashboard</Typography>
                    </Box>
                </Box>
                <Container maxWidth="xl" sx={styles.XlContainer}>
                    <Box sx={styles.mainTitle}>
                        <Typography variant="h6" sx={styles.DashTitle}>Good Morning, Thompson 🌤️</Typography>
                        <Typography component={'p'} sx={styles.DashSubTitle}>Take a look at what’s happening with your collections today!</Typography>
                    </Box>
                    <Box sx={styles.CollectionsBox}>
                        <Typography variant="h6" sx={styles.CollectionsTitle}>Collections</Typography>
                        <Box sx={styles.CollectionSliderArea}>
                            <CollectionsSlider />
                        </Box>
                        <Box
                            // className="revealator-zoomin revealator-duration15 revealator-delay8"
                            sx={styles.mobCollectionArea}>
                            <Box sx={styles.mobCollectionSliderItem}>
                                <Box sx={styles.mobCollectionItem}>
                                    <Box sx={styles.mobCollectionItemImg}>
                                        <Box
                                            component={"img"}
                                            alt='CollectionImage'
                                            src={"/assets/images/music1.jpg"}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <Box sx={styles.mobpleyIcon}>
                                            <Icon icon="gravity-ui:play-fill" />
                                        </Box>
                                    </Box>
                                    <Box sx={styles.mobCollectionItemInner}>
                                        <Typography variant="h6" sx={styles.mobCollectionItemTitle}>$12,567.00</Typography>
                                        <Typography variant="p" component={"p"} sx={styles.mobCollectionItemSubTitle}>Total Earnings</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={styles.mobCollectionSliderItem}>
                                <Box sx={styles.mobCollectionItem}>
                                    <Box sx={styles.mobCollectionItemImg}>
                                        <Box
                                            component={"img"}
                                            alt='CollectionImage'
                                            src={"/assets/images/music1.jpg"}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <Box sx={styles.mobpleyIcon}>
                                            <Icon icon="gravity-ui:play-fill" />
                                        </Box>
                                    </Box>
                                    <Box sx={styles.mobCollectionItemInner}>
                                        <Typography variant="h6" sx={styles.mobCollectionItemTitle}>$12,567.00</Typography>
                                        <Typography variant="p" component={"p"} sx={styles.mobCollectionItemSubTitle}>Total Earnings</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={styles.mobCollectionSliderItem}>
                                <Box sx={styles.mobCollectionItem}>
                                    <Box sx={styles.mobCollectionItemImg}>
                                        <Box
                                            component={"img"}
                                            alt='CollectionImage'
                                            src={"/assets/images/music1.jpg"}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <Box sx={styles.mobpleyIcon}>
                                            <Icon icon="gravity-ui:play-fill" />
                                        </Box>
                                    </Box>
                                    <Box sx={styles.mobCollectionItemInner}>
                                        <Typography variant="h6" sx={styles.mobCollectionItemTitle}>$12,567.00</Typography>
                                        <Typography variant="p" component={"p"} sx={styles.mobCollectionItemSubTitle}>Total Earnings</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={styles.mobCollectionSliderItem}>
                                <Box sx={styles.mobCollectionItem}>
                                    <Box sx={styles.mobCollectionItemImg}>
                                        <Box
                                            component={"img"}
                                            alt='CollectionImage'
                                            src={"/assets/images/music1.jpg"}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <Box sx={styles.mobpleyIcon}>
                                            <Icon icon="gravity-ui:play-fill" />
                                        </Box>
                                    </Box>
                                    <Box sx={styles.mobCollectionItemInner}>
                                        <Typography variant="h6" sx={styles.mobCollectionItemTitle}>$12,567.00</Typography>
                                        <Typography variant="p" component={"p"} sx={styles.mobCollectionItemSubTitle}>Total Earnings</Typography>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>

                    </Box>
                    <Box sx={styles.CollectionsRow}>
                        <Box sx={styles.CollectionscolBox1}>
                            {/* //music player add here */}
                            <MusicPlayerDemo />
                        </Box>
                        <Box sx={styles.CollectionscolBox2}>
                            <Box sx={styles.eventSection}>
                                <Typography variant="h6" sx={styles.CollectionsTitle}>Latest Events</Typography>
                                {/* <Box component={'ul'} sx={styles.CollectionList}>
                                    <Box component={'li'} sx={styles.CollectionListItem}>
                                        <Box sx={styles.eventItem}>
                                            <Box sx={styles.eventItemImgBox}>
                                                <Box sx={styles.eventItemImg}>
                                                    <Box
                                                        component={"img"}
                                                        src={"/assets/images/music1.jpg"} alt="Badge"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemInnerBox}>
                                                <Box sx={styles.eventItemInner}>
                                                    <Typography variant="h6" sx={styles.eventItemTitle}>Twice as Tall concert at the 02 Arena on 10th march, 2024.</Typography>
                                                    <Box sx={styles.eventItemBtnGroup}>
                                                        <Button
                                                            sx={styles.eventItemBtn1}>
                                                            <Typography
                                                                sx={styles.eventItemBtn1Text} >
                                                                Buy Tickets
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            sx={styles.eventItemBtn2}>
                                                            <Typography
                                                                sx={styles.eventItemBtn2Text}>
                                                                Swap $Stage
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemTimeBox}>
                                                <Box sx={styles.eventItemTimeBoxOuter}>
                                                    <Box sx={styles.eventItemTimeBoxInner} />
                                                    <Typography variant="h6" sx={styles.eventItemTime}>15 mins ago</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box component={'li'} sx={styles.CollectionListItem}>
                                        <Box sx={styles.eventItem}>
                                            <Box sx={styles.eventItemImgBox}>
                                                <Box sx={styles.eventItemImg}>
                                                    <Box
                                                        component={"img"}
                                                        src={"/assets/images/music1.jpg"} alt="Badge"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemInnerBox}>
                                                <Box sx={styles.eventItemInner}>
                                                    <Typography variant="h6" sx={styles.eventItemTitle}>Twice as Tall concert at the 02 Arena on 10th march, 2024.</Typography>
                                                    <Box sx={styles.eventItemBtnGroup}>
                                                        <Button
                                                            sx={styles.eventItemBtn1}>
                                                            <Typography
                                                                sx={styles.eventItemBtn1Text} >
                                                                Buy Tickets
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            sx={styles.eventItemBtn2}>
                                                            <Typography
                                                                sx={styles.eventItemBtn2Text}>
                                                                Swap $Stage
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemTimeBox}>
                                                <Box sx={styles.eventItemTimeBoxOuter}>
                                                    <Box sx={styles.eventItemTimeBoxInner} />
                                                    <Typography variant="h6" sx={styles.eventItemTime}>15 mins ago</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box component={'li'} sx={styles.CollectionListItem}>
                                        <Box sx={styles.eventItem}>
                                            <Box sx={styles.eventItemImgBox}>
                                                <Box sx={styles.eventItemImg}>
                                                    <Box
                                                        component={"img"}
                                                        src={"/assets/images/music1.jpg"} alt="Badge"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemInnerBox}>
                                                <Box sx={styles.eventItemInner}>
                                                    <Typography variant="h6" sx={styles.eventItemTitle}>Twice as Tall concert at the 02 Arena on 10th march, 2024.</Typography>
                                                    <Box sx={styles.eventItemBtnGroup}>
                                                        <Button
                                                            sx={styles.eventItemBtn1}>
                                                            <Typography
                                                                sx={styles.eventItemBtn1Text} >
                                                                Buy Tickets
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            sx={styles.eventItemBtn2}>
                                                            <Typography
                                                                sx={styles.eventItemBtn2Text}>
                                                                Swap $Stage
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemTimeBox}>
                                                <Box sx={styles.eventItemTimeBoxOuter}>
                                                    <Box sx={styles.eventItemTimeBoxInner} />
                                                    <Typography variant="h6" sx={styles.eventItemTime}>15 mins ago</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box component={'li'} sx={styles.CollectionListItem}>
                                        <Box sx={styles.eventItem}>
                                            <Box sx={styles.eventItemImgBox}>
                                                <Box sx={styles.eventItemImg}>
                                                    <Box
                                                        component={"img"}
                                                        src={"/assets/images/music1.jpg"} alt="Badge"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemInnerBox}>
                                                <Box sx={styles.eventItemInner}>
                                                    <Typography variant="h6" sx={styles.eventItemTitle}>Twice as Tall concert at the 02 Arena on 10th march, 2024.</Typography>
                                                    <Box sx={styles.eventItemBtnGroup}>
                                                        <Button
                                                            sx={styles.eventItemBtn1}>
                                                            <Typography
                                                                sx={styles.eventItemBtn1Text} >
                                                                Buy Tickets
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            sx={styles.eventItemBtn2}>
                                                            <Typography
                                                                sx={styles.eventItemBtn2Text}>
                                                                Swap $Stage
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemTimeBox}>
                                                <Box sx={styles.eventItemTimeBoxOuter}>
                                                    <Box sx={styles.eventItemTimeBoxInner} />
                                                    <Typography variant="h6" sx={styles.eventItemTime}>15 mins ago</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box component={'li'} sx={styles.CollectionListItem}>
                                        <Box sx={styles.eventItem}>
                                            <Box sx={styles.eventItemImgBox}>
                                                <Box sx={styles.eventItemImg}>
                                                    <Box
                                                        component={"img"}
                                                        src={"/assets/images/music1.jpg"} alt="Badge"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemInnerBox}>
                                                <Box sx={styles.eventItemInner}>
                                                    <Typography variant="h6" sx={styles.eventItemTitle}>Twice as Tall concert at the 02 Arena on 10th march, 2024.</Typography>
                                                    <Box sx={styles.eventItemBtnGroup}>
                                                        <Button
                                                            sx={styles.eventItemBtn1}>
                                                            <Typography
                                                                sx={styles.eventItemBtn1Text} >
                                                                Buy Tickets
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            sx={styles.eventItemBtn2}>
                                                            <Typography
                                                                sx={styles.eventItemBtn2Text}>
                                                                Swap $Stage
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.eventItemTimeBox}>
                                                <Box sx={styles.eventItemTimeBoxOuter}>
                                                    <Box sx={styles.eventItemTimeBoxInner} />
                                                    <Typography variant="h6" sx={styles.eventItemTime}>15 mins ago</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>


                                </Box> */}
                                <Box sx={styles.comingSoonBox}>
                                    <Box sx={styles.comingSoonImg}>
                                        <Box
                                            component={'img'}
                                            src={"/assets/images/comingsoon.png"}
                                            alt="Coming Soon"
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                    <Typography variant="h6" sx={styles.comingSoonText}>Coming Soon</Typography>

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box >
    )
}

export default FansDashboard