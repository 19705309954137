// import styled from '@emotion/styled';
// import { Avatar, Box, List, Typography, } from '@mui/material'
import { Avatar, Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { selectCurrentUser } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/hooks";
// import React, { useEffect, useState } from 'react'
// import theme from '../../Theme';
// import axios from 'axios';
// import SocialLink from '../../components/SocialLink';
// import TrackLink from '../../components/TrackLink';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Carousel from "../../components/Carousel";
import theme from "../../Theme";
import { faqContent } from "../../utils/common";
import {styles} from "./style"
const Home = () => {
  const [activeTab, setActiveTab] = React.useState(0);


  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    // console.log("currentUser", currentUser);
  }, [currentUser]);

  return (
    <>
      <Container maxWidth="xl"
        sx={styles.homeContainer}
      >
        <Header />

        <Box
          sx={styles.section1Box}
        >
          <Typography
            sx={styles.mainDescription}
            className="revealator-zoomin revealator-duration15 revealator-delay10"
          >
            We distribute your music to every major crypto platform so you don’t
            have to
          </Typography>

          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
              },
              alignItems: "center",
              backgroundColor: theme.palette.primary.white,
              borderRadius: "39px",
              border: `1px solid ${theme.palette.primary.logoColor}`,
              boxShadow:
                "0px 0px 0px 4px rgba(242, 244, 247, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "8px 16px",
              marginTop: "10px",
            }}
            className="revealator-zoomin revealator-duration15 revealator-delay5"
            component={"a"}
            href="#"
          >
            <Box
              component={"img"}
              src={"/assets/images/Social button.png"}
              alt="Google"
              sx={{
                width: "28px",
              }}
            />

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                paddingLeft: "12px",
                color: theme.palette.primary.logoColor,
              }}
            >
              Join the waiting list
            </Typography>
          </Box>

          <Box
            component={"img"}
            src={"/assets/images/Frame.png"}
            alt="Google"
            className="revealator-zoomin revealator-duration15"
            sx={styles.section1Icons}
          />
        </Box>
        <Box
          sx={{
            marginTop: "118px",
          }}
        >
          <Typography
            sx={styles.growYourFanbaseText}
            className="revealator-slideright revealator-duration15 revealator-delay5"
          >
            Grow your Fanbase
          </Typography>

          <Typography
            sx={styles.seamlessAccessToYourCatalogues}
            className="revealator-slideleft revealator-duration15 revealator-delay5"
          >
            Seamless access to your catalogues
          </Typography>
        </Box>
        <Box
          sx={styles.mobileImageContainer}
        >
          <Box
            component={"img"}
            src={"/assets/images/mobileScreen_edited.png"}
            alt="Google"
            className="revealator-zoomin revealator-duration15 revealator-delay5"
            sx={styles.mobileImage}
          />
          <Box
            sx={{
              width: "100%",
              marginTop: "155px",
            }}
          >
            <Typography
              sx={styles.reviewsHeader}
              className="revealator-slidedown revealator-duration15 revealator-delay5"
            >
              What do they think?
            </Typography>

            <Typography
              sx={styles.reviewsSectionSubText}
              className="revealator-zoomin revealator-duration15 revealator-delay5"
            >
              Get to know what the web3 pioneers are saying…
            </Typography>


            <Container maxWidth="xl"
              sx={styles.carouselContainerStyles}
            >
              <Carousel />
            </Container>

            <Box
              sx={styles.reviewCarouselItemsOuter}
              className="revealator-slideright revealator-duration15 revealator-delay5"
            >
              <Box
                sx={styles.reaviewsCarouselItemsInner}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Avatar
                    alt={"Remy Sharp"}
                    src={"/assets/images/Avatar.png"}
                    sx={styles.reaviewsCarouselItemsAvatar}
                  />
                  <Box
                    component={"img"}
                    src={"/assets/images/QuoteBadge.png"}
                    alt="Badge"
                    sx={styles.reviewsCarouselItemsImage}
                  />
                </Box>

                <Box
                  sx={styles.reviewsCarouselItemsUserName}
                >
                  <Typography
                    sx={styles.reaviewsCarouselItemsHeader}
                  >
                    CAM MURDOCH キャム
                  </Typography>
                  <Typography
                    sx={styles.reviewsCarouselItemsUserHandle}
                  >
                    @cammurdochmusic
                  </Typography>
                  <Typography
                    sx={styles.reviewsCarouselItemsComment}
                  >
                    “Revenue wise, Web3 has been more fruitful this and last
                    year...by a lot.”
                  </Typography>
                  <Box
                    component={"img"}
                    src={"/assets/images/pajamas_twitter.png"}
                    alt="Badge"
                    sx={{
                      width: "68px",
                      height: "68px",
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={styles.reviewCarouselItemsOuter}
              className="revealator-slideright revealator-duration15 revealator-delay5"
            >
              <Box
                sx={styles.reaviewsCarouselItemsInner}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Avatar
                    alt={"Remy Sharp"}
                    src={"/assets/images/Avatar.png"}
                    sx={styles.reaviewsCarouselItemsAvatar}
                  />
                  <Box
                    component={"img"}
                    src={"/assets/images/QuoteBadge.png"}
                    alt="Badge"
                    sx={styles.reviewsCarouselItemsImage}
                  />
                </Box>

                <Box
                  sx={styles.reviewsCarouselItemsUserName}
                >
                  <Typography
                    sx={styles.reaviewsCarouselItemsHeader}
                  >
                    CAM MURDOCH キャム
                  </Typography>
                  <Typography
                    sx={styles.reviewsCarouselItemsUserHandle}
                  >
                    @cammurdochmusic
                  </Typography>
                  <Typography
                    sx={styles.reviewsCarouselItemsComment}
                  >
                    “Revenue wise, Web3 has been more fruitful this and last
                    year...by a lot.”
                  </Typography>
                  <Box
                    component={"img"}
                    src={"/assets/images/pajamas_twitter.png"}
                    alt="Badge"
                    sx={{
                      width: "68px",
                      height: "68px",
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={styles.reviewCarouselItemsOuter}
              className="revealator-slideright revealator-duration15 revealator-delay5"
            >
              <Box
                sx={styles.reaviewsCarouselItemsInner}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Avatar
                    alt={"Remy Sharp"}
                    src={"/assets/images/Avatar.png"}
                    sx={styles.reaviewsCarouselItemsAvatar}
                  />
                  <Box
                    component={"img"}
                    src={"/assets/images/QuoteBadge.png"}
                    alt="Badge"
                    sx={styles.reviewsCarouselItemsImage}
                  />
                </Box>

                <Box
                  sx={styles.reviewsCarouselItemsUserName}
                >
                  <Typography
                    sx={styles.reaviewsCarouselItemsHeader}
                  >
                    CAM MURDOCH キャム
                  </Typography>
                  <Typography
                    sx={styles.reviewsCarouselItemsUserHandle}
                  >
                    @cammurdochmusic
                  </Typography>
                  <Typography
                    sx={styles.reviewsCarouselItemsComment}
                  >
                    “Revenue wise, Web3 has been more fruitful this and last
                    year...by a lot.”
                  </Typography>
                  <Box
                    component={"img"}
                    src={"/assets/images/pajamas_twitter.png"}
                    alt="Badge"
                    sx={{
                      width: "68px",
                      height: "68px",
                    }}
                  />
                </Box>
              </Box>
            </Box>


          </Box>
        </Box>

        <Box
          sx={styles.faqsSection}
        >
          <Box
            sx={styles.faqsConainer}
          >
            <Typography
              sx={styles.faqsHeader}
              className="revealator-slidedown revealator-duration15 revealator-delay5"
            >
              FAQs
            </Typography>

            <Typography
              sx={styles.faqsSubtitle}
              className="revealator-zoomin revealator-duration15 revealator-delay5"
            >
              We are here to assist you with almost anything you might need
            </Typography>

            <Box
              sx={styles.faqTable}
              className="revealator-zoomin revealator-duration15 revealator-delay1"
            >
              {/* <VerticalTabs /> */}

              <Box
                sx={styles.faqTabsContainer}
                className="tabOuter"
              >
                {faqContent.map((item, index) => (
                  <Box
                    key={index}
                    sx={styles.faqTabs}
                    className={`tabItem ${activeTab === index ? "activeTabOuter" : "activeTab"
                      }`}
                    onClick={() => setActiveTab(index)}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        letterSpacing: "-0.54px",
                        color: theme.palette.primary.logoColor,
                        minWidth: {
                          xs: "284px",
                          sm: "284px",
                        },
                      }}
                      className={`tabItem ${activeTab === index ? "activeText" : "tabText"
                        }`}

                    >
                      {item.title}
                    </Typography>
                  </Box>
                ))}




              </Box>

              <Box
                sx={styles.faqContentContainer}
              >
                <Typography
                  sx={styles.faqContent}
                >
                  {faqContent[activeTab].content}
                </Typography>
              </Box>


            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
