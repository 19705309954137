import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      logoColor: '#003852',
      logoColor30: 'rgb(0 56 82 / 30%)',
      deselectedRadioColor: '#C0C0C0',
      textColor: "#758494",
      main: '#3f50b5',
      dark: '#002884',
      white: '#fff',
      Lightbg: "rgba(117, 132, 148, 0.05)",
      Lightbg2: "rgba(255, 255, 255, 0.40)",
      Lightbg3: "rgba(0, 56, 82, 0.5)",
      LightText3: "rgba(68, 68, 68, 0.60)",
      Lightbg4: "rgba(255, 255, 255, 0.4)",
      inputBorder: "rgba(0, 56, 82, 0.2)",
      TabText: "#8F9BB3",
      para: '#9AA4B2',
      LightText: '#75849499',
      errorText: '#d32f2f',
      borderColor: '#CED3E4',
      swapModalBg: 'rgba(52, 64, 84, 0.7)',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },

  },
});

export default theme;