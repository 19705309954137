import { createSlice } from "@reduxjs/toolkit";

const artistSlice = createSlice({
    name: "artist",
    initialState: {
        currentArtist: null,
        status: "idle",
        error: null,
    },
    reducers: {
        setCurrentArtist: (state, action) => {
            state.currentArtist = action.payload;
        },
        clearCurrentArtist: (state) => {
            state.currentArtist = null;
        },
        updateCurrentArtist: (state, action) => {
            state.currentArtist = {
                ...state.currentArtist,
                artist: action.payload.artist,
            };
        },
        updateCurrentArtistAlbum: (state, action) => {
            state.currentArtist = {
                ...state.currentArtist,
                tracks: action.payload.tracks,
                album: action.payload.album,
            };
        },
    },
});

export const { setCurrentArtist, clearCurrentArtist, updateCurrentArtist, updateCurrentArtistAlbum } = artistSlice.actions;
export const selectCurrentArtist = (state) => state.artist.currentArtist;

export default artistSlice.reducer;