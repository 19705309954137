import { Box, Button, FormControl, FormLabel, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import React, { useState } from "react";
import theme from "../Theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import { VisuallyHiddenInput } from "@chakra-ui/react";
import { formatTimeAddSemicolon } from "../utils/common";

const TrackNo = ({ index, formData, setFormDataInTrack, setTrackNumber, trackNumber, errors, setErrors, selectedTracks, setSelectedTracks }) => {
  const [trackDuration,setTrackDuration]= useState()
  const handleTrackFormdataChange = (event) => {
    const { name, value } = event.target;
    console.log(formData, selectedTracks)
    setFormDataInTrack({
      ...formData,
      tracks: formData.tracks.map((track, i) => {
        if (i === index) {
          return {
            ...track,
            [name]: value,
          };
        }
        return track;
      }),
    });

    const updatedErrors = {};

    setErrors(updatedErrors)

  };


  const handleBlur = () => {
    const updatedErrors = {};
    setErrors(updatedErrors);
  };

  const handlePlusClick = () => {
    setTrackNumber(trackNumber + 1);
    setFormDataInTrack({
      ...formData,
      tracks: [
        ...formData.tracks,
        {
          track_no: trackNumber + 1,
          track_name: '',
          track_duration: '',
        },
      ],
    });
  }

  const handleTracksChange = (e, index) => {
    const {name,value} = e.target
    const file = e.target.files[0];
    var durationRounded
    const acceptedFileTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
    if (file) {
      const audioElement = new Audio(URL.createObjectURL(file));
      audioElement.addEventListener('loadedmetadata', () => {
        const durationInSeconds = audioElement.duration;
        const durationInMinutes = durationInSeconds / 60
        durationRounded = durationInMinutes.toFixed(2);

        setFormDataInTrack({
          ...formData,
          tracks: formData.tracks.map((track, i) => {

            if (i === index) {
              console.log(i, index, parseFloat(durationRounded))
              return {
                ...track,
                track_duration: parseFloat(durationRounded),
              };
            }
            return track;
          }),
        });

        // setTrackDuration(durationRounded)
        // console.log(durationRounded);
      });
    }
    console.log("===> ", trackDuration)
    if (!file) {
      return;
    }

    if (!acceptedFileTypes.includes(file.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Invalid file type. Please select an MP3, WAV or OGG file.',
      });
      return;
    }

    // console.log("file ===> ", file);

    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file.size > maxSize) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'File is too large, please select a file less than 10MB.',
      })
      return;
    }
    console.log(selectedTracks)
    setSelectedTracks({
      ...selectedTracks,
      [index]: file,
    });

    
    const updatedErrors = {};
    console.log(formData)
    setErrors(updatedErrors)

  }

  // console.log("selectedTracks ===> ", selectedTracks);

  return (
    <Box sx={{
      display: 'flex',
      gap: {
        xs: '20px',
        sm: '20px',
        md: '15px',
        lg: '15px',

      },
      alignItems: 'center',
      justifyContent: 'start',
      flexDirection: 'row',
      margin: {
        xs: '0px 0px',
        sm: '0px 0px',
        md: '10px 0px',
        lg: '10px 0px',
      },
      width: {
        xs: '100%',
        sm: '100%',
        md: (index === [...Array(trackNumber)].length - 1) ? '505px' : '450px',
        lg: (index === [...Array(trackNumber)].length - 1) ? '505px' : '450px',
      },
    }}>
      <FormControl sx={{
        marginTop: {
          xs: '0px',
          sm: '0px',
          md: '10px',
          lg: '10px',
        },
        minHeight: index === 0 ? '120px' : '85px',
        width: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          lg: '100%',
        },
      }}
        fullWidth
      >
        {index === 0 && <FormLabel sx={{
          marginBottom: '10px',
          color: theme.palette.primary.logoColor,
          fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
          },
        }}
          id="demo-row-radio-buttons-group-label">Track no.</FormLabel>
        }


        <Select
          className="cardInputArea"
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '100%',
            },
            fontSize: "20px",
            borderRadius: '10px',
            height: '50px',
            minHeight: '50px',
          }}
          fullWidth
          labelId="demo-social-media-select-label"
          id="demo-social-media-select"
          name="track_no"
          value={formData.tracks[index].track_no}
          label="Social Media"
          displayEmpty
          onChange={handleTrackFormdataChange}
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={KeyboardArrowDownIcon}
        >

          {[...Array(trackNumber)].map((_, i) => (
            <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
          ))}

        </Select>
      </FormControl>
      <FormControl
        sx={{
          minHeight: index === 0 ? '120px' : '85px',
        }}
        fullWidth
      >
        {index === 0 &&
          <FormLabel sx={{
            marginBottom: '10px',
            color: theme.palette.primary.logoColor,
            fontWeight: 400,
            fontSize: {
              xs: "16px",
              sm: "16px",
              md: "16px",
              lg: "18px",
            },
          }} id="demo-row-radio-buttons-group-label">Song Title *</FormLabel>
        }

        <TextField className="cardInputArea" sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
          },
          height: '50px',
          fontSize: "22px",
          borderRadius: '10px',
        }}
          fullWidth
          id="fullWidth"
          placeholder="Enter"
          name="track_name"
          value={formData.tracks[index].track_name}
          onChange={handleTrackFormdataChange}
          error={errors[index]?.track_name ? true : false}
          helperText={errors[index]?.track_name}
        />
      </FormControl>
      {/* <FormControl
        sx={{
          minHeight: index === 0 ? '120px' : '85px',
        }}
        fullWidth>
        {index === 0 &&
          <FormLabel sx={{
            marginBottom: '10px',
            color: theme.palette.primary.logoColor,
            fontWeight: 400,
            fontSize: {
              xs: "16px",
              sm: "16px",
              md: "16px",
              lg: "18px",
            },
          }} id="demo-row-radio-buttons-group-label">Duration *</FormLabel>
        }

        <TextField className="cardInputArea"
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '100%',

            },
            fontSize: "22px",
            borderRadius: '10px',
          }}
          onKeyDown={(event) => {
            //add backspace to allow user to delete
            if (event.key === "Backspace") {
              return;
            }

            if (event.target.value.length >= 5) {
              event.preventDefault();
            }
          }}
          fullWidth
          id="fullWidth"
          placeholder="Enter"
          name="track_duration"
          value={formData.tracks[index].track_duration}
          onBlur={handleBlur}
          onChange={(event) => {
            const formattedTime = formatTimeAddSemicolon(event.target.value);
            handleTrackFormdataChange({ target: { value: formattedTime, name: event.target.name } });
          }}
          error={errors[index]?.track_duration ? true : false}
          helperText={errors[index]?.track_duration}
        />
      </FormControl> */}
      <FormControl fullWidth
        sx={{
          minHeight: index === 0 ? '120px' : '85px',
        }}
      >
        {index === 0 &&
          <FormLabel sx={{
            marginBottom: '10px',
            color: theme.palette.primary.logoColor,
            fontWeight: 400,
            fontSize: {
              xs: "16px",
              sm: "16px",
              md: "16px",
              lg: "18px",
            },
          }} id="demo-row-radio-buttons-group-label">Song File *</FormLabel>
        }
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100px',
              lg: '100px',
            },
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '5px',
            color: theme.palette.primary.logoColor,
            background: 'transparent !important',
            borderRadius: '10px',
            padding: "8px",
            textTransform: "capitalize",
            border: "1px dashed",
            boxShadow: 'none !important',
            borderColor: theme.palette.primary.logoColor,

            "&:hover": {
              borderColor: 'transparent',
              color: theme.palette.primary.logoColor,
              border: "1px dashed",
              background: 'transparent !important',
              boxShadow: 'none !important',
            }
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
        >
          <Box sx={{
            width: '30px',
            height: '30px',
            objectFit: 'contain',
          }} component={"img"} src={selectedTracks[index] ? "/assets/images/checked.png" : "/assets/images/musicFile.png"} />

          <>

            <VisuallyHiddenInput
              type="file"
              accept="audio/*.mp3, audio/*.wav, audio/*.ogg"
              onChange={(e) => handleTracksChange(e, index)}
            />

          </>

        </Button>

        {errors[index]?.track_file &&
          <Typography
            sx={{
              color: theme.palette.primary.errorText,
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "12px",
                lg: "12px",
              },
            }}
          >
            {errors[index]?.track_file}
          </Typography>}
      </FormControl>
      {(index === [...Array(trackNumber)].length - 1) &&
        <IconButton
          sx={{
            backgroundColor: theme.palette.primary.logoColor,
            color: theme.palette.primary.white,
            marginTop: index === 0 ? '5px' : '-25px',
            "&:hover": {
              backgroundColor: theme.palette.primary.logoColor,
              color: theme.palette.primary.white,
            }
          }}
          onClick={handlePlusClick}
        >
          <AddIcon />
        </IconButton>
      }

    </Box>
  )
}

export default TrackNo;