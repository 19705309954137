import theme from "../../Theme"

export const styles = {
    artistInfoPageContainer:{
    padding: {
        xs: '20px 20px 50px',
            sm: '20px 20px 50px',
                md: '30px 0px 70px',
                    lg: '30px 0px 70px',
                },
},
    logo: {
        width: {
            xs: '104px',
            sm: '104px',
            md: '100px',
            lg: '140px',
        },
        height: {
            xs: '32px',
            sm: '32px',
            md: '35px',
            lg: '43px',
        },
        objectFit: 'contain',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    Header: {
        padding: {
            xs: '20px 0px 50px',
            sm: '20px 0px 50px',
            md: '30px 0px 70px',
            lg: '30px 0px 70px',
        },
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'start',
            lg: 'start',

        },
        gap: '20px',
        boder: '1px solid black'
    },
    UploadMetaDataText: {
        color: theme.palette.primary.logoColor,
        fontWeight: 600,
        fontSize: {
            xs: "24px",
            sm: "24px",
            md: "24px",
            lg: "24px",
        },
        textAlign: {
            xs: 'center',
            sm: 'center',
            md: 'start',
            lg: 'start',
        },

    },
    UploadMetaDataText: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
    },
    enterAllYourInfoText: {
        color: theme.palette.primary.logoColor,
        fontWeight: 600,
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "20px",
            lg: "20px",
        },
    },
    enterAllYourInfoContainer: {
        width: '25%',
        display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',

        }
    },
    formContainer: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '75%',
            lg: '75%',
        },
        paddingLeft: {
            xs: '0',
            sm: '0',
            md: '50px',
            lg: '50px',

        },
        borderLeft: {
            xs: 'none',
            sm: 'none',
            md: '1px solid',
            lg: '1px solid',
        },
        borderColor: theme.palette.primary.logoColor30,
    },
    formContainerInnerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '20px',
        width: '100%',
        flexDirection: 'column',
    },
    formControlLable: {
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        flexDirection: 'row-reverse',
        border: '1px solid',
        borderRadius: '8px',

        padding: '10px 0px 10px 20px',
        width: {
            xs: '50%',
            sm: '50%',
            md: '190px',
            lg: '190px',
        },
        margin: '0px',
        height: '60px',
        fontWeight: 600,
    },
    artistInfoRadioGroup: {
        display: 'flex',
        gap: '20px',
        width: '100%',
        justifyContent: {
            xs: 'space-between',
            sm: 'space-between',
            md: 'start',
            lg: 'start',
        },
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    releaseTypeFormLable: {
        marginBottom: '15px',
        color: theme.palette.primary.logoColor,
        fontWeight: 400,
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "16px",
            lg: "18px",
        },
    },
    mainContentbox: {
        display: {
            xs: 'block',
            sm: 'block',
            md: 'flex',
            lg: 'flex',
        },
        justifyContent: "start",
        width: "100%",
    },
    paymentText: {
        color: theme.palette.primary.logoColor,
        fontWeight: 600,
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
    },
    pageNumContainer: {
        borderRadius: "100%",
        border: "2px solid",
        borderColor: theme.palette.primary.logoColor,
        height: "35px",
        width: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    headerPageInfo: {
        color: theme.palette.primary.logoColor,
        fontWeight: 600,
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        minWidth: {
            xs: '50%',
            sm: '50%',
            md: '225px',
            lg: '225px',
        },
        width: {
            xs: '50%',
            sm: '50%',
            md: 'auto',
            lg: 'auto',
        },
        height: {
            xs: "100px",
            sm: "100px",
            md: "60px",
            lg: "60px",

        },
        flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
        },
        justifyContent: {
            xs: 'space-between',
            sm: 'space-between',
            md: 'start',
            lg: 'start',
        },
        borderRadius: "20px",
        padding: {
            xs: "8px 8px 8px 8px",
            sm: "8px 8px 8px 8px",
            md: "8px 24px 8px 8px",
            lg: "8px 24px 8px 8px",

        },
        textTransform: "capitalize",
        border: "2px solid",
        borderColor: 'transparent',
        gap: "10px",
        opacity: 0.5,
        "&:hover": {
            borderColor: 'transparent !important',
            color: theme.palette.primary.logoColor,
            border: "2px solid",
            background: 'transparent !important',
        },
        pointerEvents: "none",
    },
    artistInfoText: {
        color: theme.palette.primary.logoColor,
        fontWeight: 800,
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
    },
    pageNumberText: {
        color: theme.palette.primary.logoColor,
        fontWeight: 600,
        fontSize: '16px',
    }, artistInformationButton: {
        color: theme.palette.primary.logoColor,
        fontWeight: 600,
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        borderRadius: "20px",
        padding: {
            xs: "8px 8px 8px 8px",
            sm: "8px 8px 8px 8px",
            md: "8px 24px 8px 8px",
            lg: "8px 24px 8px 8px",

        },
        flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
        },
        textTransform: "capitalize",
        border: "2px solid",
        borderColor: theme.palette.primary.logoColor,
        gap: "10px",
        minWidth: {
            xs: '50%',
            sm: '50%',
            md: '225px',
            lg: '225px',
        },
        width: {
            xs: '50%',
            sm: '50%',
            md: 'auto',
            lg: 'auto',
        },
        height: {
            xs: "100px",
            sm: "100px",
            md: "60px",
            lg: "60px",
        },
        justifyContent: {
            xs: 'space-between',
            sm: 'space-between',
            md: 'start',
            lg: 'start',
        },
        "&:hover": {
            borderColor: 'transparent',
            color: theme.palette.primary.logoColor,
            border: "2px solid",
            background: 'transparent !important',
        },
        pointerEvents: "none",
    },
    topInfoButtonsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: {
            xs: 'space-between',
            sm: 'space-between',
            md: 'start',
            lg: 'start',
        },
        gap: "20px",
        width: "100%",
        paddingTop: "40px",
        paddingBottom: "50px",
    },
    pageInfoSection:{
    display: "flex",
        alignItems: "start",
            justifyContent: "start",
                gap: "20px",
                    width: "100%",
                  }

}