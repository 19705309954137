import { Box, Button, FormControl, FormLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../Theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Swal from "sweetalert2";
import { languageOptions } from "../utils/common";
import { countryOptions } from "../utils/common";
import { API_BASE_URL } from "../url";
const ProMetadataForm = ({ setArtistDashboard, currentUser, currentArtist }) => {

  const [isTwitterSelected, setIsTwitterSelected] = useState(false);
  const [isInstagramSelected, setIsInstagramSelected] = useState(false);
  const [formData, setFormData] = useState({
    contributingArtists: "",
    series: "",
    set: "",
    collection: "",
    mood: "",
    specialThanks: "",
    visualArtist: "",
    distributor: "",
    releaseDate: "",
    publicationDate: "",
    catalogueNumber: "",
    bitrate: "",
    mixMasteringEngineer: "",
    producer: "",
    featuredArtist: "",
    recordingEngineer: "",
    coProducer: "",
    releaseVersion: "",
    parentalAdvisory: "",
    isrc: "",
    metadataLanguage: "",
    countryOfOrigin: "",
    language: "",
    derivedFrom: "",

    socialMediaPlatform: "facebook",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
  });
  // SocialLinkStart
  useEffect(() => {
    if (currentArtist) {
      if (currentArtist?.artist?.artist_name) {
        setFormData(prevFormData => ({
          ...prevFormData,
          artistName: currentArtist.artist.artist_name,
        }));
      }

      if (currentArtist?.artist?.facebook_link) {
        setFormData(prevFormData => ({
          ...prevFormData,
          facebookLink: currentArtist.artist.facebook_link,
        }));
      }

      if (currentArtist?.artist?.twitter_link) {
        setFormData(prevFormData => ({
          ...prevFormData,
          twitterLink: currentArtist.artist.twitter_link,
        }));
        setIsTwitterSelected(true);
      }

      if (currentArtist?.artist?.instagram_link) {
        setFormData(prevFormData => ({
          ...prevFormData,
          instagramLink: currentArtist.artist.instagram_link,
        }));
        setIsInstagramSelected(true);
      }
    }
  }, [currentArtist]);

  useEffect(() => {
    if (formData.socialMediaPlatform === "twitter") {
      setIsTwitterSelected(true);
    }

    if (formData.socialMediaPlatform === "instagram") {
      setIsInstagramSelected(true);
    }

    // console.log("formData ==> ", formData);
  }, [formData])
  // SocialLinkEnd

  useEffect(() => {
    if (currentArtist) {

      if (currentArtist?.album?.contributing_artists) {
        setFormData({ ...formData, contributingArtists: currentArtist.album.contributing_artists });
      }

      if (currentArtist?.album?.series) {
        setFormData({ ...formData, series: currentArtist.album.series });
      }

      if (currentArtist?.album?.album_set) {
        setFormData({ ...formData, set: currentArtist.album.album_set });
      }

      if (currentArtist?.album?.collection) {
        setFormData({ ...formData, collection: currentArtist.album.collection });
      }

      if (currentArtist?.album?.mood) {
        setFormData({ ...formData, mood: currentArtist.album.mood });
      }

      if (currentArtist?.album?.special_thanks) {
        setFormData({ ...formData, specialThanks: currentArtist.album.special_thanks });
      }

      if (currentArtist?.album?.visual_artist) {
        setFormData({ ...formData, visualArtist: currentArtist.album.visual_artist });
      }

      if (currentArtist?.album?.distributor) {
        setFormData({ ...formData, distributor: currentArtist.album.distributor });
      }

      if (currentArtist?.album?.release_date) {
        setFormData({ ...formData, releaseDate: currentArtist.album.release_date });
      }

      if (currentArtist?.album?.publication_date) {
        setFormData({ ...formData, publicationDate: currentArtist.album.publication_date });
      }

      if (currentArtist?.album?.catalogue_no) {
        setFormData({ ...formData, catalogueNumber: currentArtist.album.catalogue_no });
      }

      if (currentArtist?.album?.bitrate) {
        setFormData({ ...formData, bitrate: currentArtist.album.bitrate });
      }

      if (currentArtist?.album?.mix_engineer) {
        setFormData({ ...formData, mixMasteringEngineer: currentArtist.album.mix_engineer });
      }

      if (currentArtist?.album?.producer) {
        setFormData({ ...formData, producer: currentArtist.album.producer });
      }

      if (currentArtist?.album?.feature_artist) {
        setFormData({ ...formData, featuredArtist: currentArtist.album.feature_artist });
      }

      if (currentArtist?.album?.recording_engineer) {
        setFormData({ ...formData, recordingEngineer: currentArtist.album.recording_engineer });
      }

      if (currentArtist?.album?.co_producer) {
        setFormData({ ...formData, coProducer: currentArtist.album.co_producer });
      }

      if (currentArtist?.album?.release_version) {
        setFormData({ ...formData, releaseVersion: currentArtist.album.release_version });
      }

      if (currentArtist?.album?.parental_advisor) {
        setFormData({ ...formData, parentalAdvisory: currentArtist.album.parental_advisor });
      }

      if (currentArtist?.album?.isrc) {
        setFormData({ ...formData, isrc: currentArtist.album.isrc });
      }

      if (currentArtist?.album?.metadata_lang) {
        setFormData({ ...formData, metadataLanguage: currentArtist.album.metadata_lang });
      }

      if (currentArtist?.album?.country) {
        setFormData({ ...formData, countryOfOrigin: currentArtist.album.country });
      }

      if (currentArtist?.album?.language) {
        setFormData({ ...formData, language: currentArtist.album.language });
      }

      if (currentArtist?.album?.derived_from) {
        setFormData({ ...formData, derivedFrom: currentArtist.album.derived_from });
      }

    }
  }, [currentArtist]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData)
  };

  const handleNextClick = () => {
    // console.log("formData ==> ", formData);

    const newFormData = new FormData();
    newFormData.append("album_id", currentArtist.album.album_id);
    newFormData.append("artist_id", currentArtist.artist.id);
    newFormData.append("contributing_artists", formData.contributingArtists);
    newFormData.append("series", formData.series);
    newFormData.append("album_set", formData.set);
    newFormData.append("collection", formData.collection);
    newFormData.append("mood", formData.mood);
    newFormData.append("special_thanks", formData.specialThanks);
    newFormData.append("visual_artist", formData.visualArtist);
    newFormData.append("distributor", formData.distributor);
    newFormData.append("release_date", formData.releaseDate);
    newFormData.append("publication_date", formData.publicationDate);
    newFormData.append("catalogue_no", formData.catalogueNumber);
    newFormData.append("bitrate", formData.bitrate);
    newFormData.append("mix_engineer", formData.mixMasteringEngineer);
    newFormData.append("producer", formData.producer);
    newFormData.append("feature_artist", formData.featuredArtist);
    newFormData.append("recording_engineer", formData.recordingEngineer);
    newFormData.append("co_producer", formData.coProducer);
    newFormData.append("release_version", formData.releaseVersion);
    newFormData.append("parental_advisor", formData.parentalAdvisory);
    newFormData.append("isrc", formData.isrc);
    newFormData.append("metadata_lang", formData.metadataLanguage);
    newFormData.append("country", formData.countryOfOrigin);
    newFormData.append("language", formData.language);
    newFormData.append("derived_from", formData.derivedFrom);

    // newFormData.append("socialMediaPlatform", formData.socialMediaPlatform);
    newFormData.append("facebook_link", formData.facebookLink);
    newFormData.append("twitter_link", formData.twitterLink);
    newFormData.append("instagram_link", formData.instagramLink);
    // for (var pair of newFormData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    console.log(newFormData)
    updateAlbumTrack(newFormData);
  }

  const handleSkipClick = () => {
    // console.log("Skip")
    setArtistDashboard(true);
  }

  const updateAlbumTrack = async (formData) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}updateAlbumTrack`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
        return;
      }

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Album track updated successfully!',
        });
      }

      const data = await response.json();
      // console.log("Received data ==> ", data);
      setArtistDashboard(true);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      gap: '20px',
      width: '100%',
      flexDirection: 'column',
    }}>

      <Box className="mainInputBox"
        sx={{
          marginTop: {
            xs: '20px',
            sm: '20px',
            md: '50px',
            lg: '50px',

          },
          display: 'grid',
          gap: '30px',
          width: '100%',
        }}
      >
        {/* Social link */}
        <Box
          sx={{
            width: '100%',
            padding: '30px',
            background: theme.palette.primary.Lightbg2,
            borderRadius: '20px',
            display: 'grid',
            gap: '30px',
          }}
        >
          <Typography variant="h6" sx={{
            color: theme.palette.primary.logoColor,
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "29px",
            textAlign: "left",
          }}>Social Links</Typography>
          <Box sx={{
            padding: '20px 20px 30px',
            borderRadius: '20px',
            border: '1px solid',
            borderColor: theme.palette.primary.Lightbg3,
          }}>
            <FormControl fullWidth>
              <FormLabel sx={{
                marginBottom: '10px',
                color: theme.palette.primary.logoColor,
                fontWeight: 600,
                fontSize: {
                  xs: "16px",
                  sm: "16px",
                  md: "16px",
                  lg: "18px",
                },
              }} id="demo-row-radio-buttons-group-label">Social Platform</FormLabel>

              <Select
                className="cardInputArea"
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                  },
                  fontSize: "20px",
                  borderRadius: '10px',
                }}
                fullWidth
                labelId="demo-social-media-select-label"
                id="demo-social-media-select"
                name="socialMediaPlatform"
                value={formData.socialMediaPlatform}
                label="Social Media"
                displayEmpty
                onChange={handleFormDataChange}
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={KeyboardArrowDownIcon}
              >

                <MenuItem value={"facebook"}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}>
                    <img src="/assets/images/facebook.png" alt="facebook" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                    Facebook
                  </div>
                </MenuItem>
                <MenuItem value={"twitter"}><div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}>
                  <img src="/assets/images/twitter.png" alt="twitter" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                  twitter
                </div></MenuItem>
                <MenuItem value={"instagram"}><div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}>
                  <img src="/assets/images/instagram.png" alt="instagram" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                  instagram
                </div></MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{
            padding: '20px 20px 30px',
            borderRadius: '20px',
            border: '1px solid',
            borderColor: theme.palette.primary.Lightbg3,
          }}>
            <FormControl fullWidth>
              <FormLabel sx={{
                marginBottom: '10px',
                color: theme.palette.primary.logoColor,
                fontWeight: 600,
                fontSize: {
                  xs: "16px",
                  sm: "16px",
                  md: "16px",
                  lg: "18px",
                },
              }} id="demo-row-radio-buttons-group-label">Facebook Link</FormLabel>

              <TextField className="cardInputArea" sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',

                },
                fontSize: "22px",
                borderRadius: '10px',
              }}
                fullWidth
                id="facebookLink"
                placeholder="https://"
                name="facebookLink"
                value={formData.facebookLink}
                onChange={handleFormDataChange}
              />
            </FormControl>
          </Box>

          {isTwitterSelected &&
            <Box sx={{
              padding: '20px 20px 30px',
              borderRadius: '20px',
              border: '1px solid',
              borderColor: theme.palette.primary.Lightbg3,
            }}>
              <FormControl fullWidth>
                <FormLabel sx={{
                  marginBottom: '10px',
                  color: theme.palette.primary.logoColor,
                  fontWeight: 600,
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "16px",
                    lg: "18px",
                  },
                }} id="demo-row-radio-buttons-group-label">Twitter Link</FormLabel>

                <TextField className="cardInputArea" sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',

                  },
                  fontSize: "22px",
                  borderRadius: '10px',
                }}
                  fullWidth
                  id="twitterLink"
                  placeholder="https://"
                  name="twitterLink"
                  value={formData.twitterLink}
                  onChange={handleFormDataChange}
                />
              </FormControl>
            </Box>
          }

          {isInstagramSelected &&
            <Box sx={{
              padding: '20px 20px 30px',
              borderRadius: '20px',
              border: '1px solid',
              borderColor: theme.palette.primary.Lightbg3,
            }}>
              <FormControl fullWidth>
                <FormLabel sx={{
                  marginBottom: '10px',
                  color: theme.palette.primary.logoColor,
                  fontWeight: 600,
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "16px",
                    lg: "18px",
                  },
                }} id="demo-row-radio-buttons-group-label">Instagram Link</FormLabel>

                <TextField className="cardInputArea" sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',

                  },
                  fontSize: "22px",
                  borderRadius: '10px',
                }}
                  fullWidth
                  id="instagramLink"
                  placeholder="https://"
                  name="instagramLink"
                  value={formData.instagramLink}
                  onChange={handleFormDataChange}
                />
              </FormControl>
            </Box>
          }

        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Contributing Artists</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="contributingAtrist"
              placeholder="Enter contributing artist"
              name="contributingArtists"
              value={formData.contributingArtists}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Series</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="fullWidth"
              placeholder="Enter song title"
              name="series"
              value={formData.series}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Set</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="set"
              placeholder="Enter"
              name="set"
              value={formData.set}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Collection</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="collection"
              placeholder="Enter"
              name="collection"
              value={formData.collection}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Mood</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="mood"
              placeholder="Enter"
              name="mood"
              value={formData.mood}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Special Thanks</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="specialThanks"
              placeholder="Enter"
              name="specialThanks"
              value={formData.specialThanks}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Visual Artist</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="visualArtist"
              placeholder="Enter"
              name="visualArtist"
              value={formData.visualArtist}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Distributor</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="distributor"
              placeholder="Enter"
              name="distributor"
              value={formData.distributor}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }}
              id="demo-row-radio-buttons-group-label">Release Date</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="releaseDate"
              placeholder="Enter"
              name="releaseDate"
              type="date"
              value={formData.releaseDate}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Publication date</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="publicationDate"
              type="date"
              placeholder="Enter"
              name="publicationDate"
              value={formData.publicationDate}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Catalogue  number</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="catalogueNumber"
              placeholder="Enter"
              name="catalogueNumber"
              value={formData.catalogueNumber}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Bitrate</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="bitrate"
              placeholder="Enter"
              name="bitrate"
              value={formData.bitrate}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Mix/Mastering Engineer</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="mixMasteringEngineer"
              placeholder="Enter"
              name="mixMasteringEngineer"
              value={formData.mixMasteringEngineer}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Producer</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="producer"
              placeholder="Enter"
              name="producer"
              value={formData.producer}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Featured Artist</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="featuredArtist"
              placeholder="Enter"
              name="featuredArtist"
              value={formData.featuredArtist}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Recording Engineer</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="recordingEngineer"
              placeholder="Enter"
              name="recordingEngineer"
              value={formData.recordingEngineer}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>

        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Co-producer</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="coProducer"
              placeholder="Enter"
              name="coProducer"
              value={formData.coProducer}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Release Version</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="releaseVersion"
              placeholder="Enter"
              name="releaseVersion"
              value={formData.releaseVersion}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>

        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Parental  Advisory</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="parentalAdvisory"
              placeholder="Enter"
              name="parentalAdvisory"
              value={formData.parentalAdvisory}
              onChange={handleFormDataChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">ISRC</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="isrc"
              placeholder="Enter"
              name="isrc"
              value={formData.isrc}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Metadata Language</FormLabel>
            <Select
              labelId="metadataLanguage"
              id="metadataLanguage"
              value={formData.metadataLanguage}
              name="metadataLanguage"
              onChange={handleFormDataChange}
              fullWidth
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                },
                fontSize: "22px",
                borderRadius: '10px',
              }}

              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem
                value=""
                sx={{
                  fontSize: "22px",
                  borderRadius: '10px',
                }}
              >
                Enter
              </MenuItem>
              {languageOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  sx={{
                    fontSize: "22px",
                    borderRadius: '10px',
                  }}
                >{option.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Country of Origin</FormLabel>
            <Select
              labelId="countryOfOrigin"
              id="countryOfOrigin"
              value={formData.countryOfOrigin}
              name="countryOfOrigin"
              onChange={handleFormDataChange}
              fullWidth
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                },
                fontSize: "22px",
                borderRadius: '10px',
              }}

              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem
                value=""
                sx={{
                  fontSize: "22px",
                  borderRadius: '10px',
                }}
              >
                Enter
              </MenuItem>
              {countryOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  sx={{
                    fontSize: "22px",
                    borderRadius: '10px',
                  }}
                >{option.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },

          width: '100%',
        }}>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Language</FormLabel>
            <Select
              labelId="language"
              id="language"
              value={formData.language}
              name="language"
              onChange={handleFormDataChange}
              fullWidth
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                },
                fontSize: "22px",
                borderRadius: '10px',
              }}

              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem
                value=""
                sx={{
                  fontSize: "22px",
                  borderRadius: '10px',
                }}
              >
                Enter
              </MenuItem>
              {languageOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  sx={{
                    fontSize: "22px",
                    borderRadius: '10px',
                  }}
                >{option.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel sx={{
              marginBottom: '10px',
              color: theme.palette.primary.logoColor,
              fontWeight: 400,
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
              },
            }} id="demo-row-radio-buttons-group-label">Derived from</FormLabel>
            <TextField className="cardInputArea" sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

              },
              fontSize: "22px",
              borderRadius: '10px',
            }}
              fullWidth
              id="derivedFrom"
              placeholder="Enter"
              name="derivedFrom"
              value={formData.derivedFrom}
              onChange={handleFormDataChange}
            />
          </FormControl>
        </Box>

      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '40px',
          alignItems: 'center',
          justifyContent: 'center',

          width: '100%',
        }}
      >
        {/* <Button
          sx={{
            width: 'auto',
            minWidth: '160px',
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "22px",
            color: theme.palette.primary.logoColor,
            border: '2px solid',
            borderColor: theme.palette.primary.logoColor,
            padding: '20px 32px 20px 32px',
            marginTop: '50px',
            background: 'transparent',
            borderRadius: '10px',
            textTransform: 'capitalize',
            height: '55px',
            boxShadow: '0px 8px 15px 1px rgb(0 0 0 / 10%)',
            transition: 'all 0.3s ease',
            "&:hover": {
              background: 'transparent',
              boxShadow: '0px 8px 15px 1px rgb(0 0 0 / 10%)',
              color: theme.palette.primary.logoColor,
              border: '2px solid',
              borderColor: theme.palette.primary.logoColor,
            }
          }}
          variant="contained"
          onClick={handleSkipClick}
        >Skip</Button> */}
        <Button
          sx={{
            width: 'auto',
            minWidth: '160px',
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "22px",
            color: theme.palette.primary.white,
            padding: '20px 32px 20px 32px',
            marginTop: '50px',
            background: theme.palette.primary.logoColor,
            borderRadius: '10px',
            textTransform: 'capitalize',
            height: '55px',
            border: '2px solid',
            borderColor: theme.palette.primary.logoColor,
            boxShadow: '0px 8px 26.85px 1px #00385259',
            transition: 'all 0.3s ease',
            "&:hover": {
              background: theme.palette.primary.logoColor,
              boxShadow: '0px 8px 26.85px 1px #00385259',
              color: theme.palette.primary.white,

            }
          }}
          variant="contained"
          onClick={handleNextClick}
        >Submit</Button>
      </Box>

    </Box>
  )
}

export default ProMetadataForm;