import { Box, CircularProgress } from "@mui/material";
import theme from "../Theme";
import { API_BASE_URL } from "../url";

export const languageOptions = [
    { key: 'Arabic', text: 'Arabic', value: 'Arabic' },
    { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
    { key: 'Danish', text: 'Danish', value: 'Danish' },
    { key: 'Dutch', text: 'Dutch', value: 'Dutch' },
    { key: 'English', text: 'English', value: 'English' },
    { key: 'French', text: 'French', value: 'French' },
    { key: 'German', text: 'German', value: 'German' },
    { key: 'Greek', text: 'Greek', value: 'Greek' },
    { key: 'Hungarian', text: 'Hungarian', value: 'Hungarian' },
    { key: 'Italian', text: 'Italian', value: 'Italian' },
    { key: 'Japanese', text: 'Japanese', value: 'Japanese' },
    { key: 'Korean', text: 'Korean', value: 'Korean' },
    { key: 'Lithuanian', text: 'Lithuanian', value: 'Lithuanian' },
    { key: 'Persian', text: 'Persian', value: 'Persian' },
    { key: 'Polish', text: 'Polish', value: 'Polish' },
    { key: 'Portuguese', text: 'Portuguese', value: 'Portuguese' },
    { key: 'Russian', text: 'Russian', value: 'Russian' },
    { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
    { key: 'Swedish', text: 'Swedish', value: 'Swedish' },
    { key: 'Turkish', text: 'Turkish', value: 'Turkish' },
    { key: 'Vietnamese', text: 'Vietnamese', value: 'Vietnamese' },
]

export const countryOptions = [
    { key: 'Afghanistan', text: 'Afghanistan', value: 'Afghanistan' },
    { key: 'Ƭand Islands', text: 'Ƭand Islands', value: 'Ƭand Islands' },
    { key: 'Albania', text: 'Albania', value: 'Albania' },
    { key: 'Algeria', text: 'Algeria', value: 'Algeria' },
    { key: 'American Samoa', text: 'American Samoa', value: 'American Samoa' },
    { key: 'Andorra', text: 'Andorra', value: 'Andorra' },
    { key: 'Angola', text: 'Angola', value: 'Angola' },
    { key: 'Anguilla', text: 'Anguilla', value: 'Anguilla' },
    { key: 'Antarctica', text: 'Antarctica', value: 'Antarctica' },
    { key: 'Antigua and Barbuda', text: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
    { key: 'Argentina', text: 'Argentina', value: 'Argentina' },
    { key: 'Armenia', text: 'Armenia', value: 'Armenia' },
    { key: 'Aruba', text: 'Aruba', value: 'Aruba' },
    { key: 'Australia', text: 'Australia', value: 'Australia' },
    { key: 'Austria', text: 'Austria', value: 'Austria' },
    { key: 'Azerbaijan', text: 'Azerbaijan', value: 'Azerbaijan' },
    { key: 'Bahamas', text: 'Bahamas', value: 'Bahamas' },
    { key: 'Bahrain', text: 'Bahrain', value: 'Bahrain' },
    { key: 'Bangladesh', text: 'Bangladesh', value: 'Bangladesh' },
    { key: 'Barbados', text: 'Barbados', value: 'Barbados' },
    { key: 'Belarus', text: 'Belarus', value: 'Belarus' },
    { key: 'Belgium', text: 'Belgium', value: 'Belgium' },
    { key: 'Belize', text: 'Belize', value: 'Belize' },
    { key: 'Benin', text: 'Benin', value: 'Benin' },
    { key: 'Bermuda', text: 'Bermuda', value: 'Bermuda' },
    { key: 'Bhutan', text: 'Bhutan', value: 'Bhutan' },
    { key: 'Bolivia, Plurinational State of', text: 'Bolivia, Plurinational State of', value: 'Bolivia, Plurinational State of' },
    { key: 'Bonaire, Sint Eustatius and Saba', text: 'Bonaire, Sint Eustatius and Saba', value: 'Bonaire, Sint Eustatius and Saba' },
    { key: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
    { key: 'Botswana', text: 'Botswana', value: 'Botswana' },
    { key: 'Bouvet Island', text: 'Bouvet Island', value: 'Bouvet Island' },
    { key: 'Brazil', text: 'Brazil', value: 'Brazil' },
    { key: 'British Indian Ocean Territory', text: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
    { key: 'Brunei Darussalam', text: 'Brunei Darussalam', value: 'Brunei Darussalam' },
    { key: 'Bulgaria', text: 'Bulgaria', value: 'Bulgaria' },
    { key: 'Burkina Faso', text: 'Burkina Faso', value: 'Burkina Faso' },
    { key: 'Burundi', text: 'Burundi', value: 'Burundi' },
    { key: 'Cambodia', text: 'Cambodia', value: 'Cambodia' },
    { key: 'Cameroon', text: 'Cameroon', value: 'Cameroon' },
    { key: 'Canada', text: 'Canada', value: 'Canada' },
    { key: 'Cape Verde', text: 'Cape Verde', value: 'Cape Verde' },
    { key: 'Cayman Islands', text: 'Cayman Islands', value: 'Cayman Islands' },
    { key: 'Central African Republic', text: 'Central African Republic', value: 'Central African Republic' },
    { key: 'Chad', text: 'Chad', value: 'Chad' },
    { key: 'Chile', text: 'Chile', value: 'Chile' },
    { key: 'China', text: 'China', value: 'China' },
    { key: 'Christmas Island', text: 'Christmas Island', value: 'Christmas Island' },
    { key: 'Cocos (Keeling) Islands', text: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
    { key: 'Colombia', text: 'Colombia', value: 'Colombia' },
    { key: 'Comoros', text: 'Comoros', value: 'Comoros' },
    { key: 'Congo', text: 'Congo', value: 'Congo' },
    { key: 'Congo, the Democratic Republic of the', text: 'Congo, the Democratic Republic of the', value: 'Congo, the Democratic Republic of the' },
    { key: 'Cook Islands', text: 'Cook Islands', value: 'Cook Islands' },
    { key: 'Costa Rica', text: 'Costa Rica', value: 'Costa Rica' },
    { key: 'C𴥠d\'Ivoire', text: 'C𴥠d\'Ivoire', value: 'C𴥠d\'Ivoire' },
    { key: 'Croatia', text: 'Croatia', value: 'Croatia' },
    { key: 'Cuba', text: 'Cuba', value: 'Cuba' },
    { key: 'Cura袯', text: 'Cura袯', value: 'Cura袯' },
    { key: 'Cyprus', text: 'Cyprus', value: 'Cyprus' },
    { key: 'Czech Republic', text: 'Czech Republic', value: 'Czech Republic' },
    { key: 'Denmark', text: 'Denmark', value: 'Denmark' },
    { key: 'Djibouti', text: 'Djibouti', value: 'Djibouti' },
    { key: 'Dominica', text: 'Dominica', value: 'Dominica' },
    { key: 'Dominican Republic', text: 'Dominican Republic', value: 'Dominican Republic' },
    { key: 'Ecuador', text: 'Ecuador', value: 'Ecuador' },
    { key: 'Egypt', text: 'Egypt', value: 'Egypt' },
    { key: 'El Salvador', text: 'El Salvador', value: 'El Salvador' },
    { key: 'Equatorial Guinea', text: 'Equatorial Guinea', value: 'Equatorial Guinea' },
    { key: 'Eritrea', text: 'Eritrea', value: 'Eritrea' },
    { key: 'Estonia', text: 'Estonia', value: 'Estonia' },
    { key: 'Ethiopia', text: 'Ethiopia', value: 'Ethiopia' },
    { key: 'Falkland Islands (Malvinas)', text: 'Falkland Islands (Malvinas)', value: 'Falkland Islands (Malvinas)' },
    { key: 'Faroe Islands', text: 'Faroe Islands', value: 'Faroe Islands' },
    { key: 'Fiji', text: 'Fiji', value: 'Fiji' },
    { key: 'Finland', text: 'Finland', value: 'Finland' },
    { key: 'France', text: 'France', value: 'France' },
    { key: "French Guiana", text: "French Guiana", value: "French Guiana" },
    { key: "French Polynesia", text: "French Polynesia", value: "French Polynesia" },
    { key: "French Southern Territories", text: "French Southern Territories", value: "French Southern Territories" },
    { key: "Gabon", text: "Gabon", value: "Gabon" },
    { key: "Gambia", text: "Gambia", value: "Gambia" },
    { key: "Georgia", text: "Georgia", value: "Georgia" },
    { key: "Germany", text: "Germany", value: "Germany" },
    { key: "Ghana", text: "Ghana", value: "Ghana" },
    { key: "Gibraltar", text: "Gibraltar", value: "Gibraltar" },
    { key: "Greece", text: "Greece", value: "Greece" },
    { key: "Greenland", text: "Greenland", value: "Greenland" },
    { key: "Grenada", text: "Grenada", value: "Grenada" },
    { key: "Guadeloupe", text: "Guadeloupe", value: "Guadeloupe" },
    { key: "Guam", text: "Guam", value: "Guam" },
    { key: "Guatemala", text: "Guatemala", value: "Guatemala" },
    { key: "Guernsey", text: "Guernsey", value: "Guernsey" },
    { key: "Guinea", text: "Guinea", value: "Guinea" },
    { key: "Guinea-Bissau", text: "Guinea-Bissau", value: "Guinea-Bissau" },
    { key: "Guyana", text: "Guyana", value: "Guyana" },
    { key: "Haiti", text: "Haiti", value: "Haiti" },
    { key: "Heard Island and McDonald Islands", text: "Heard Island and McDonald Islands", value: "Heard Island and McDonald Islands" },
    { key: "Holy See (Vatican City State)", text: "Holy See (Vatican City State)", value: "Holy See (Vatican City State)" },
    { key: "Honduras", text: "Honduras", value: "Honduras" },
    { key: "Hong Kong", text: "Hong Kong", value: "Hong Kong" },
    { key: "Hungary", text: "Hungary", value: "Hungary" },
    { key: "Iceland", text: "Iceland", value: "Iceland" },
    { key: "India", text: "India", value: "India" },
    { key: "Indonesia", text: "Indonesia", value: "Indonesia" },
    { key: "Iran, Islamic Republic of", text: "Iran, Islamic Republic of", value: "Iran, Islamic Republic of" },
    { key: "Iraq", text: "Iraq", value: "Iraq" },
    { key: "Ireland", text: "Ireland", value: "Ireland" },
    { key: "Isle of Man", text: "Isle of Man", value: "Isle of Man" },
    { key: "Israel", text: "Israel", value: "Israel" },
    { key: "Italy", text: "Italy", value: "Italy" },
    { key: "Jamaica", text: "Jamaica", value: "Jamaica" },
    { key: "Japan", text: "Japan", value: "Japan" },
    { key: "Jersey", text: "Jersey", value: "Jersey" },
    { key: "Jordan", text: "Jordan", value: "Jordan" },
    { key: "Kazakhstan", text: "Kazakhstan", value: "Kazakhstan" },
    { key: "Kenya", text: "Kenya", value: "Kenya" },
    { key: "Kiribati", text: "Kiribati", value: "Kiribati" },
    { key: "Korea, Democratic People's Republic of", text: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
    { key: "Korea, Republic of", text: "Korea, Republic of", value: "Korea, Republic of" },
    { key: "Kuwait", text: "Kuwait", value: "Kuwait" },
    { key: "Kyrgyzstan", text: "Kyrgyzstan", value: "Kyrgyzstan" },
    { key: "Lao People's Democratic Republic", text: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic" },
    { key: "Latvia", text: "Latvia", value: "Latvia" },
    { key: "Lebanon", text: "Lebanon", value: "Lebanon" },
    { key: "Lesotho", text: "Lesotho", value: "Lesotho" },
    { key: "Liberia", text: "Liberia", value: "Liberia" },
    { key: "Libya", text: "Libya", value: "Libya" },
    { key: "Liechtenstein", text: "Liechtenstein", value: "Liechtenstein" },
    { key: "Lithuania", text: "Lithuania", value: "Lithuania" },
    { key: "Luxembourg", text: "Luxembourg", value: "Luxembourg" },
    { key: "Macao", text: "Macao", value: "Macao" },
    { key: "Macedonia, the former Yugoslav Republic of", text: "Macedonia, the former Yugoslav Republic of", value: "Macedonia, the former Yugoslav Republic of" },
    { key: "Madagascar", text: "Madagascar", value: "Madagascar" },
    { key: "Malawi", text: "Malawi", value: "Malawi" },
    { key: "Malaysia", text: "Malaysia", value: "Malaysia" },
    { key: "Maldives", text: "Maldives", value: "Maldives" },
    { key: "Mali", text: "Mali", value: "Mali" },
    { key: "Malta", text: "Malta", value: "Malta" },
    { key: "Marshall Islands", text: "Marshall Islands", value: "Marshall Islands" },
    { key: "Martinique", text: "Martinique", value: "Martinique" },
    { key: "Mauritania", text: "Mauritania", value: "Mauritania" },
    { key: "Mauritius", text: "Mauritius", value: "Mauritius" },
    { key: "Mayotte", text: "Mayotte", value: "Mayotte" },
    { key: "Mexico", text: "Mexico", value: "Mexico" },
    { key: "Micronesia, Federated States of", text: "Micronesia, Federated States of", value: "Micronesia, Federated States of" },
    { key: "Moldova, Republic of", text: "Moldova, Republic of", value: "Moldova, Republic of" },
    { key: "Monaco", text: "Monaco", value: "Monaco" },
    { key: "Mongolia", text: "Mongolia", value: "Mongolia" },
    { key: "Montenegro", text: "Montenegro", value: "Montenegro" },
    { key: "Montserrat", text: "Montserrat", value: "Montserrat" },
    { key: "Morocco", text: "Morocco", value: "Morocco" },
    { key: "Mozambique", text: "Mozambique", value: "Mozambique" },
    { key: "Myanmar", text: "Myanmar", value: "Myanmar" },
    { key: "Namibia", text: "Namibia", value: "Namibia" },
    { key: "Nauru", text: "Nauru", value: "Nauru" },
    { key: "Nepal", text: "Nepal", value: "Nepal" },
    { key: "Netherlands", text: "Netherlands", value: "Netherlands" },
    { key: "New Caledonia", text: "New Caledonia", value: "New Caledonia" },
    { key: "New Zealand", text: "New Zealand", value: "New Zealand" },
    { key: "Nicaragua", text: "Nicaragua", value: "Nicaragua" },
    { key: "Niger", text: "Niger", value: "Niger" },
    { key: "Nigeria", text: "Nigeria", value: "Nigeria" },
    { key: "Niue", text: "Niue", value: "Niue" },
    { key: "Norfolk Island", text: "Norfolk Island", value: "Norfolk Island" },
    { key: "Northern Mariana Islands", text: "Northern Mariana Islands", value: "Northern Mariana Islands" },
    { key: "Norway", text: "Norway", value: "Norway" },
    { key: "Oman", text: "Oman", value: "Oman" },
    { key: "Pakistan", text: "Pakistan", value: "Pakistan" },
    { key: "Palau", text: "Palau", value: "Palau" },
    { key: "Palestinian Territory, Occupied", text: "Palestinian Territory, Occupied", value: "Palestinian Territory, Occupied" },
    { key: "Panama", text: "Panama", value: "Panama" },
    { key: "Papua New Guinea", text: "Papua New Guinea", value: "Papua New Guinea" },
    { key: "Paraguay", text: "Paraguay", value: "Paraguay" },
    { key: "Peru", text: "Peru", value: "Peru" },
    { key: "Philippines", text: "Philippines", value: "Philippines" },
    { key: "Pitcairn", text: "Pitcairn", value: "Pitcairn" },
    { key: "Poland", text: "Poland", value: "Poland" },
    { key: "Portugal", text: "Portugal", value: "Portugal" },
    { key: "Puerto Rico", text: "Puerto Rico", value: "Puerto Rico" },
    { key: "Qatar", text: "Qatar", value: "Qatar" },
    { key: "R궮ion", text: "R궮ion", value: "R궮ion" },
    { key: "Romania", text: "Romania", value: "Romania" },
    { key: "Russian Federation", text: "Russian Federation", value: "Russian Federation" },
    { key: "Rwanda", text: "Rwanda", value: "Rwanda" },
    { key: "Saint Barthꭥmy", text: "Saint Barthꭥmy", value: "Saint Barthꭥmy" },
    { key: "Saint Helena, Ascension and Tristan da Cunha", text: "Saint Helena, Ascension and Tristan da Cunha", value: "Saint Helena, Ascension and Tristan da Cunha" },
    { key: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { key: "Saint Lucia", text: "Saint Lucia", value: "Saint Lucia" },
    { key: "Saint Martin (French part)", text: "Saint Martin (French part)", value: "Saint Martin (French part)" },
    { key: "Saint Pierre and Miquelon", text: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
    { key: "Saint Vincent and the Grenadines", text: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
    { key: "Samoa", text: "Samoa", value: "Samoa" },
    { key: "San Marino", text: "San Marino", value: "San Marino" },
    { key: "Sao Tome and Principe", text: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { key: "Saudi Arabia", text: "Saudi Arabia", value: "Saudi Arabia" },
    { key: "Senegal", text: "Senegal", value: "Senegal" },
    { key: "Serbia", text: "Serbia", value: "Serbia" },
    { key: "Seychelles", text: "Seychelles", value: "Seychelles" },
    { key: "Sierra Leone", text: "Sierra Leone", value: "Sierra Leone" },
    { key: "Singapore", text: "Singapore", value: "Singapore" },
    { key: "Sint Maarten (Dutch part)", text: "Sint Maarten (Dutch part)", value: "Sint Maarten (Dutch part)" },
    { key: "Slovakia", text: "Slovakia", value: "Slovakia" },
    { key: "Slovenia", text: "Slovenia", value: "Slovenia" },
    { key: "Solomon Islands", text: "Solomon Islands", value: "Solomon Islands" },
    { key: "Somalia", text: "Somalia", value: "Somalia" },
    { key: "South Africa", text: "South Africa", value: "South Africa" },
    { key: "South Georgia and the South Sandwich Islands", text: "South Georgia and the South Sandwich Islands", value: "South Georgia and the South Sandwich Islands" },
    { key: "South Sudan", text: "South Sudan", value: "South Sudan" },
    { key: "Spain", text: "Spain", value: "Spain" },
    { key: "Sri Lanka", text: "Sri Lanka", value: "Sri Lanka" },
    { key: "Sudan", text: "Sudan", value: "Sudan" },
    { key: "Suriname", text: "Suriname", value: "Suriname" },
    { key: "Svalbard and Jan Mayen", text: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
    { key: "Swaziland", text: "Swaziland", value: "Swaziland" },
    { key: "Sweden", text: "Sweden", value: "Sweden" },
    { key: "Switzerland", text: "Switzerland", value: "Switzerland" },
    { key: "Syrian Arab Republic", text: "Syrian Arab Republic", value: "Syrian Arab Republic" },
    { key: "Taiwan, Province of China", text: "Taiwan, Province of China", value: "Taiwan, Province of China" },
    { key: "Tajikistan", text: "Tajikistan", value: "Tajikistan" },
    { key: "Tanzania, United Republic of", text: "Tanzania, United Republic of", value: "Tanzania, United Republic of" },
    { key: "Thailand", text: "Thailand", value: "Thailand" },
    { key: "Timor-Leste", text: "Timor-Leste", value: "Timor-Leste" },
    { key: "Togo", text: "Togo", value: "Togo" },
    { key: "Tokelau", text: "Tokelau", value: "Tokelau" },
    { key: "Tonga", text: "Tonga", value: "Tonga" },
    { key: "Trinidad and Tobago", text: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { key: "Tunisia", text: "Tunisia", value: "Tunisia" },
    { key: "Turkey", text: "Turkey", value: "Turkey" },
    { key: "Turkmenistan", text: "Turkmenistan", value: "Turkmenistan" },
    { key: "Turks and Caicos Islands", text: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
    { key: "Tuvalu", text: "Tuvalu", value: "Tuvalu" },
    { key: "Uganda", text: "Uganda", value: "Uganda" },
    { key: "Ukraine", text: "Ukraine", value: "Ukraine" },
    { key: "United Arab Emirates", text: "United Arab Emirates", value: "United Arab Emirates" },
    { key: "United Kingdom", text: "United Kingdom", value: "United Kingdom" },
    { key: "United States", text: "United States", value: "United States" },
    { key: "United States Minor Outlying Islands", text: "United States Minor Outlying Islands", value: "United States Minor Outlying Islands" },
    { key: "Uruguay", text: "Uruguay", value: "Uruguay" },
    { key: "Uzbekistan", text: "Uzbekistan", value: "Uzbekistan" },
    { key: "Vanuatu", text: "Vanuatu", value: "Vanuatu" },
    { key: "Venezuela, Bolivarian Republic of", text: "Venezuela, Bolivarian Republic of", value: "Venezuela, Bolivarian Republic of" },
    { key: "Viet Nam", text: "Viet Nam", value: "Viet Nam" },
    { key: "Virgin Islands, British", text: "Virgin Islands, British", value: "Virgin Islands, British" },
    { key: "Virgin Islands, U.S.", text: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
    { key: "Wallis and Futuna", text: "Wallis and Futuna", value: "Wallis and Futuna" },
    { key: "Western Sahara", text: "Western Sahara", value: "Western Sahara" },
    { key: "Yemen", text: "Yemen", value: "Yemen" },
    { key: "Zambia", text: "Zambia", value: "Zambia" },
    { key: "Zimbabwe", text: "Zimbabwe", value: "Zimbabwe" }
];


export const Loader = () => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            <CircularProgress
                sx={{
                    color: theme.palette.primary.logoColor,
                }}
            />
        </Box>
    )
}

// Function to format time input to add a colon after the second digit
export const formatTimeAddSemicolon = (input) => {
    // Remove all non-digit characters
    const digits = input.replace(/\D/g, '');

    // If there are more than 2 digits, insert a colon after the second digit
    if (digits.length > 2) {
        return digits.slice(0, 2) + ':' + digits.slice(2);
    }

    return digits;
}

export const faqContent = [
    {
        title:
            "How is Sound3 different from traditional music distribution channels/platforms?",
        content:
            "Sound3 leverages web3 technology, distributing music across multiple blockchains and web3 music minting platforms exclusively.",
    },
    {
        title:
            "How much does it cost to use Sound3, and are there any hidden costs?",
        content:
            "Our entry-level Starter pack offers free uploads, allowing artists to keep an impressive 90% of their revenue. For those looking to unlock advanced features, our Pro pack is available at $8 per month or $80 annually (when paid in advance). Please note, there might be a small additional charge on some platforms to cover network fees, ensuring transparent and fair pricing. Find comprehensive details on our pricing page.",
    },
    {
        title: "Can I upload both singles and albums on Sound3?",
        content:
            "Absolutely! Sound3 allows you to upload both singles and albums, giving you the flexibility to showcase your music in various formats.",
    },
    {
        title: "How do I start uploading music on Sound3?",
        content:
            "To start sharing your music with the world, create an account on Sound3, log in, and follow our intuitive upload process. It's designed to be user-friendly and efficient.",
    },
    {
        title: "I'm new to web3 and don't have a wallet, can I still use Sound3?",
        content:
            "Absolutely! We've simplified the process. Just sign up using your Gmail or social login, and our system will automatically set up a web3 wallet for you. Embrace web3 without the complexities.",
    },
    {
        title: "How do NFTs work on Sound3?",
        content:
            "Sound3 mints NFTs for your music, turning each track into a unique digital asset. Fans can buy, sell, and trade these NFTs, creating new opportunities for artists in the web3 space.",
    },
    {
        title: "Are people still buying NFTs? How do NFTs and web3 benefit me as an artist?",
        content: "Yes, the NFT market is thriving. Web3 and NFTs offer artists direct monetization, ownership control, and new revenue streams through unique digital assets.",
    },
    {
        title: 'How long does the process take? Can I start selling my music as soon as I upload on Sound3?',
        content: 'The availability of your music varies across platforms, each with different lead times. However, as a pro user, you gain the advantage of scheduling drops, allowing you to coordinate a unified launch date for your music on all platforms.'
    }, {
        title: 'Do I need to own everything? Can I upload covers and remixes on Sound3?',
        content: 'You need to own the rights to upload. Original content is recommended, but if you have the necessary permissions for covers and remixes, you can upload those as well.'
    },
    {
        title: 'When and how often do I get paid?',
        content: 'Experience the beauty of instant payouts with Sound3. As your music is minted.'
    }
];

export const currencyConvert = async () => {
    console.log("currency converter")
    try {
        // Fetch ADA to USD exchange rate
        const adaResponse = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=XRPUSDT');
        const adaData = await adaResponse.json();
        const adaToUsd = adaData.price
        console.log("ada to usd ", adaToUsd)
        // setAdaToUsd(adaData.cardano.usd);

        // Fetch XRP to USD exchange rate
        const xrpResponse = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=XRPUSDT');
        const xrpData = await xrpResponse.json();
        const xrpToUsd = xrpData.price;
        if (adaToUsd !== null && xrpToUsd !== null) {
            // Perform conversion
            const adaValueInUSD = 5 * adaToUsd;
            const xrpValueInUSD = 22 * xrpToUsd;
            const totalUSD = adaValueInUSD + xrpValueInUSD;
            console.log("totalUSD ", totalUSD)
            const platformFee = totalUSD * 0.10;
            console.log("====platformFee==",platformFee)
            const totalWithFee = (totalUSD + platformFee).toFixed(2);
            console.log("totalWithFee",totalWithFee)
            return totalWithFee;
        } else {
            console.log("Error fetching exchange rates");
            return null;
        }
    } catch (error) {
        console.error('Error fetching exchange rates:', error);
        return null;
    }
};
export const getClientSecret = async () => {
    console.log("client secret")
    try {
        const amount = await currencyConvert()
        console.log("amount ", amount)

        const response = await fetch(`${API_BASE_URL}stripeSecret?amount=${amount}`)
        const data = await response.json()
        console.log("client secret response ", data)
        return data
    } catch (err) {
        console.log("client secret response err ", err)
    }




}

export const convertDateFormat = (dateString) => {
    var dateObject = new Date(dateString);
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var month = months[dateObject.getMonth()];
    var day = dateObject.getDate();
    var year = dateObject.getFullYear();
    var hour = dateObject.getHours();
    var minute = dateObject.getMinutes();
    var second = dateObject.getSeconds();
    var ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12;
    var formattedDate = month + " " + day + ", " + year + ", " + hour + ":" + (minute < 10 ? '0' : '') + minute + " " + ampm;
    return formattedDate;

}
