import React, { useState } from 'react';
import { Button, Box, Typography, Modal } from '@mui/material';
import { Icon } from '@iconify/react';
// import { FaShareAlt } from 'react-icons/fa'; // Example icon
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TumblrIcon, TumblrShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { FaFacebook, FaTwitter, FaInstagram, FaTelegram, FaCopy } from 'react-icons/fa';
// import { FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon, TelegramIcon, LinkedinIcon, RedditIcon, TumblrIcon, PinterestIcon } from 'react-share';

// import { FacebookShareButton, InstagramShareButton } from 'react-share';

const SharePopup = ({ url,open, onClose }) => {
    const copyToClipboard = () => {
        // Copy URL to clipboard logic here
        navigator.clipboard.writeText(url)
            .then(() => {
                alert('URL copied to clipboard');
                // You can add a toast or some indication here that URL has been copied
            })
            .catch((error) => {
                alert('Error copying to clipboard:', error);
                // Handle error if any
            });
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="modal-modal-description" variant='h4' sx={{ mt: 2 }}>Share</Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {/* Facebook Share Button */}
                    <FacebookShareButton url={url}>
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <FacebookIcon size={32} round />
                        </Box>
                    </FacebookShareButton>

                    {/* Twitter Share Button */}
                    <TwitterShareButton url={url} >
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <TwitterIcon size={32} round />
                        </Box>
                    </TwitterShareButton>

                    {/* WhatsApp Share Button */}
                    <WhatsappShareButton url={url} title="Check this out" >
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <WhatsappIcon size={32} round />
                        </Box>
                    </WhatsappShareButton>

                    {/* Email Share Button */}
                    <EmailShareButton url={url}>
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <EmailIcon size={32} round />
                        </Box>
                    </EmailShareButton>

                    {/* Telegram Share Button */}
                    <TelegramShareButton url={url} >
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <TelegramIcon size={32} round />
                        </Box>
                    </TelegramShareButton>

                    {/* LinkedIn Share Button */}
                    <LinkedinShareButton url={url}>
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <LinkedinIcon size={32} round />
                        </Box>
                    </LinkedinShareButton>

                    {/* Reddit Share Button */}
                    <RedditShareButton url={url}>
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <RedditIcon size={32} round />
                        </Box>
                    </RedditShareButton>

                    {/* Tumblr Share Button */}
                    <TumblrShareButton url={url}>
                        <Box variant="outlined" color="primary" sx={{ mr: 2 }}>
                            <TumblrIcon size={32} round />
                        </Box>
                    </TumblrShareButton>

                    <Box variant="outlined" color="primary" sx={{ mr: 2 }} onClick={copyToClipboard}>
                        <FaCopy size={32} />
                    </Box>
                    
                </Typography> 
            </Box>
        </Modal>
    );
};
export default SharePopup;