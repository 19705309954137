import React from 'react'
import CarouselItem from './CarouselItem'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Carousel() {
    const sliderOptions = {
        // infinite: true,
        dots: true,
        // centerMode: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // infinite: true,
                    dots: false,
                }
            },
        ],

    }
    return (
        <Slider {...sliderOptions}>
            <CarouselItem
                avatarSrc="/assets/images/Avatar.png"
                avatarAltText="Remy Sharp"
                heading="CAM MURDOCH キャム"
                tagLink="@cammurdochmusic"
                about="“Revenue wise, Web3 has been more fruitful this and last year...by a lot.”"
            />
            <CarouselItem
                avatarSrc="/assets/images/Avatar_1.png"
                avatarAltText="Remy Sharp"
                heading="NIDO"
                tagLink="@OfficialNID0"
                about="““Appreciate you following Revenue from 4 years of streaming is about $40 for me...””"
            />
            <CarouselItem
                avatarSrc="/assets/images/Avatar_1.png"
                avatarAltText="Remy Sharp"
                heading="NIDO"
                tagLink="@OfficialNID0"
                about="““Appreciate you following Revenue from 4 years of streaming is about $40 for me...””"
            />
            <CarouselItem
                avatarSrc="/assets/images/Avatar.png"
                avatarAltText="Remy Sharp"
                heading="CAM MURDOCH キャム"
                tagLink="@cammurdochmusic"
                about="“Revenue wise, Web3 has been more fruitful this and last year...by a lot.”"
            />
        </Slider>

    )
}

export default Carousel;