// Desc: Artist Information Page
import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../Theme";
import ProMetadataForm from "../../components/ProMetadataForm";
import DoneIcon from '@mui/icons-material/Done';
import { selectCurrentUser } from "../../redux/reducers/userSlice";
import { selectCurrentArtist, setCurrentArtist } from "../../redux/reducers/artistSlice";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import SocialLink from "../../components/SocialLink";
import { Loader } from "../../utils/common";
import { API_BASE_URL } from "../../url";
import { useDispatch } from "react-redux";

const ProMetadata = () => {
  const dispatch = useDispatch()

  const [artistDashboard, setArtistDashboard] = useState(false);
  const [proMetadataInformation, setProMetadataInformation] = useState(false);
  const [artistLoginInformation, setArtistLoginInformation] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  // const currentUser = useAppSelector(selectCurrentUser);

  const navigate = useNavigate();

  const currentArtist = useAppSelector(selectCurrentArtist);
  let currentUser = localStorage.getItem("currentUser");

  if (currentUser) {
    currentUser = JSON.parse(currentUser);
  }

  useEffect(() => {
    // console.log("currentUser in proMetadata ==> ", currentUser);

    if (!currentUser) {
      navigate('/');
    } else {
      setProMetadataInformation(true);
    }
  }, [currentUser, navigate]);

  const artistLogin = async () => {
    const formData = new FormData();
    formData.append("email", currentUser.email);
    formData.append("typeOfLogin", currentUser.typeOfLogin);

    try {
      const response = await fetch(API_BASE_URL + 'artistLogin', {
        method: "POST",
        body: formData,
      });

      // console.log("Response in artist login ==> ", response);

      if (!response.ok) {
        console.log("Error in fetching artist login information");
      } else {
        const responseData = await response.json();

        setArtistLoginInformation(responseData.data);

        // console.log("Response Data in artist login ==> ", responseData.data);

        if (responseData?.data?.artist) {
          dispatch(setCurrentArtist(responseData.data))

          // setIsLoading(false);
        }
      }
    } catch (error) {
      // console.log("Error in fetching artist login information ==> ", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // console.log("artistLoginInformation ==> ", !artistLoginInformation, currentUser, currentUser.email, currentUser.typeOfLogin);
    if (!artistLoginInformation && currentUser && currentUser.email && currentUser.typeOfLogin) {
      // console.log("in condition artistLoginInformation ==> ", artistLoginInformation, currentUser);
      artistLogin();
    }
  }, [currentUser, navigate, artistLoginInformation, dispatch])


  useEffect(() => {
    if (artistDashboard)
      navigate('/artistDashboard');

    // console.log("packageInformation in onboarding ==> ", packageInformation);
  }, [artistDashboard, proMetadataInformation, navigate])

  return (
    <>
      {proMetadataInformation && !isLoading ?
        <>
          <Box>
            <Container maxWidth="xl" sx={{ height: "100%" }}>
              <Box sx={{
                padding: {
                  xs: '40px 0px 0px',
                  sm: '40px 0px 0px',
                  md: '30px 0px',
                  lg: '30px 0px',

                },
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'start',
                  lg: 'start',

                },
                gap: '20px'
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }} href="#" component={"a"}
                // className="revealator-slideright revealator-duration15 revealator-delay5"
                >
                  <Box sx={{
                    width: {
                      xs: '104px',
                      sm: '104px',
                      md: '100px',
                      lg: '140px',
                    },
                    height: {
                      xs: '32px',
                      sm: '32px',
                      md: '35px',
                      lg: '43px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/logo.png"} alt="Logo"
                  />
                </Box>

              </Box>

            </Container >
          </Box>

          <Container maxWidth="xl"
            sx={{
              overflow: 'hidden',
            }}
          >


            <Box
              sx={{
                padding: {
                  xs: '20px 0px 50px',
                  sm: '20px 0px 50px',
                  md: '30px 0px 70px',
                  lg: '30px 0px 70px',
                },
              }}
            >
              {/* Upload your Metadata Part  */}
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.primary.logoColor,
                      fontWeight: 600,
                      fontSize: {
                        xs: "24px",
                        sm: "24px",
                        md: "24px",
                        lg: "24px",
                      },
                      textAlign: {
                        xs: 'center',
                        sm: 'center',
                        md: 'start',
                        lg: 'start',
                      },

                    }}
                  >
                    Upload your Metadata
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  {/* buttons here */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        xs: 'space-between',
                        sm: 'space-between',
                        md: 'start',
                        lg: 'start',
                      },
                      gap: "20px",
                      width: "100%",
                      paddingTop: "40px",
                      paddingBottom: "50px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                        },
                        borderRadius: "20px",
                        padding: {
                          xs: "8px 8px 8px 8px",
                          sm: "8px 8px 8px 8px",
                          md: "8px 24px 8px 8px",
                          lg: "8px 24px 8px 8px",

                        },
                        flexDirection: {
                          xs: 'column',
                          sm: 'column',
                          md: 'row',
                          lg: 'row',
                        },
                        textTransform: "capitalize",
                        border: "2px solid",
                        borderColor: theme.palette.primary.logoColor,
                        gap: "10px",
                        minWidth: {
                          xs: '38%',
                          sm: '38%',
                          md: '225px',
                          lg: '225px',
                        },
                        width: {
                          xs: '38%',
                          sm: '38%',
                          md: 'auto',
                          lg: 'auto',
                        },
                        height: {
                          xs: "100px",
                          sm: "100px",
                          md: "60px",
                          lg: "60px",
                        },
                        justifyContent: {
                          xs: 'space-between',
                          sm: 'space-between',
                          md: 'start',
                          lg: 'start',
                        },
                        pointerEvents: 'none',
                        "&:hover": {
                          borderColor: 'transparent',
                          color: theme.palette.primary.logoColor,
                          border: "2px solid",
                          background: 'transparent !important',
                        }
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "100%",
                          border: "2px solid",
                          borderColor: theme.palette.primary.logoColor,
                          backgroundColor: theme.palette.primary.logoColor,
                          height: "35px",
                          width: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <DoneIcon
                          sx={{
                            color: theme.palette.primary.white,
                            fontSize: '22px',
                          }} />
                      </Box>

                      <Typography
                        sx={{
                          color: theme.palette.primary.logoColor,
                          fontWeight: 800,
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "16px",
                            lg: "16px",
                          },
                        }}>Artist Information</Typography>
                    </Button>
                    <Box
                      sx={{
                        width: "50px",
                        height: "0px",
                        borderBottom: "2px dashed",
                        borderColor: theme.palette.primary.logoColor,
                        opacity: 0.5,
                      }}></Box>
                    <Button
                      variant="outlined"
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                        },
                        borderRadius: "20px",
                        padding: {
                          xs: "8px 8px 8px 8px",
                          sm: "8px 8px 8px 8px",
                          md: "8px 24px 8px 8px",
                          lg: "8px 24px 8px 8px",

                        },
                        flexDirection: {
                          xs: 'column',
                          sm: 'column',
                          md: 'row',
                          lg: 'row',
                        },
                        textTransform: "capitalize",
                        border: "2px solid",
                        borderColor: theme.palette.primary.logoColor,
                        gap: "10px",
                        minWidth: {
                          xs: '38%',
                          sm: '38%',
                          md: '225px',
                          lg: '225px',
                        },
                        width: {
                          xs: '38%',
                          sm: '38%',
                          md: 'auto',
                          lg: 'auto',
                        },
                        height: {
                          xs: "100px",
                          sm: "100px",
                          md: "60px",
                          lg: "60px",
                        },
                        justifyContent: {
                          xs: 'space-between',
                          sm: 'space-between',
                          md: 'start',
                          lg: 'start',
                        },
                        pointerEvents: 'none',
                        "&:hover": {
                          borderColor: 'transparent',
                          color: theme.palette.primary.logoColor,
                          border: "2px solid",
                          background: 'transparent !important',
                        }
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "100%",
                          border: "2px solid",
                          borderColor: theme.palette.primary.logoColor,
                          height: "35px",
                          width: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.logoColor,
                            fontWeight: 600,
                            fontSize: '16px',
                          }}
                        >2</Typography>
                      </Box>

                      <Typography
                        sx={{
                          color: theme.palette.primary.logoColor,
                          fontWeight: 800,
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "16px",
                            lg: "16px",
                          },
                        }}>Pro Metadata</Typography>
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'flex',
                      lg: 'flex',
                    },
                    justifyContent: "start",
                    width: "100%",
                  }}>
                  <Box
                    sx={{
                      width: '25%',
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block',

                      }
                    }}>
                    <Typography
                      variant="h4"
                      component={"h3"}
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "20px",
                          lg: "20px",
                        },
                      }}
                    >
                      Enter all your information
                    </Typography>
                  </Box>

                  {/* form box  */}
                  <Box
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '75%',
                        lg: '75%',
                      },
                      paddingLeft: {
                        xs: '0',
                        sm: '0',
                        md: '50px',
                        lg: '50px',

                      },
                      borderLeft: {
                        xs: 'none',
                        sm: 'none',
                        md: '1px solid',
                        lg: '1px solid',
                      },
                      borderColor: theme.palette.primary.logoColor30,
                    }}>
                    <ProMetadataForm
                      setArtistDashboard={setArtistDashboard}
                      currentUser={currentUser}
                      currentArtist={currentArtist}
                    />


                  </Box>

                </Box>

              </Box>
              {/* Upload your Metadata part end */}

            </Box>
          </Container>
        </> : <Loader />
      }


    </>
  );
};


export default ProMetadata;
