// import styled from '@emotion/styled';
import { Avatar, Box, Button, CircularProgress, List, ListItem, ListItemIcon, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../Theme';
import axios from 'axios';
import { GET_ARTIST, GET_ARTIST_ALBUM, IMAGE_URL } from '../../url';
import { Link, useParams } from "react-router-dom";
import SocialLink from '../../components/SocialLink';
import TrackLink from '../../components/TrackLink';
import { Helmet } from 'react-helmet-async';
import { styles } from './styles';

const Landing = () => {
  const [artist, setArtist] = useState(null)
  const [launchStatus, setLaunchStatus] = useState(null)
  const params = useParams();

  const [daysLeft, setDaysLeft] = useState(0);

  const [isExpirationExist, setIsExpirationExist] = useState(true);

  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const [isDetailsFetched, setIsDetailsFetched] = useState(false);

  useEffect(() => {
    const _getArtist = async () => {
      await axios.get(GET_ARTIST_ALBUM + params.slug)
        .then(res => {
          // console.log("Artist ==> ", res.data);
          if (res?.data?.status) {

            setArtist(res.data)
          } else {
            if (res.data?.artist) {
              setArtist(res.data);
            }
            setLaunchStatus(res?.data?.launchStatus)
          }

          if (res.data?.album?.remaining_time?.days && res?.data?.status) {
            setDaysLeft(res.data.album.remaining_time.days);
          }

          if (res.data.album.is_launch_date == 1 && res?.data?.status) {
            setTime(res.data.album.remaining_time);
          } else if (res?.data?.status) {
            setIsExpirationExist(false);
          }
        })
        .catch(err => {
          // console.log("Error ==> ", err)

        })
        .finally(() => {
          setIsDetailsFetched(true);
        })
    }
    _getArtist();

    const timer = setInterval(() => {
      setTime(prevTime => {
        let { hours, minutes, seconds } = prevTime;

        // Decrement seconds
        seconds--;

        // Adjust minutes and seconds if needed
        if (seconds < 0) {
          seconds = 59;
          minutes--;

          if (minutes < 0) {
            minutes = 59;
            hours--;

            if (hours < 0) {
              // Timer reached zero, clear interval
              clearInterval(timer);
            }
          }
        }

        return { hours, minutes, seconds };
      });
    }, 1000);

    // Cleanup function to clear interval
    return () => clearInterval(timer);

  }, [params])

  // console.log("Artist ==> ", artist);
  // console.log("Params ==> ", params);


  const getArtistImage = (artist_image) => {
    if (artist_image) {

      const coverImage = artist_image.split('/');
      coverImage.splice(0, 1);
      // console.log("coverImage ==> ", coverImage);
      //  coverImage.splice(1, 0, "public");

      const coverImageStr = coverImage.join('/');
      // console.log("coverImageStr ==> ", coverImageStr);
      return IMAGE_URL + coverImageStr
    }

    return null
  }

  // console.log('time ==> ', time);

  if (isDetailsFetched) {
    // console.log('Days Left ==> ', daysLeft);
    // console.log('time Left ==> ', time);
  }

  return (
    <>
      {artist?.artist?.picture && <Helmet>
        {/* <title>{artist.artist && artist.artist.stage_name}</title>
        <meta property="og:title" content={artist.artist && artist.artist.stage_name} /> */}
        <meta property="og:image" content={getArtistImage(artist.artist.picture)} />
      </Helmet>
      }
      {/* artist ? */}
      {isDetailsFetched ?
        <>
          {artist ?
            <Box sx={styles.artistPart}>
              <Box sx={styles.wholeArtistDetails}>
                <Box sx={styles.aritstDetailsContainer}>
                  {artist.artist && <Avatar sx={styles.artistPicture} alt="Remy Sharp" src={getArtistImage(artist.artist.picture)} />}
                  {artist.artist && <Typography sx={styles.artistName} component={"h2"}>{artist.artist?.stage_name ? artist.artist?.stage_name : artist.artist?.artist_name}</Typography>}
                  {artist && <Typography sx={styles.artistAlbumBio} component={"h2"}>{artist.album.bio}</Typography>}
                  {artist && <Typography sx={styles.artistBioText} component={"p"}>{artist.bio}</Typography>}
                </Box>
                {/* socile media icons */}
                <Box sx={styles.socialLinksBox}>

                  {/* social link */}

                  <SocialLink
                    artist={artist}
                  />

                </Box>

                <List sx={styles.list}>
                  {/* Sound3 link */}
                  {!isExpirationExist ?
                    <ListItem sx={styles.listItemContainer}>
                      {/* <Box sx={styles.listItemLeft}>
                        <ListItemIcon sx={styles.listItemIcon}>
                          <Box sx={styles.listItemIconImg}
                            component={"img"}
                            src={"./assets/images/sound3Square.png"} />
                        </ListItemIcon>
                        <Typography sx={styles.sound3text} component={"p"}>
                          Sound<sup>3</sup>
                        </Typography>

                        <Box sx={styles.debitCardIcons}>
                          <Box
                            component={"img"}
                            src={"./assets/images/visa.png"}
                            sx={{
                              width: '15px',
                              height: '15px',
                            }}
                          />
                          <Box
                            component={"img"}
                            src={"./assets/images/masterCard.png"}
                            sx={{
                              width: '15px',
                              height: '15px',
                            }}
                          />

                        </Box>
                      </Box> */}
                      <Link to={`/mint?id=${artist?.album?.slug}`} target="_blank" rel="noopener noreferrer">
                        <Button sx={styles.collectButton} variant="contained">Collect</Button>
                      </Link>
                    </ListItem> 
                    :
                    // (launchStatus && launchStatus !== 'Expire') ?
                    <ListItem sx={styles.listItemContainer}>
                      {/* <Box sx={styles.listItemLeft}>
                        <ListItemIcon sx={styles.listItemIcon}>
                          <Box sx={styles.listItemIconImg} component={"img"} src={"./assets/images/sound3Square.png"} />
                        </ListItemIcon>
                        <Typography sx={styles.sound3text} component={"p"}>Sound<sup>3</sup></Typography>

                        <Box sx={styles.debitCardIcons}>
                          <Box
                            component={"img"}
                            src={"./assets/images/visa.png"}
                            sx={{
                              width: '15px',
                              height: '15px',
                            }}
                          />
                          <Box
                            component={"img"}
                            src={"./assets/images/masterCard.png"}
                            sx={{
                              width: '15px',
                              height: '15px',
                            }}
                          />

                        </Box>
                      </Box> */}
                      <Link to={`/mint?id=${artist?.album?.slug}`} target="_blank" rel="noopener noreferrer">
                        <Button sx={styles.collectButton} variant="contained">Collect</Button>
                      </Link>
                    </ListItem>
                    // : null
                  }

                  {/* Sound3 link */}
                  {/* // sound xyz link */}
                  <TrackLink artist={artist} />


                </List>
              </Box>

            </Box> : (launchStatus && launchStatus === "Coming soon") ?
              <Box sx={styles.comingSoonTextBox}>
                <Typography sx={styles.comingSoonText} component={"h2"}>Coming Soon</Typography>
              </Box>
              :
              // No Artist Found Condition
              <Box sx={styles.noArtisrtFoundTextBox}>
                <Typography sx={styles.noArtistFoundText} component={"h2"}>No Artist Found</Typography>
              </Box>
          }

        </> :

        <Box sx={styles.circularProgressContainer}>
          <CircularProgress
            sx={{
              color: theme.palette.primary.logoColor,
            }}
          />
        </Box>
      }

    </>

  )
}

export default Landing