import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import artistReducer from './reducers/artistSlice';

export default configureStore({
    reducer: {
        user: userReducer,
        artist: artistReducer,
    },
});
