
import theme from "../../Theme"

export const styles = {
    formPartHeaderNew: {
        fontSize: "45px",
        fontWeight: "700",
        lineHeight: "44px",
        color: theme.palette.primary.logoColor,
        textAlign: "center",
    },
    formPartHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: '20px',
        marginBottom: '30px'
    },
}