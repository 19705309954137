import theme from "../../Theme"

export const styles = {
    headerSection: {
        // backgroundColor: theme.palette.primary.main,
        padding: "5px 0px",
        borderBottom: '1px solid #ddd',
    },
    dashBoardIconBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "56px",
        width: "60px",
        backgroundColor: theme.palette.primary.Lightbg,
        borderRadius: "10px",
        color: theme.palette.primary.logoColor,
        fontSize: "24px",
    },
    sideDashboard: {
        padding: "40px 20px 20px 20px",
        borderTop: `1px solid ${theme.palette.primary.borderColor}`,
        position: "relative",
        top: "0px",
        left: "0px",
        height: "100vh",
        width: "10%",
        display: {
            xs: "None",
            sm: "none",
            md: "none",
            lg: "none",
            xl: 'block'
        },
    },
    MainRow: {
        display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "flex",
        },
        justifyContent: "center",
        position: "relative",
        "&::before": {
            content: '""',
            position: "absolute",
            top: "0",
            left: "10%",
            width: "1px",
            height: "100%",
            backgroundColor: theme.palette.primary.borderColor,
            display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: 'block'
            },
        },
    },
    mainTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    DashTitle: {
        fontSize: {
            xs: "22px",
            sm: "22px",
            md: "32px",
            lg: "32px",
        },
        fontWeight: "700",
        letterSpacing: {
            xs: "-0.66px",
            sm: "-0.66px",
            md: "-0.96px",
            lg: "-0.96px",
        },
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.logoColor,
        marginBottom: '10px'
    },
    DashSubTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.textColor,
    },
    CollectionsBox: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "70px",
        backgroundColor: theme.palette.primary.Lightbg2,
        border: `1px solid ${theme.palette.primary.borderColor}`,
        padding: {
            xs: '15px',
            sm: '15px',
            md: '30px',
            lg: '50px',
        },
        borderRadius: "20px",
    },
    CollectionsTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "left",
        },
        color: theme.palette.primary.logoColor,
        marginBottom: '30px'
    },

    CollectionItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: "30px 30px 20px 30px",
        borderRadius: "20px",
        margin: "0px 20px",
        border: `1px solid ${theme.palette.primary.borderColor}`,
    },
    CollectionItemImg: {
        width: '100%',
        height: '100%',
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
    },
    CollectionItemTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.logoColor,
        marginTop: '20px'
    },
    CollectionItemSubTitle: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
        marginTop: '10px'
    },
    pleyIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: '#7C8DB5BF',
        borderRadius: "50%",
        height: "48px",
        width: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.white,
    },



    CollectionsRow: {
        display: "flex",
        flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
        },
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'space-between',
        },
        gap: '30px',
        width: '100%',
        padding: '50px 0px',
    },
    eventSection: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: theme.palette.primary.white,
        border: `1px solid ${theme.palette.primary.borderColor}`,
        padding: {
            xs: '15px',
            sm: '15px',
            md: '30px',
            lg: '30px',
        },
        borderRadius: "20px",
    },
    eventItem: {
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        gap: {
            xs: "10px",
            sm: "10px",
            md: "20px",
            lg: "20px",
        },
    },
    eventItemImg: {
        width: {
            xs: '30px',
            sm: '30px',
            md: '70px',
            lg: '70px',

        },
        height: {
            xs: '30px',
            sm: '30px',
            md: '70px',
            lg: '70px',
        },
        borderRadius: "10px",
        overflow: "hidden",
        position: "relative",
    },
    eventItemTitle: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "18px",
            lg: "18px",
        },
        fontWeight: "600",
        lineHeight: "24px",

        color: theme.palette.primary.logoColor,
    },
    eventItemTime: {
        fontSize: {
            xs: "10px",
            sm: "10px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },

    CollectionListItem: {
        padding: '30px 0px',
        borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
    },
    eventItemBtn1: {
        backgroundColor: theme.palette.primary.logoColor,
        color: theme.palette.primary.white,
        border: `2px solid ${theme.palette.primary.logoColor}`,
        padding: {
            xs: '5px 10px',
            sm: '5px 10px',
            md: '10px 20px',
            lg: '10px 20px',

        },
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: theme.palette.primary.logoColor,
        },
    },
    eventItemBtn1Text: {
        color: theme.palette.primary.white,
        fontSize: {
            xs: '10px',
            sm: '10px',
            md: '14px',
            lg: '14px',
        },
        fontWeight: '600',
    },
    eventItemBtn2: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `2px solid ${theme.palette.primary.logoColor}`,
        padding: {
            xs: '5px 10px',
            sm: '5px 10px',
            md: '10px 20px',
            lg: '10px 20px',

        },
        borderRadius: '10px',
    },
    eventItemBtn2Text: {
        color: theme.palette.primary.logoColor,
        fontSize: {
            xs: '10px',
            sm: '10px',
            md: '14px',
            lg: '14px',
        },
        fontWeight: '600',
    },

    CollectionList: {
        maxHeight: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '460px',
        },
        overflowY: "auto",
    },
    XlContainer: {
        padding: {
            xs: '20px',
            sm: '20px',
            md: '20px',
            lg: '50px 20px !important',
        },
        width: {
            xs: 'auto',
            sm: 'auto',
            md: 'auto',
            lg: '90%',
        },
    },
    dashBoardBx: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    CollectionscolBox1: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '60%',
            lg: '55%',

        },
    }, CollectionscolBox2: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '40%',
            lg: '45%',
        },
    },
    eventItemImgBox: {
        width: {
            xs: '10%',
            sm: '10%',
            md: '12%',
            lg: '12%',
        },
    },
    eventItemInnerBox: {
        width: {
            xs: '76%',
            sm: '76%',
            md: '70%',
            lg: '70%',
        },
    },
    eventItemBtnGroup: {
        display: "flex",
        gap: {
            xs: "10px",
            sm: "10px",
            md: "20px",
            lg: "20px",
        },
        alignItems: "center",
        marginTop: "10px",
    },
    eventItemTimeBoxOuter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
    },
    eventItemTimeBoxInner: {
        height: '5px',
        width: '5px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.textColor,
    },
    eventItemTimeBox: {
        width: {
            xs: '25%',
            sm: '25%',
            md: '20%',
            lg: '20%',
        },
    },
    CollectionSliderArea: {
        display: {
            xs: 'none',
            sm: 'block',
            md: 'block',
            lg: 'block',
        },
    },
    mobCollectionArea: {
        display: {
            xs: 'grid',
            sm: 'none',
            md: 'none',
            lg: 'none',
        },
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '15px',
    },
    mobCollectionItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: "20px 20px 20px 20px",
        borderRadius: "20px",
        // margin: "0px 10px",
        border: `1px solid ${theme.palette.primary.borderColor}`,
    },
    mobCollectionItemImg: {
        width: '100%',
        height: '100px',
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
    },
    mobCollectionItemTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.logoColor,
        marginTop: '10px'
    },
    mobCollectionItemSubTitle: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
        marginTop: '5px'
    },
    mobpleyIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: '#7C8DB5BF',
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: '16px',
        color: theme.palette.primary.white,
    },
    comingSoonBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",
        borderRadius: "20px",
    },
    comingSoonImg: {
        width: {
            xs: '100px',
            sm: '100px',
            md: '150px',
            lg: '150px',
        },
        height: {
            xs: '100px',
            sm: '100px',
            md: '150px',
            lg: '150px',
        },
    },
    comingSoonText: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: "center",
        color: theme.palette.primary.logoColor,
        marginTop: '20px'
    },

}