// Desc: Styles for CollectionsSlider component
export const styles = {

    nextArrow: {
        position: 'absolute',
        top: '-15%',
        right: '15%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1,
        fontSize: '20px',
        lineHeight: '100%',
        "&:before": {
            display: 'none',
        },
    },
    prevArrow: {
        position: 'absolute',
        top: '-15%',
        right: '18%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1,
        fontSize: '20px',
        lineHeight: '100%',

        "&:before": {
            display: 'none',
        },
    },


}