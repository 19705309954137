import theme from "../../Theme"

export const styles = {
    collectButton: {
        backgroundColor: theme.palette.primary.logoColor,
        color: '#fff',
        borderRadius: '10px',
        padding: {
            xs: '10px',
            sm: '10px',
            md: '10px 30px',
            lg: '10px 30px',
        },
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '17.07px',
        textTransform: 'capitalize',
        border: "1px solid transparent",
        "&:hover": {
            backgroundColor: "transparent",
            border: `1px solid ${theme.palette.primary.logoColor}`,
            color: theme.palette.primary.logoColor,
        }
    },
    debitCardIcons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: {
            xs: '10px 15px',
            sm: '10px 15px',
            md: '10px 30px',
            lg: '10px 30px',
        },
        backgroundColor: theme.palette.primary.Lightbg,
        borderRadius: '30px',
        gap: '5px',
    },
    sound3text: {
        fontSize: {
            xs: '16px',
            sm: '16px',
            md: '18px',
            lg: '18px',
        },
        fontWeight: '600',
        lineHeight: '1',
        color: theme.palette.primary.logoColor,
        marginLeft: '0px'
    },
    listItemIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.Lightbg,
        borderRadius: '50%',
        width: '45px',
        minWidth: '45px',
        height: '45px',
        minHeight: '45px',
    },
    listItemIconImg: {
        height: '35px',
        width: '35px',
        borderRadius: '50%',
    },
    listItemLeftSide: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',

    },
    comingSoonText: {
        fontSize: {
            xs: '20px',
            sm: '20px',
            md: '26px',
            lg: '26px',
        },
        fontWeight: '600',
        lineHeight: "1",
        color: theme.palette.primary.logoColor,
        marginTop: '20px'
    },
    comingSoonTextBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    noArtistFoundText: {
        fontSize: {
            xs: '20px',
            sm: '20px',
            md: '26px',
            lg: '26px',
        },
        fontWeight: '600',
        lineHeight: "1",
        color: theme.palette.primary.logoColor,
        marginTop: '20px'
    },
    noArtisrtFoundTextBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }, circularProgressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    listItemLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',

    },
    listItemContainer: {
        backgroundColor: theme.palette.primary.Lightbg,
        borderRadius: '10px',
        padding: {
            xs: '10px 10px',
            sm: '10px 10px',
            md: '10px 20px',
            lg: '10px 20px',

        },
        gap: '5px',
        justifyContent: 'space-between',
    },
    list: {
        gap: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    socialLinksBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        padding: '20px',
        marginBottom: '20px'
    },
    artistBioText: {
        fontSize: {
            xs: '16px',
            sm: '16px',
            md: '18px',
            lg: '18px',
        },
        fontWeight: '600',
        lineHeight: "1",
        color: theme.palette.primary.textColor,
        marginTop: '10px',
        textAlign: 'center',
        padding: '0 28px',
    },
    artistAlbumBio: {
        fontSize: {
            xs: '14px',
            sm: '14px',
            md: '20px',
            lg: '20px',
        },
        fontWeight: '600',
        lineHeight: "1",
        color: theme.palette.primary.logoColor,
        marginTop: '10px'
    },
    artistName: {
        fontSize: {
            xs: '20px',
            sm: '20px',
            md: '26px',
            lg: '26px',
        },
        fontWeight: '600',
        lineHeight: "1",
        color: theme.palette.primary.logoColor,
        marginTop: '20px'
    },
    artistPicture: {
        height: '80px',
        width: '80px',

    },
    aritstDetailsContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '20px',
    },
    wholeArtistDetails: {
        borderRadius: '30px',
        backgroundImage: `url("./assets/images/newbg.png")`,
        backgroundColor: '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: {
            xs: '360px',
            sm: '360px',
            md: '500px',
            lg: '500px',
        },
        padding: {
            xs: '20px 10px',
            sm: '20px 10px',
            md: '50px 20px',
            lg: '50px 20px',
        },
    },
    artistPart:{
    width: '100%',
        display: 'flex',
            alignItems: 'center',
                justifyContent: 'center',
                    padding: {
        xs: '50px 10px',
            sm: '50px 10px',
                md: '50px 30px',
                    lg: '50px',
              },
}
}