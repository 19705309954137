import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Box, Button, Typography } from "@mui/material";
import theme from "../../Theme";
import { selectCurrentArtist, updateCurrentArtistAlbum } from "../../redux/reducers/artistSlice";
import { styles } from '../CheckOutForm/style';
import { API_BASE_URL } from "../../url";
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ amount, clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const currentArtist = useSelector(selectCurrentArtist);
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!stripe || !clientSecret) return;

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent, error }) => {
            if (error) {
                console.error("Error retrieving payment intent:", error);
                setMessage("Failed to retrieve payment intent.");
            } else {
                console.log("PaymentIntent:", paymentIntent);
            }
        });
    }, [stripe, clientSecret]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            setMessage("Stripe.js has not loaded yet.");
            return;
        }

        setIsProcessing(true);

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `https://sound3.live/stripeSuccess?client_reference_id=${currentArtist.artist.id}_${currentArtist?.album?.album_id}`,
                },
            });

            if (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Payment Failed',
                    text: error.message || "An error occurred during payment.",
                });
                setMessage(`Payment failed: ${error.message || "An error occurred during payment."}`);
                return;
            }

            if (paymentIntent && paymentIntent.status === 'succeeded') {
                console.log("Payment succeeded:", paymentIntent.status);
                // const artistInfo = JSON.parse(localStorage.getItem('artistInfo') || '{}');

                // if (!artistInfo || Object.keys(artistInfo).length === 0) {
                //     setMessage("Invalid artist information.");
                //     return;
                // }

                // const response = await fetch(`${API_BASE_URL}addAlbumTrack`, {
                //     method: "POST",
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify(artistInfo),
                // });

                // if (!response.ok) {
                //     throw new Error(`Error in adding album track: ${response.statusText}`);
                // }

                // const responseData = await response.json();

                // if (responseData?.data?.album) {
                //     dispatch(updateCurrentArtistAlbum(responseData.data));
                //     Swal.fire({
                //         icon: 'success',
                //         title: 'Success!',
                //         text: 'Album track has been added successfully.',
                //     });
                    navigate('/paymentConfirmation');
                // }

                // localStorage.removeItem('artistInfo');
            } else {
                setMessage("Payment not completed.");
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage(`An unexpected error occurred: ${error.message || "Please try again."}`);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Box sx={styles.paymentFormBoxOuter}>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.primary.logoColor,
                    fontWeight: 500,
                    marginBottom: "10px",
                    fontSize: "18px",
                    textAlign: 'center',
                }}
            >
                Amount to be paid: ${(amount / 100).toFixed(2)}
            </Typography>
            <Box sx={styles.paymentFormBox}>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement id="payment-element" />
                    <Button
                        type="submit"
                        sx={styles.submitBtn}
                        disabled={isProcessing || !stripe || !elements}
                    >
                        <span>
                            {isProcessing ? "Processing ... " : "Pay now"}
                        </span>
                    </Button>
                    {message && <div id="payment-message">{message}</div>}
                </form>
            </Box>
        </Box>
    );
};

export default CheckoutForm;
