// import styled from '@emotion/styled';
import { Box, Button, Typography, } from '@mui/material'
import React from 'react'
import theme from '../Theme'
import { Icon } from '@iconify/react';

const MusicPlayerDemo = () => {


  return (
    <Box
      sx={{

        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.white,
        padding: {
          xs: "50px 20px",
          sm: "30px",
        },
        boxShadow: `0px 40px 37px ${theme.palette.primary.ShadowColor}`,
        position: "relative",
        border: `1px solid ${theme.palette.primary.borderColor}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "flex-start",

          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",

          },
          alignItems: "center",
          width: "100%",
          gap: {
            xs: "15px",
            sm: "30px",
          },
        }}
      >
        <Box
          className=" playmusicImg"
          component={"img"}
          src={"/assets/images/music.png"}
          alt="Music"
          sx={{
            width: {
              xs: "200px",
              sm: "270px",
              md: "160px",
            },
            height: {
              xs: "200px",
              sm: "220px",
              md: "155px",
            },
            objectFit: "cover",
            borderRadius: "15px"
          }}
        />
        <Box>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "flex-start",
              lg: "flex-start",
              xl: "flex-start",
            },
            gap: "10px",

          }}>
            <Typography
              component={'h6'}
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                },
                fontWeight: "600",
                lineHeight: {
                  xs: "17.68px",
                  sm: "15.47px",
                },
                color: theme.palette.primary.logoColor,
                textAlign: { xs: "center", sm: "left" }
              }}
            >
              Album
            </Typography>
            <Typography
              component={'h2'}
              sx={{
                fontSize: {
                  xs: "22px",
                  sm: "33px",
                },
                fontWeight: "600",
                lineHeight: {
                  xs: "24.31px",
                  sm: "36.47px",
                },
                color: theme.palette.primary.logoColor,
                textAlign: { xs: "center", sm: "left" }
              }}
            >
              Money Machine
            </Typography>
            <Typography
              component={'p'}
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                },
                fontWeight: "600",
                lineHeight: {
                  xs: "19.89px",
                  sm: "17.68px",
                },
                color: theme.palette.primary.textColor,
                textAlign: {
                  xs: "center",
                  sm: "left"
                }
              }}
            >
              1000 Gecks
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "12px",
                    lg: "14px",
                    xl: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "11.05px",
                    sm: "15.47px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                18 SONGS
              </Typography>
              <Box sx={{
                width: "5px",
                height: "5px",
                backgroundColor: theme.palette.primary.logoColor,
                borderRadius: "50%",
              }} className={"marker"} />
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "12px",
                    lg: "14px",
                    xl: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "11.05px",
                    sm: "15.47px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                1 HOUR AND 6 MINUTES
              </Typography>
              <Box sx={{
                width: "5px",
                height: "5px",
                backgroundColor: theme.palette.primary.logoColor,
                borderRadius: "50%",
              }} className={"marker"} />
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "12px",
                    lg: "14px",
                    xl: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "11.05px",
                    sm: "15.47px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                MAR 24 2023
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "flex-start"
            },
            flexDirection: {
              xs: "column",
              sm: "row"
            },
            alignItems: "center",
            gap: {
              xs: "20px",
              sm: "30px",
            },
            marginTop: "25px",
          }}>
            {/* <Button
              sx={{
                padding: "15px 20px",
                borderRadius: "15px",
                backgroundColor: theme.palette.primary.logoColor,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                '&:hover': {
                  backgroundColor: theme.palette.primary.logoColor,
                }

              }}
            >
              <Box sx={{
                color: theme.palette.primary.white,
                lineHeight: "100%",
                fontSize: "16px",
              }}>
                <Icon icon="gravity-ui:play-fill" />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: theme.palette.primary.white,
                  textTransform: "none",
                }}
              >
                Sell
              </Typography>
            </Button> */}

          </Box>
        </Box>
      </Box>
      <Box sx={{
        width: "100%",
        marginTop: "30px",
      }}>

        <Box component={'ul'} sx={{
          marginTop: "30px",
        }}>
          {/* playlist here  */}
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 0",
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
          }}
            component={"li"}>
            <Box sx={{
              flex: "2.3",
            }}>
              <Typography
                component={'h6'}
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "13.26px",
                    sm: "17.68px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                <span style={
                  {
                    color: theme.palette.primary.textColor,
                    fontWeight: "400",
                    marginRight: "10px",
                  }
                }>1.</span> Growin’ Up and Getting Old
              </Typography>
            </Box>

            <Box sx={{
              flex: "1",
              display: "flex",
              justifyContent: {
                xs: " flex-end",
                sm: "space-between"

              },
              alignItems: "center",
            }} >
              <Typography
                component={'p'}
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "19.89px",
                  color: theme.palette.primary.textColor,
                  display: {
                    xs: "none",
                    sm: "block"

                  },
                }}
              >
                02:55
              </Typography>
              <Button
                sx={{
                  padding: {
                    xs: "5px 5px",
                    sm: "5px 30px",
                  },
                  borderRadius: "5px",
                  backgroundColor: theme.palette.primary.logoColor,
                  color: theme.palette.primary.textColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  '&:hover': {
                    backgroundColor: theme.palette.primary.logoColor,
                  }

                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "17.34px",
                    color: theme.palette.primary.white,

                  }}
                >
                  Play
                </Typography>

              </Button>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 0",
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
          }}
            component={"li"}>
            <Box sx={{
              flex: "2.3",
            }}>
              <Typography
                component={'h6'}
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "13.26px",
                    sm: "17.68px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                <span style={
                  {
                    color: theme.palette.primary.textColor,
                    fontWeight: "400",
                    marginRight: "10px",
                  }
                }>2.</span> She Said
              </Typography>
            </Box>

            <Box sx={{
              flex: "1",
              display: "flex",
              justifyContent: {
                xs: " flex-end",
                sm: "space-between"

              },
              alignItems: "center",
            }} >
              <Typography
                component={'p'}
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "19.89px",
                  color: theme.palette.primary.textColor,
                  display: {
                    xs: "none",
                    sm: "block"

                  },
                }}
              >
                02:55
              </Typography>
              <Button
                sx={{
                  padding: {
                    xs: "5px 5px",
                    sm: "5px 30px",
                  },
                  borderRadius: "5px",
                  backgroundColor: theme.palette.primary.logoColor,
                  color: theme.palette.primary.textColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  '&:hover': {
                    backgroundColor: theme.palette.primary.logoColor,
                  }

                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "17.34px",
                    color: theme.palette.primary.white,

                  }}
                >
                  Play
                </Typography>

              </Button>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 0",
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
          }}
            component={"li"}>
            <Box sx={{
              flex: "2.3",
            }}>
              <Typography
                component={'h6'}
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "13.26px",
                    sm: "17.68px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                <span style={
                  {
                    color: theme.palette.primary.textColor,
                    fontWeight: "400",
                    marginRight: "10px",
                  }
                }>3.</span> Gremlin Path ft Klay
              </Typography>
            </Box>

            <Box sx={{
              flex: "1",
              display: "flex",
              justifyContent: {
                xs: " flex-end",
                sm: "space-between"

              },
              alignItems: "center",
            }} >
              <Typography
                component={'p'}
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "19.89px",
                  color: theme.palette.primary.textColor,
                  display: {
                    xs: "none",
                    sm: "block"

                  },
                }}
              >
                02:55
              </Typography>
              <Button
                sx={{
                  padding: {
                    xs: "5px 5px",
                    sm: "5px 30px",
                  },
                  borderRadius: "5px",
                  backgroundColor: theme.palette.primary.logoColor,
                  color: theme.palette.primary.textColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  '&:hover': {
                    backgroundColor: theme.palette.primary.logoColor,
                  }

                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "17.34px",
                    color: theme.palette.primary.white,

                  }}
                >
                  Play
                </Typography>

              </Button>
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 0",
            borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
          }}
            component={"li"}>
            <Box sx={{
              flex: "2.3",
            }}>
              <Typography
                component={'h6'}
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "13.26px",
                    sm: "17.68px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                <span style={
                  {
                    color: theme.palette.primary.textColor,
                    fontWeight: "400",
                    marginRight: "10px",
                  }
                }>4.</span> Wished ft Leeman
              </Typography>
            </Box>

            <Box sx={{
              flex: "1",
              display: "flex",
              justifyContent: {
                xs: " flex-end",
                sm: "space-between"

              },
              alignItems: "center",
            }} >
              <Typography
                component={'p'}
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "19.89px",
                  color: theme.palette.primary.textColor,
                  display: {
                    xs: "none",
                    sm: "block"

                  },
                }}
              >
                02:55
              </Typography>
              <Button
                sx={{
                  padding: {
                    xs: "5px 5px",
                    sm: "5px 30px",
                  },
                  borderRadius: "5px",
                  backgroundColor: theme.palette.primary.logoColor,
                  color: theme.palette.primary.textColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  '&:hover': {
                    backgroundColor: theme.palette.primary.logoColor,
                  }

                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "17.34px",
                    color: theme.palette.primary.white,

                  }}
                >
                  Play
                </Typography>

              </Button>
            </Box>
          </Box>


        </Box>
      </Box>

    </Box>
  )
}


export default MusicPlayerDemo;