import React from 'react'
import { Avatar, Box, Typography } from '@mui/material';
import theme from '../Theme';

function CarouselItem({ avatarSrc, avatarAltText, heading, tagLink, about }) {
    return (
        <Box
            sx={{
                marginTop: '55px',
                // marginLeft: '20px !important',
                // marginRight: '20px !important'
            }}
            className="revealator-zoomin revealator-duration15 revealator-delay8"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.Lightbg2,
                    padding: '50px 20px 25px',
                    width: '335px',
                    borderRadius: '3px',
                    minHeight: '440px'
                }}
            >
                <Box
                    sx={{
                        position: 'relative'
                    }}
                >
                    <Avatar
                        alt={avatarAltText}
                        src={avatarSrc}
                        sx={{
                            width: '90px',
                            height: '90px',
                            borderRadius: '90px'
                        }}
                    />
                    <Box
                        component={"img"}
                        src={"/assets/images/QuoteBadge.png"} alt="Badge"
                        sx={{
                            width: '28px',
                            height: '28px',
                            position: 'absolute',
                            right: 0,
                            bottom: 0
                        }}
                    />
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            color: theme.palette.primary.logoColor,
                            textAlign: 'center',
                            margin: '18px 0 11px'
                        }}
                    >
                        {heading}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: theme.palette.primary.LightText3,
                            textAlign: 'center',
                            marginBottom: '24px'
                        }}
                    >
                        {tagLink}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: theme.palette.primary.LightText3,
                            textAlign: 'center',
                            lineHeight: '32px',
                            marginBottom: '10px',
                            letterSpacing: '0'
                        }}
                    >
                        {about}
                    </Typography>
                    <Box
                        component={"img"}
                        src={"/assets/images/pajamas_twitter.png"} alt="Badge"
                        sx={{
                            width: '68px',
                            height: '68px',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CarouselItem