import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { styles } from '../Onboarding/style';
import { useNavigate } from 'react-router-dom';

const PaymentConfirmation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/proMetaData");
        }, 4000);

        return () => clearTimeout(timer); 
    }, [navigate]);

    return (
        <div style={{
            display: 'flex',
            height: '100vh',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={styles.formPartHeaderContainer}>
                <Box
                    component="img"
                    alt='CollectionImage'
                    src="/assets/images/correct.png"
                    sx={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                    }}
                />
                <Typography sx={styles.formPartHeaderNew}>
                    Payment Successful
                </Typography>
            </Box>
        </div>
    );
};

export default PaymentConfirmation;
