// import styled from '@emotion/styled';
import { Box, Button, CircularProgress, Tab, Tabs, Typography, } from '@mui/material'
import Slider from '@mui/material/Slider';
import React from 'react'
import theme from '../Theme'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IMAGE_URL } from '../url';
import { Howl } from 'howler';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Loader } from '../utils/common';

const MusicPlayer = ({ artistAlbumTrackDetails, className, id, time }) => {
  const [value, setValue] = React.useState(0);
  const [trackSounds, setTrackSounds] = React.useState([]);
  const [isSoundsLoaded, setIsSoundsLoaded] = React.useState(false);

  const [isPageLoading, setIsPageLoading] = React.useState(true);

  const [trackDuration, setTrackDuration] = React.useState(null);
  // const [currentTime, setCurrentTime] = React.useState(0);

  // const [soundDurations, setSoundDurations] = React.useState({});

  const [isPlaying, setIsPlaying] = React.useState({});

  const playerRefs = React.useRef(artistAlbumTrackDetails?.tracks?.map(() => React.createRef()));

  // console.log("playerRefs ==> ", playerRefs);

  // console.log('Current Time: ', currentTime);

  // console.log('Sound Durations: ', soundDurations);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTrackPath = (track_file) => {
    // console.log("track_file ==> ", track_file);
    if (track_file) {
      //   const trackFile = track_file.split('/');
      //   // console.log("trackFile ==> ", trackFile);
      //   trackFile.splice(1, 0, "storage");

      //   const trackFileStr = trackFile.join('/');
      //   // console.log("trackFileStr ==> ", trackFileStr);
      //   return IMAGE_URL + trackFileStr
      // console.log("IMAGE_URL + track_file ==> ", IMAGE_URL + track_file);
      return IMAGE_URL + track_file
    }

    return null
  }

  // console.log("artistAlbumTrackDetails ==> ", artistAlbumTrackDetails);

  React.useEffect(() => {
    if (artistAlbumTrackDetails?.tracks?.length > 0) {
      const sounds = artistAlbumTrackDetails.tracks.map((track) => {
        if (track.track_file) {
          const sound = new Howl({
            src: [getTrackPath(track.track_file)],
            format: [track.track_file.split('.').pop()],
            html5: true,
          });

          return sound;
        } else {
          return null;
        }
      });

      setTrackSounds(sounds);
    } else {
      setIsPageLoading(false);
    }
  }, [artistAlbumTrackDetails]);

  // console.log("trackSounds ==> ", trackSounds);

  const getAlbumImage = (album_image) => {
    if (album_image) {
      // const coverImage = album_image.split('/');
      // // console.log("coverImage ==> ", coverImage);
      // coverImage.splice(1, 0, "storage");

      // const coverImageStr = coverImage.join('/');
      // // console.log("coverImageStr ==> ", coverImageStr);
      // return IMAGE_URL + coverImageStr
      return IMAGE_URL + album_image
    }

    return null
  }
  React.useEffect(() => {
    let loadedSounds = 0;
    trackSounds.forEach((sound, index) => {
      if (sound) {
        // console.log('Sound: ', sound);
        sound.on('load', function () {
          loadedSounds++;
          if (loadedSounds === trackSounds.length) {
            // console.log('All sounds have loaded');
            getTracksDuration(trackSounds);
            setIsSoundsLoaded(true);
            setIsPageLoading(false);
          }
        });
        sound.on('end', function () {
          console.log('Sound has finished playing');

        });
      }
    });

  }, [trackSounds]);

  const handleTrackPlay = (index) => {
    if (trackSounds[index]) {

      if (isSoundsLoaded) {
        // console.log('PlayerRef ==> ', playerRefs.current[index].current.audio.current);
        playerRefs.current.forEach((ref, i) => {
          if (ref?.current?.audio?.current) {
            if (i !== index) {
              ref.current.audio.current.pause();
            } else {
              ref.current.audio.current.play();
            }
          }
        });

        setIsPlaying({
          [index]: true,
        })
      }

    }
  }

  const handleTrackStop = (index) => {
    if (trackSounds[index]) {
      if (isSoundsLoaded) {
        playerRefs.current.forEach((ref, i) => {
          if (ref?.current?.audio?.current)
            ref.current.audio.current.pause();
        });

        setIsPlaying({
          [index]: false,
        })
      }
    }
  }

  const getTracksDuration = (trackSounds) => {
    const totalSeconds = trackSounds.reduce(
      (acc, track) => acc + track._duration,
      0
    );
    const totalMinutes = totalSeconds / 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    const seconds = Math.floor(totalSeconds % 60);
    // console.log('Total Minutes: ', totalMinutes);
    // console.log('Hours: ', hours);
    // console.log('Minutes: ', minutes);
    // console.log('Seconds: ', seconds);
    if (hours > 0 && minutes > 0 && seconds > 0) {
      if (hours === 1) {
        setTrackDuration(`${hours} HOUR ${minutes} MINUTES ${seconds} SECONDS`);
      } else {
        setTrackDuration(`${hours} HOURS ${minutes} MINUTES ${seconds} SECONDS`);
      }
    } else if (hours === 0 && minutes > 0 && seconds > 0) {
      if (minutes === 1) {
        setTrackDuration(`${minutes} MINUTE ${seconds} SECONDS`);
      } else {
        setTrackDuration(`${minutes} MINUTES ${seconds} SECONDS`);
      }
    } else if (hours === 0 && minutes === 0 && seconds > 0) {
      if (seconds === 1) {
        setTrackDuration(`${seconds} SECOND`);
      } else {
        setTrackDuration(`${seconds} SECONDS`);
      }
    }
  }


  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "75%",
          lg: "75%",
          xl: "75%",
        },
        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.white,
        padding: {
          xs: "50px 20px",
          sm: "30px",
        },
        boxShadow: `0px 40px 37px ${theme.palette.primary.ShadowColor}`,
        position: "relative",
        border: `5px solid ${theme.palette.primary.logoColor}`,
      }}
      className={className}
      id={id}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "flex-start",

          },
          flexDirection: {
            xs: "column",
            sm: "row",

          },
          alignItems: "center",
          width: "100%",
          gap: {
            xs: "15px",
            sm: "30px",
          },
        }}
      >
        <Box
          className=" playmusicImg"
          component={"img"}
          src={artistAlbumTrackDetails?.album?.album_image ? getAlbumImage(artistAlbumTrackDetails.album.album_image) : "/assets/images/album.png"}
          alt="Music"
          sx={{
            width: {
              xs: "200px",
              sm: "270px",
            },
            height: [
              "200px",
              "220px",
            ],
            objectFit: "cover",
            borderRadius: "15px"
          }}
        />
        <Box>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: {
              xs: "center",
              sm: "flex-start"
            },
            gap: "10px",

          }}>
            <Typography
              component={'h6'}
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                },
                fontWeight: "600",
                lineHeight: {
                  xs: "17.68px",
                  sm: "15.47px",
                },
                color: theme.palette.primary.logoColor,
                textAlign: { xs: "center", sm: "left" }
              }}
            >
              {artistAlbumTrackDetails?.album?.album_type === 1 ? "Album" : "Single"}
            </Typography>
            <Typography
              component={'h2'}
              sx={{
                fontSize: {
                  xs: "22px",
                  sm: "33px",
                },
                fontWeight: "600",
                lineHeight: {
                  xs: "24.31px",
                  sm: "36.47px",
                },
                color: theme.palette.primary.logoColor,
                textAlign: { xs: "center", sm: "left" }
              }}
            >
              {artistAlbumTrackDetails?.album?.album_name}
            </Typography>
            <Typography
              component={'p'}
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                },
                fontWeight: "600",
                lineHeight: {
                  xs: "19.89px",
                  sm: "17.68px",
                },
                color: theme.palette.primary.textColor,
                textAlign: {
                  xs: "center",
                  sm: "left"
                }
              }}
            >
              {artistAlbumTrackDetails?.artist?.artist_name} - {
                artistAlbumTrackDetails?.album?.album_type == 0
                  ?
                  (artistAlbumTrackDetails?.tracks?.length ? artistAlbumTrackDetails?.tracks[0]?.track_name : "")
                  :
                  artistAlbumTrackDetails?.album?.album_name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                    lg: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "11.05px",
                    sm: "15.47px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                {artistAlbumTrackDetails?.tracks?.length} {artistAlbumTrackDetails?.tracks?.length > 1 ? "Tracks" : "Track"}
              </Typography>
              <Box sx={{
                width: "5px",
                height: "5px",
                backgroundColor: theme.palette.primary.logoColor,
                borderRadius: "50%",
              }} className={"marker"} />
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                    lg: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "11.05px",
                    sm: "15.47px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                {isPageLoading &&
                  <CircularProgress
                    size={15}
                    sx={{
                      color: theme.palette.primary.logoColor,
                    }}
                  />
                }
                {
                  artistAlbumTrackDetails?.tracks?.length
                    ? trackDuration
                    : "0 hr 0 min"
                }
              </Typography>
              <Box sx={{
                width: "5px",
                height: "5px",
                backgroundColor: theme.palette.primary.logoColor,
                borderRadius: "50%",
              }} className={"marker"} />
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                    lg: "16px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "11.05px",
                    sm: "15.47px",
                  },
                  color: theme.palette.primary.logoColor,
                }}
              >
                {
                  artistAlbumTrackDetails?.album?.release_date &&
                  (() => {
                    const dateObj = new Date(artistAlbumTrackDetails.album.release_date);
                    const day = dateObj.getDate();
                    const month = dateObj.toLocaleString('default', { month: 'short' });
                    const year = dateObj.getFullYear();

                    return `${month} ${day} ${year}`;
                  })()
                }
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "flex-start"
            },
            flexDirection: {
              xs: "column",
              sm: "row"
            },
            alignItems: "center",
            gap: {
              xs: "20px",
              sm: "30px",
            },
            marginTop: "25px",
          }}>
            <Button
              sx={{
                padding: "15px 20px",
                borderRadius: "15px",
                backgroundColor: theme.palette.primary.logoColor,
                color: theme.palette.primary.textColor,
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                textTransform: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                letterSpacing: "-1px",
                gap: "10px",
                '&:hover': {
                  backgroundColor: theme.palette.primary.logoColor,
                }

              }}
            >
              <PlayArrowIcon
                sx={{
                  color: theme.palette.primary.white,
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: theme.palette.primary.white,
                }}
              >
                Buy
              </Typography>
            </Button>
            <Typography
              component={'h6'}
              sx={{
                fontSize: [
                  "14px",
                  "18px",
                ],
                fontWeight: "600",
                lineHeight: "24px",
                color: theme.palette.primary.logoColor,
                letterSpacing: "-1px",
              }}
            >
              {artistAlbumTrackDetails?.album?.is_launch_date == 1 ?
                <>
                  {artistAlbumTrackDetails?.album?.remaining_time?.days > 0 && <>{artistAlbumTrackDetails?.album?.remaining_time?.days} days and </>}
                  {time != null && <>{time?.hours} : {time?.minutes} : {time?.seconds} </>}
                  Left
                </>
                :
                <>No expiration time</>
              }
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        width: "100%",
        marginTop: "30px",
      }}>
        <Tabs className='musicTabs'
          sx={{
            width: "100%",
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.primary.logoColor,
            }
          }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example">
          <Tab className='musicTab' sx={{
            color: value === 0 ? theme.palette.primary.logoColor : theme.palette.primary.textColor,
            fontSize: "16px",
            fontWeight: value === 0 ? "700" : "400",
            lineHeight: "19px",
            textTransform: "none",
            letterSpacing: "-1px",
            padding: "0",
            '&:hover': {
              color: theme.palette.primary.logoColor,
            }
          }} label="TRACKS" />
          <Tab className='musicTab' sx={{
            color: value === 1 ? theme.palette.primary.logoColor : theme.palette.primary.textColor,
            fontSize: "16px",
            fontWeight: value === 1 ? "700" : "400",
            lineHeight: "19px",
            textTransform: "none",
            letterSpacing: "-1px",
            padding: "0",
            '&:hover': {
              color: theme.palette.primary.logoColor,
            }
          }} label="DETAILS" />

        </Tabs>

        <TabPanel value={value} index={0}>
          <Box component={'ul'} sx={{
            marginTop: "30px",
          }}>
            {/* playlist here  */}
            {artistAlbumTrackDetails?.tracks?.map((track, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "15px 0",
                  borderBottom: `1px solid ${theme.palette.primary.tabBoder}`,
                }}
                component={"li"}>

                <Box sx={{
                  flex: "2.3",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "20px",
                }}>
                  <Typography
                    component={'h6'}
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "16px",
                      },
                      fontWeight: "600",
                      lineHeight: {
                        xs: "13.26px",
                        sm: "17.68px",
                      },
                      color: theme.palette.primary.logoColor,
                    }}
                  >
                    <span style={
                      {
                        color: theme.palette.primary.textColor,
                        fontWeight: "400",
                        marginRight: "10px",
                      }
                    }>{index + 1}.</span> {track.track_name}
                  </Typography>

                </Box>



                <Box sx={{
                  flex: "4",
                  display: "flex",
                  justifyContent: {
                    xs: " flex-end",
                    sm: "space-between"

                  },
                  alignItems: "center",
                  gap: "20px",
                }} >
                  {isPageLoading &&
                    <CircularProgress
                      sx={{
                        color: theme.palette.primary.logoColor,
                      }}
                    />
                  }
                  {isSoundsLoaded && trackSounds[index] &&
                    <Box
                      sx={{
                        width: {
                          xs: "70%",
                          sm: "70%",
                        },
                      }}
                    >
                      <AudioPlayer
                        className="trackPlayer"
                        ref={playerRefs.current[index]}
                        src={trackSounds[index]?._src}
                        // onPlay={e => console.log("onPlay")}
                        onEnded={() => {
                          setIsPlaying({
                            [index]: false,
                          });
                        }}
                        customAdditionalControls={[]}
                        customVolumeControls={[]}
                        customControlsSection={[]}
                        style={{
                          backgroundColor: theme.palette.primary.white,
                          borderRadius: "20px",
                        }}
                      />
                    </Box>
                  }

                  {trackSounds[index] && (isPlaying[index] ?
                    <Button
                      sx={{
                        padding: {
                          xs: "5px 5px",
                          sm: "5px 30px",
                        },
                        borderRadius: "5px",
                        backgroundColor: theme.palette.primary.logoColor,
                        color: theme.palette.primary.textColor,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        '&:hover': {
                          backgroundColor: theme.palette.primary.logoColor,
                        }

                      }}

                      onClick={() => {
                        handleTrackStop(index);
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "17.34px",
                          color: theme.palette.primary.white,

                        }}
                      >
                        Stop
                      </Typography>

                    </Button> :

                    <Button
                      sx={{
                        padding: {
                          xs: "5px 5px",
                          sm: "5px 30px",
                        },
                        borderRadius: "5px",
                        backgroundColor: theme.palette.primary.logoColor,
                        color: theme.palette.primary.textColor,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        '&:hover': {
                          backgroundColor: theme.palette.primary.logoColor,
                        }

                      }}

                      onClick={() => {
                        handleTrackPlay(index);
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "17.34px",
                          color: theme.palette.primary.white,

                        }}
                      >
                        Play
                      </Typography>

                    </Button>)
                  }
                </Box>
              </Box>
            ))
            }




          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box component={'ul'} sx={{
            marginTop: "30px",
          }}>
            {/* artist bio here  */}
            <Box sx={{
              display: "flex",
              flexDirection: 'column',
              alignItems: "start",
              gap: "15px",
            }}>
              <Typography
                component={'h6'}
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                  },
                  fontWeight: "600",
                  lineHeight: {
                    xs: "15.47px",
                    sm: "17.68px",
                  },
                  color: theme.palette.primary.logoColor,
                  textAlign: { xs: "center", sm: "left" }
                }}
              >
                Artist Bio
              </Typography>
              <Typography
                component={'p'}
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "18px",
                  },
                  fontWeight: "400",
                  lineHeight: {
                    xs: "17.68px",
                    sm: "19.89px",
                  },
                  color: theme.palette.primary.textColor,
                  textAlign: { xs: "center", sm: "left" }
                }}
              >
                {artistAlbumTrackDetails?.album?.bio}
              </Typography>
            </Box>


          </Box>
        </TabPanel>
      </Box>

    </Box>
  )
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}
export default MusicPlayer;