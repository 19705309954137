export const getApi = async (walletName) => await window.cardano[walletName].enable()

export function isLocalStorageAvailable() {
  const test = "test"

  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      localStorage &&
      localStorage.length !== 0
    )
  }
}

export function getSocialInfoFromLocalStorage(network) {
  return localStorage.getItem(`nufi-social-login-${network}`)
}
