import theme from "../../Theme"

export const styles = {

    DropBox: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
        },
        gap: "20px",
        padding: {
            xs: "20px",
            sm: "20px",
            md: "20px",
            lg: "20px",
            xl: '20px 25px',
        },
        borderRadius: "20px",
        border: `1px solid ${theme.palette.primary.borderColor}`,
        backgroundColor: theme.palette.primary.Lightbg2,
        margin: "20px 0px",
    },
    DropLeftArea: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "20px",
    },
    DropImg: {
        width: {
            xs: '100px',
            sm: '100px',
            md: '120px',
            lg: '120px',
            xl: '130px',
        },
        height: {
            xs: '100px',
            sm: '100px',
            md: '110px',
            lg: '110px',
            xl: '120px',
        },
        borderRadius: "20px",
        overflow: "hidden",
        position: "relative",
    },
    DropContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "space-between",
        flexDirection: "column",
        gap: "4px",
    },
    DropBoxTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "18px",
            lg: "18px",
            lx: "20px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    DropBoxSubTitle: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },
    DropSubTIme: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
    },
    DropBtn: {
        width: {
            xs: '100%',
            sm: '100%',
            md: 'auto',
            lg: 'auto',
        },
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: {
            xs: '8px 10px',
            sm: '8px 10px',
            md: '10px 20px',
            lg: '10px 20px',
        },
        borderRadius: '8px',
        display: "flex",
        justifyContent: "center",
        border: `1px solid ${theme.palette.primary.logoColor}`,
        gap: '10px',
        alignItems: "center",
        "&:hover": {
            backgroundColor: 'transparent',

        },
    },
    DropBoxBtnText: {
        color: theme.palette.primary.logoColor,
        fontSize: {
            xs: '12px',
            sm: '12px',
            md: '14px',
            lg: '14px',
        },
        fontWeight: '600',
    },
    DropBtnIcon: {
        fontSize: {
            xs: '16px',
            sm: '16px',
            md: '20px',
            lg: '20px',
        },
        color: theme.palette.primary.white,
        height: {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '30px',
        },
        width: {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '30px',
        },
        borderRadius: {
            xs: '5px',
            sm: '5px',
            md: '5px',
            lg: '8px',
        },
        backgroundColor: theme.palette.primary.logoColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    DropBoxInfoItem: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
    },
    DropBoxInfoText: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.para,
    },
    DropBoxInfoValue: {
        fontSize: {
            xs: "12px",
            sm: "12px",
            md: "14px",
            lg: "14px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    ViewDataBtn: {
        display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
        },
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        minWidth: 'fit-content',
        width: 'fit-content',
        padding: {
            xs: '8px',
            sm: '8px',
            md: '8px 12px',
            lg: '8px 12px',
        },
        borderRadius: '8px',
        textTransform: 'capitalize',
        boxShadow: 'none',
        fontSize: {
            xs: '10px',
            sm: '10px',
            md: '12px',
            lg: '12px',
        },
        fontWeight: '600',
        lineHeight: '1',
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },

    MintsUl: {
        display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "flex",

        },
        justifyContent: "center",
        alignItems: "center",
        gap: "0px",
    },
    MintsLi: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        padding: "0px 30px",
        borderLeft: `1px solid ${theme.palette.primary.borderColor}`,
    },
    MintIcon: {
        width: '50px',
        height: '50px',
        objectFit: 'cover',
    },
    MintInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0px",
    },
    MintInfoItem: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
    },
    MintInfoText: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "500",
        lineHeight: "24px",
        color: theme.palette.primary.para,
    },
    MintInfoValue: {
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
            lg: "16px",
        },
        fontWeight: "600",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    ButtonGroups: {
        display: {
            xs: "flex",
            sm: "grid",
            md: "grid",
            lg: "grid",
            xl: "none",

        },
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
    },
    DataBtn: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        padding: "10px 20px",
        width: {
            xs: "50%",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
        },
        borderRadius: "8px",
        textTransform: "capitalize",
        boxShadow: 'none',
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1",
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
    MintsBtn: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.logoColor,
        border: `1px solid ${theme.palette.primary.logoColor}`,
        padding: "10px 20px",
        width: {
            xs: "50%",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
        },
        borderRadius: "8px",
        textTransform: "capitalize",
        boxShadow: 'none',
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1",
        "&:hover": {
            backgroundColor: 'transparent',
        },
    },
}