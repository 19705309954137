import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { styles } from "./style";
import axios from "axios";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../url";
import Modal from "react-modal";
import { convertDateFormat } from "../../utils/common";
function AnalyticsItem() {
  const [artistData, setArtistData] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [selectedTrack, setSelectedTrack] = useState(null);
  const [trackDetail, setTrackDetail] = useState(null);
  const [artisanDetail,setArtisanDetail]=useState(null);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const getArtistData = async () => {
    try {
      const responseData = await axios.post(API_BASE_URL + "artistLogin", {
        email: currentUser.email,
        typeOfLogin: currentUser.typeOfLogin,
      });

      console.log("responseData.data", responseData);
      if (!responseData.data.status) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        setIsLoading(false);
      } else {
        const data = responseData.data.data;
        setArtistData(data.artist);
        setTracks(data.tracks);
        setIsLoading(false);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
 
  const getArtisianDetail = async () => {
    if (artistData) {
      try {
        const responseData = await axios.post(
          `https://sound3.live/sound3_newback/public/api/get_tracks_by_artist`,
          { id: artistData.id }
        );
        if (!responseData.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        } else {
          setArtisanDetail(responseData.data.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getTrackDetail = async (trackId) => {
    try {
      const responseData = await axios.post(
        `https://sound3.live/sound3_newback/public/api/get_tracks_detail`,
        {
          id: trackId,
        }
      );
      console.log("===responseData==", responseData);
      if (!responseData.data.status) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      } else {
        const data = responseData.data.data;
        console.log("===data==", data);
        setTrackDetail(data);
        setModalIsOpen(true);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (artistData) {
      getArtisianDetail();
    }
  }, [artistData]);
  useEffect(() => {
    Modal.setAppElement("#root");
    getArtistData();
  }, []);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }
  // const handleViewDataClick = (artist_id) => {
  //   // setSelectedTrack(track);
  //  const artist_id= artistData.id
  //   setModalIsOpen(true);
  // };

  return (
    <Box>
      {artisanDetail && artisanDetail.length ? (
        artisanDetail.map((item, index) => (
          <Box sx={styles.DropBox} key={index}>
            <Box sx={styles.DropLeftArea}>
              <Box sx={styles.DropImg}>
                <Box
                  component={"img"}
                  src={"/assets/images/music1.jpg"}
                  alt="Drop Image"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box sx={styles.DropContent}>
                <Typography variant="h6" sx={styles.DropBoxTitle}>
                  {item?.track_name}
                </Typography>
                <Typography component={"p"} sx={styles.DropBoxSubTitle}>
                  {item?.slug}
                </Typography>
                <Box sx={styles.DropBoxInfoItem}>
                  <Typography component={"span"} sx={styles.DropBoxInfoText}>
                    Total Mints
                  </Typography>
                  <Typography component={"span"} sx={styles.DropBoxInfoValue}>
                    {" "}
                    - 0.0001
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={styles.ViewDataBtn}
                  onClick={() => getTrackDetail(item.artist_id)}
                >
                  View Data
                </Button>
              </Box>
            </Box>
  
            <Box component={"ul"} sx={styles.MintsUl}>
              {[1, 2, 10, 7, 5].map((soundId) => (
                <Box component={"li"} sx={styles.MintsLi} key={soundId}>
                  <Box
                    component={"img"}
                    src={`/assets/images/sound${soundId}.png`}
                    alt={`sound${soundId}`}
                    sx={styles.MintIcon}
                  />
                  <Box sx={styles.MintInfo}>
                    <Box sx={styles.MintInfoItem}>
                      <Typography component={"span"} sx={styles.MintInfoText}>
                        Mints -{" "}
                      </Typography>
                      <Typography component={"span"} sx={styles.MintInfoValue}>
                        {" "}
                        0.0011
                      </Typography>
                    </Box>
                    <Box sx={styles.MintInfoItem}>
                      <Typography component={"span"} sx={styles.MintInfoText}>
                        Earned -{" "}
                      </Typography>
                      <Typography component={"span"} sx={styles.MintInfoValue}>
                        {" "}
                        £600
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
  
            <Box sx={styles.ButtonGroups}>
              <Button variant="contained" sx={styles.DataBtn}>
                View Data
              </Button>
              <Button variant="contained" sx={styles.MintsBtn}>
                View Mint
              </Button>
            </Box>
          </Box>
        ))
      ) : (
        <Typography>No Artisan Details Available</Typography>
      )}
  
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Track Details"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "30px",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "80vh",
            overflow: "auto",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Track Details</h2>
        {trackDetail ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Detail</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Track Name</TableCell>
                  <TableCell>{trackDetail.track_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Track Duration</TableCell>
                  <TableCell>{trackDetail.track_duration}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Artist Name</TableCell>
                  <TableCell>{artistData.artist_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>
                    {convertDateFormat(trackDetail.created_at)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>QR Code</TableCell>
                  <TableCell>
                    {trackDetail.qr_code ? (
                      <img
                        src={`https://sound3.live/sound3_newback/public/qr_codes/${trackDetail.qr_code}`}
                        alt="QR Code"
                        style={{ width: "100px", height: "100px" }}
                      />
                    ) : (
                      "No QR Code Available"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No details available</Typography>
        )}
        <Button
          onClick={() => setModalIsOpen(false)}
          variant="contained"
          sx={{
            marginTop: "20px",
            backgroundColor: "#f44336",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f2f",
            },
          }}
        >
          Close
        </Button>
      </Modal>
    </Box>
  );
  
}

export default AnalyticsItem;
