import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CollectionsSliderItem from '../CullectionsSliderItem/CollectionsSliderItem';
import { Box } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <Box sx={styles.nextArrow}
            onClick={onClick}
        ><Icon icon="flowbite:angle-right-outline" /></Box>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <Box style={styles.prevArrow}
            onClick={onClick}
        ><Icon icon="flowbite:angle-left-outline" /></Box>
    );
}
function CollectionsSlider() {
    const CollectionSliderArea = {
        dots: false,
        arrows: true,
        speed: 500,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                }
            },
            {
                breakpoint: 670,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]

    };
    return (
        <Slider {...CollectionSliderArea}>
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
            <CollectionsSliderItem
                CollectionSrc="/assets/images/music1.jpg"
                CollectionAltText=""
                CollectionItemTitle="$12,567.00"
                CollectionItemSubTitle="Total Earnings"
            />
        </Slider>

    )
};

export default CollectionsSlider;