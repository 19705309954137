import theme from "../../Theme";

export const styles = {
    onboardingContainer: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '60%',
            lg: '60%',
        },
        padding: '50px',
        display:
        {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block'
        },

    },
    onboardingInnerContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'space-between',
        },
        gap: '30px',
        width: '100%',
    },
    sliderPart: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '60%',
            lg: '60%',
        },
        padding: '50px',
        display:
        {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block'
        },

    },
    sliderItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 20px 25px',
        // width: '335px',
        borderRadius: '3px',
        // minHeight: '440px',
    },
    sliderItemImageContainer: {
        width: '400px',
        height: '400px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.Lightbg2,
    },
    sliderItemImage: {
        width: '265px',
        height: '265px',
    },
    sliderItemTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    sliderItemHeader: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '30px',
        color: theme.palette.primary.logoColor,
        textAlign: 'center',
        margin: '18px 0 11px'
    },
    sliderItemSubHeader: {
        fontSize: '16px',
        fontWeight: '500',
        color: theme.palette.primary.LightText3,
        textAlign: 'center',
        marginBottom: '24px'
    },
    formPart: {
        width:
        {
            xs: '100%',
            sm: '50%',
            md: '50%',
            lg: '40%',
        },
        padding:
        {
            xs: '20px',
            sm: '20px',
            md: '30px',
            lg: '50px',
        },
        uploadImageLable: {
            marginBottom: '15px',
            color: theme.palette.primary.logoColor,
            fontWeight: 400,
            width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',

            },
            fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "18px",
            },

        },

    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: {
            xs: "104px",
            sm: "104px",
            md: "104px",
            lg: "139px",
        },
        height: {
            xs: "32px",
            sm: "32px",
            md: "32px",
            lg: "43px",
        },
        margin: '0 auto 30px'
    },
    formPartHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: '20px',
        marginBottom: '30px'
    },
    formPartHeader: {
        fontSize:
        {
            xs: "22px",
            sm: "22px",
            md: "26px",
            lg: "30px",
        },
        fontWeight: "600",
        lineHeight: "44px",
        color: theme.palette.primary.logoColor,
        textAlign:
        {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "left",
        }
        ,
    },
    formPartSubHeader: {
        fontSize:
        {
            xs: "14px",
            sm: "16px",
            md: "16px",
            lg: "20px",
        },
        textAlign:
        {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "left",
        },
        fontWeight: "400",
        lineHeight: "28px",
        color: theme.palette.primary.para,
    },
    uploadImageButton: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',

        },
        height: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '0px',
        color: theme.palette.primary.logoColor,
        background: 'transparent !important',
        borderRadius: "20px",
        padding: "8px 24px 8px 8px",
        textTransform: "capitalize",
        border: "1px dashed",
        boxShadow: 'none !important',
        borderColor: theme.palette.primary.logoColor,

        "&:hover": {
            borderColor: 'transparent',
            color: theme.palette.primary.logoColor,
            border: "1px dashed",
            background: 'transparent !important',
            boxShadow: 'none !important',
        }
    }, continueButton: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "20px",
        color: theme.palette.primary.white,
        padding: '12px 15px',
        marginTop: '50px',
        background: theme.palette.primary.logoColor,
        borderRadius: '10px',
        textTransform: 'capitalize',
        height: '50px',
        boxShadow: '0px 8px 26.85px 1px #00385259',
        "&:hover": {
            background: theme.palette.primary.logoColor,
            boxShadow: '0px 8px 26.85px 1px #00385259',
            color: theme.palette.primary.white,
        }
    },
    formSection: {
        display: "flex",
        flexDirection: "column",
        gap: '10px',
    },
    artistNameLable: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "20px",
        color: theme.palette.primary.logoColor,
    },
    artistNameError: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "20px",
        color: theme.palette.primary.errorText,
    },
    selectedImageName: {
        color: theme.palette.primary.LightText,
        fontWeight: 400,
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "14px",
            lg: "14px",
        },
    },
    selectedImageSupportingDocs: {
        color: theme.palette.primary.LightText,
        fontWeight: 400,
        fontSize: {
            xs: "14px",
            sm: "14px",
            md: "14px",
            lg: "14px",
        },
        textAlign: 'center',
    },
    selectedImageDragDrop: {
        color: theme.palette.primary.LightText,
        fontWeight: 400,
        textAlign: 'center',
        width: '100%',
        fontSize: {
            xs: "18px",
            sm: "18px",
            md: "18px",
            lg: "18px",
        },
    },
    selectedImage: {
        width: '100px',
        height: '100px',
        objectFit: 'contain',
    },
    uploadImageIcon: {
        width: '50px',
        height: '50px',
        objectFit: 'contain',
    },
    imageUploadError: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "20px",
        color: theme.palette.primary.errorText,
    },
    formPartHeaderNew: {
        fontSize: "45px",
        fontWeight: "700",
        lineHeight: "44px",
        color: theme.palette.primary.logoColor,
        textAlign: "center",
    },
}