import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styles } from './style';
import { Icon } from '@iconify/react';
import theme from '../../Theme';
import ShareButtonComponent from './Share';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_BASE_URL, IMAGE_URL } from '../../url';

function QRCodeItem() {
    const [QrData, setQrData] = useState(null);
    const [tracks, setTracks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [qrCodeData, setQrCodeData] = useState([]);
    const [artistData, setArtistData] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const getArtistData = async () => {
        try {
            const responseData = await axios.post(API_BASE_URL + 'artistLogin', {
                email: currentUser.email,
                typeOfLogin: currentUser.typeOfLogin,
            });
            if (!responseData.data.status) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
                setIsLoading(false);
            } else {
                const data = responseData.data.data;
                setArtistData(data.artist);
                setTracks(data.tracks);
                setIsLoading(false);
            }
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    const getQrCode = async (artistId) => {
        try {
            const responseData = await axios.post(`https://sound3.live/sound3_newback/public/api/get_tracks_by_artist`, {
                id: artistId,
            });
          console.log("===responseData==",responseData)
            if (!responseData.data.status) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            } else {
                const data = responseData.data.data;
                console.log("===data==",data)
                setQrCodeData(data); 
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getArtistData();
    }, []);

    useEffect(() => {
        if (artistData?.id) {
            getQrCode(artistData.id);
        }
    }, [artistData]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography>Error: {error.message}</Typography>;
    }

    return (
        <Box sx={styles.DropBox}>
            {qrCodeData.length > 0 ? (
                qrCodeData.map((item, index) => (
                    <Box sx={styles.DropLeftArea} key={index}>
                        <Box sx={styles.DropImg}>
                            <Box
                                component={'img'}
                                src={`https://sound3.live/sound3_newback/public/qr_codes/${item.qr_code}`}
                                alt="QR Code"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                        <Box sx={styles.DropContent}>
                            <Typography variant="h6" sx={styles.DropBoxTitle}>
                                {item.track_name}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    lineHeight: '1',
                                    color: theme.palette.primary.textColor,
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '22px',
                                    }}
                                >
                                    <Icon icon="iconamoon:link-light" />
                                </Box>
                                <Typography component={'p'} sx={styles.DropBoxSubTitle}></Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    lineHeight: '1',
                                    color: theme.palette.primary.textColor,
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '22px',
                                    }}
                                >
                                    <Icon icon="ph:arrow-bend-up-right-fill" />
                                </Box>
                                <Typography component={'a'} href='#' sx={styles.DropBoxSubTitle}>
                                    http://stage3.com/
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={styles.DropRightArea}>
                <ShareButtonComponent />
            </Box>
                    </Box>
                ))
            ) : (
                <Typography style={{textAlign:"center"}}>No tracks available</Typography>
            )}
          
        </Box>
    );
}

export default QRCodeItem;
