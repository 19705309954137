// Desc: Artist Information Page
import { Box, Button, Container, FormControl, FormLabel, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../Theme";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import { selectCurrentUser } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { handleClick } from "../CheckOutForm/CheckOutForm";
import { selectCurrentArtist } from "../../redux/reducers/artistSlice";
import { Loader, currencyConvert } from "../../utils/common";

const Package = () => {

  const [packageInformation, setPackageInformation] = useState(false);
  const [proMetadataInformation, setProMetadataInformation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState()
  const [formData, setFormData] = useState({
    plan: "starter",
    cardHolderName: "",
    cardNumber: "",
    month: "",
    year: "",
    cvv: "",
  });

  const currentUser = useAppSelector(selectCurrentUser);
  const currentArtist = useAppSelector(selectCurrentArtist);
  const navigate = useNavigate();

  useEffect(() => {
    const getCurrency = () => {
      currencyConvert().then((res) => {
        console.log("currency == > ", res)
        setAmount(res)
      })
        .catch((err) => {
          console.log("error in currency convert == > ", err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    getCurrency()
    localStorage.removeItem("stripeInitialized")
  }, [])

  // console.log('Is Loading', isLoading);

  useEffect(() => {
    // console.log("currentUser in package ==> ", currentUser);
    if (!currentUser) {
      navigate('/');
    } else {
      setPackageInformation(true);
    }
  }, [currentUser, navigate]);

  // useEffect(() => {
  //   if (proMetadataInformation)
  //     navigate('/proMetadata');

  // }, [packageInformation, proMetadataInformation, navigate])
  console.log('THE FINAL AMOUNT', amount)


  const handleStarterPlanClick = () => {

    setFormData({
      ...formData,
      plan: "starter"
    });

  }

  console.log("currentUser====> ", currentArtist)
  const handlePayButtonClick = async () => {
    // console.log("formData ==> ", formData);

    setProMetadataInformation(true);
    // window.open(`https://buy.stripe.com/test_9AQ0319fR0uPb3a4gg?client_reference_id=${currentArtist?.artist?.id + "_" +currentArtist?.album?.album_id }`, '_blank');
    // create stripe payment using https://buy.stripe.com/test_9AQ0319fR0uPb3a4gg this payment link and get payment details response
    localStorage.setItem("paymentCreate", true)
    const currentArtistJSON = JSON.stringify(currentArtist)
    navigate("/checkOut", currentArtist)
    // handleClick()
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Box>
        <Container maxWidth="xl" sx={{ height: "100%" }}>
          <Box sx={{
            padding: {
              xs: '40px 0px 0px',
              sm: '40px 0px 0px',
              md: '40px 0px 20px',
              lg: '40px 0px 20px',

            },
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: 'center',

            },
            gap: '20px'
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }} href="#" component={"a"}
            // className="revealator-slideright revealator-duration15 revealator-delay5"
            >
              <Box sx={{
                width: {
                  xs: '104px',
                  sm: '104px',
                  md: '100px',
                  lg: '140px',
                },
                height: {
                  xs: '32px',
                  sm: '32px',
                  md: '35px',
                  lg: '43px',
                },
                objectFit: 'contain',
              }}
                component={"img"}
                src={"/assets/images/logo.png"} alt="Logo"
              />
            </Box>

          </Box>

        </Container >
      </Box>

      <Container maxWidth="xl"
        sx={{
          overflow: 'hidden',
        }}
      >


        <Box
          sx={{
            padding: {
              xs: '20px 0px 50px',
              sm: '20px 0px 50px',
              md: '30px 0px 70px',
              lg: '30px 0px 70px',
            },
          }}
        >
          {/* Upload your Metadata Part  */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.primary.logoColor,
                  fontWeight: 600,
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "36px",
                    lg: "36px",
                  },
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'center',
                  },

                }}
              >
                Choose a Package
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.primary.textColor,
                  fontWeight: 400,
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "18px",
                    lg: "18px",
                  },
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'center',
                  },

                }}
              >
                Choose a package plan that best suits your interests
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: {
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                },
                gap: '50px',
                width: '100%',
                marginTop: '60px',
              }}>
              {/* 2 pricing cards will be here */}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '450px',
                    lg: '450px',
                  },
                  padding: '30px',
                  borderRadius: '15px',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                  backgroundColor: theme.palette.primary.Lightbg2,
                  minHeight: {
                    xs: '500px',
                    sm: '500px',
                    md: '530px',
                    lg: '530px',
                  },
                  justifyContent: 'space-between',
                  border: formData.plan === 'starter' ? '2px solid' : 'none',
                  borderColor: theme.palette.primary.logoColor,
                  cursor: 'pointer',

                }}
                onClick={handleStarterPlanClick}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}>
                  <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                    <Box sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '100%',
                      backgroundColor: 'rgb(0 56 82 / 8%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <AudiotrackIcon sx={{
                        color: theme.palette.primary.logoColor,
                        fontSize: '24px',
                      }} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}>
                    <Typography
                      variant="h4"
                      component={"h3"}
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "22px",
                          sm: "22px",
                          md: "32px",
                          lg: "32px",
                        },
                      }}
                    >
                      Starter Pack - ${amount}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.primary.textColor,
                        fontWeight: 400,
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "18px",
                          lg: "18px",
                        },
                      }}
                    >
                      Get started with our starters package
                    </Typography>

                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 700,
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "18px",
                          lg: "18px",
                        },
                      }}
                    >
                      Starter Pack Includes:
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }} component={"ul"}>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }} component={"li"}>
                        <Box>
                          <DoneOutlinedIcon sx={{
                            color: theme.palette.primary.logoColor,
                            fontSize: '20px',
                          }} />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: theme.palette.primary.textColor,
                            fontWeight: 400,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          Upload to multiple platforms
                        </Typography>
                      </Box>
                    </Box>

                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'space-between',
                }}>
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound6.png"} alt="Logo"
                  />
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound7.png"} alt="Logo"
                  />
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound9.png"} alt="Logo"
                  />
                </Box>
              </Box>

              {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '450px',
                    lg: '450px',
                  },
                  padding: '30px',
                  borderRadius: '15px',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                  backgroundColor: theme.palette.primary.Lightbg2,
                  minHeight: '530px',
                  justifyContent: 'space-between',
                  border: formData.plan === 'pro' ? '2px solid' : 'none',
                  cursor: 'pointer',

                }}
                onClick={handleProPlanClick}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}>
                  <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                    <Box sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '100%',
                      backgroundColor: 'rgb(0 56 82 / 8%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <AudiotrackIcon sx={{
                        color: theme.palette.primary.logoColor,
                        fontSize: '24px',
                      }} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}>
                    <Typography
                      variant="h4"
                      component={"h3"}
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 600,
                        fontSize: {
                          xs: "22px",
                          sm: "22px",
                          md: "32px",
                          lg: "32px",
                        },
                      }}
                    >
                      Pro-Package
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.primary.textColor,
                        fontWeight: 400,
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "18px",
                          lg: "18px",
                        },
                      }}
                    >
                      This package will have a ‘One time Upload cost’ plus Monthly Subscription $6
                    </Typography>

                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.primary.logoColor,
                        fontWeight: 700,
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "18px",
                          lg: "18px",
                        },
                      }}
                    >
                      Pro-Pack Includes:
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }} component={"ul"}>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }} component={"li"}>
                        <Box>
                          <DoneOutlinedIcon sx={{
                            color: theme.palette.primary.logoColor,
                            fontSize: '20px',
                          }} />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: theme.palette.primary.textColor,
                            fontWeight: 400,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          Upload to multiple platforms
                        </Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }} component={"li"}>
                        <Box>
                          <DoneOutlinedIcon sx={{
                            color: theme.palette.primary.logoColor,
                            fontSize: '20px',
                          }} />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: theme.palette.primary.textColor,
                            fontWeight: 400,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          Add multiple metadatas
                        </Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }} component={"li"}>
                        <Box>
                          <DoneOutlinedIcon sx={{
                            color: theme.palette.primary.logoColor,
                            fontSize: '20px',
                          }} />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: theme.palette.primary.textColor,
                            fontWeight: 400,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          Discounted Platform Fees
                        </Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }} component={"li"}>
                        <Box>
                          <DoneOutlinedIcon sx={{
                            color: theme.palette.primary.logoColor,
                            fontSize: '20px',
                          }} />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: theme.palette.primary.textColor,
                            fontWeight: 400,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          Sound Scan Drop
                        </Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }} component={"li"}>
                        <Box>
                          <DoneOutlinedIcon sx={{
                            color: theme.palette.primary.logoColor,
                            fontSize: '20px',
                          }} />
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: theme.palette.primary.textColor,
                            fontWeight: 400,
                            fontSize: {
                              xs: "16px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          Social Links
                        </Typography>
                      </Box>

                    </Box>

                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'space-between',
                }}>
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound5.png"} alt="Logo"
                  />
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound12.png"} alt="Logo"
                  />
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound2.png"} alt="Logo"
                  />
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound4.png"} alt="Logo"
                  />
                  <Box sx={{
                    width: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    height: {
                      xs: '40px',
                      sm: '55px',
                      md: '55px',
                      lg: '55px',
                    },
                    objectFit: 'contain',
                  }}
                    component={"img"}
                    src={"/assets/images/sound1.png"} alt="Logo"
                  />
                </Box>
              </Box> */}

            </Box>
            <Box sx={{
              marginTop: '70px',
              paddingLeft: {
                xs: '0px',
                sm: '0px',
                md: '0px',
                lg: '70px',
              },
              paddingRight: {
                xs: '0px',
                sm: '0px',
                md: '0px',
                lg: '150px',
              },

            }}>
              {/* <Typography variant="h4" sx={{
                color: theme.palette.primary.logoColor,
                fontWeight: 700,
                marginBottom: '40px',
                fontSize: {
                  xs: "18px",
                  sm: "18px",
                  md: "20px",
                  lg: "20px",
                },
                textAlign: {
                  xs: 'center',
                  sm: 'center',
                  md: 'left',
                  lg: 'left',
                },
              }}>
                Card Information
              </Typography> */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'center',
                justifyContent: 'center'

              }}>
                {/* <Box sx={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                  justifyContent: 'start',
                  flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  },
                }}>
                  <FormControl fullWidth>
                    <FormLabel sx={{
                      marginBottom: '10px',
                      color: theme.palette.primary.logoColor,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                      },
                    }} id="demo-row-radio-buttons-group-label">Cardholder Name</FormLabel>
                    <TextField className="cardInputArea" sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '100%',

                      },
                      fontSize: "22px",
                      borderRadius: '10px',
                    }}
                      fullWidth
                      id="cardHolderName"
                      name="cardHolderName"
                      placeholder="Enter name"
                      value={formData.cardHolderName}
                      onChange={handleFormDataChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel sx={{
                      marginBottom: '10px',
                      color: theme.palette.primary.logoColor,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                      },
                    }} id="demo-row-radio-buttons-group-label">Cardholder Number</FormLabel>
                    <TextField className="cardInputArea" sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '100%',

                      },
                      fontSize: "22px",
                      borderRadius: '10px',
                    }}
                      fullWidth
                      id="cardNumber"
                      name="cardNumber"
                      placeholder="Enter card number"
                      value={formData.cardNumber}
                      onChange={handleFormDataChange}
                    />
                  </FormControl>
                </Box>
                <Box sx={{
                  display: 'flex',
                  gap: {
                    xs: '20px',
                    sm: '20px',
                    md: '50px',
                    lg: '50px',

                  },
                  alignItems: 'center',
                  justifyContent: 'start',
                  flexDirection: 'row',
                  marginTop: {
                    xs: '0px',
                    sm: '0px',
                    md: '50px',
                    lg: '50px',
                  }
                }}>
                  <FormControl fullWidth>
                    <FormLabel sx={{
                      marginBottom: '10px',
                      color: theme.palette.primary.logoColor,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                      },
                    }} id="demo-row-radio-buttons-group-label">Month</FormLabel>
                    <TextField className="cardInputArea" sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '100%',
                      },
                      borderColor: theme.palette.primary.inputBorder,
                      fontSize: "22px",
                      borderRadius: '10px',
                    }}
                      fullWidth
                      id="month"
                      name="month"
                      placeholder="MM"
                      value={formData.month}
                      onChange={handleFormDataChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel sx={{
                      marginBottom: '10px',
                      color: theme.palette.primary.logoColor,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                      },
                    }} id="demo-row-radio-buttons-group-label">Year</FormLabel>
                    <TextField className="cardInputArea" sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '100%',

                      },
                      fontSize: "22px",
                      borderRadius: '10px',
                    }}
                      fullWidth
                      id="year"
                      name="year"
                      placeholder="YY"
                      value={formData.year}
                      onChange={handleFormDataChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel sx={{
                      marginBottom: '10px',
                      color: theme.palette.primary.logoColor,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                      },
                    }} id="demo-row-radio-buttons-group-label">CVV</FormLabel>
                    <TextField className="cardInputArea" sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '100%',

                      },
                      fontSize: "22px",
                      borderRadius: '10px',
                    }}
                      fullWidth
                      id="cvv"
                      name="cvv"
                      placeholder="Enter CVV"
                      value={formData.cvv}
                      onChange={handleFormDataChange}
                    />
                  </FormControl>
                </Box> */}

              </Box>
            </Box>
            {/* submit */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  width: 'auto',
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  color: theme.palette.primary.white,
                  padding: '20px 32px 20px 32px',
                  marginTop: '50px',
                  background: theme.palette.primary.logoColor,
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  height: '50px',
                  boxShadow: '0px 8px 26.85px 1px #00385259',
                  "&:hover": {
                    background: theme.palette.primary.logoColor,
                    boxShadow: '0px 8px 26.85px 1px #00385259',
                    color: theme.palette.primary.white,
                  }
                }}
                variant="contained"
                onClick={handlePayButtonClick}
              >Proceed to Pay ${amount} </Button>
            </Box>

          </Box>
          {/* Upload your Metadata part end */}

        </Box>
      </Container>

    </>
  );
};


export default Package;
