import theme from "../../Theme";

export const styles = {
    homeContainer:{
    overflow: 'hidden',
    },
    section1Box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

    },
    mainDescription: {
        color: theme.palette.primary.logoColor,
        textAlign: "center",
        fontSize: {
            xs: "28px",
            sm: "28px",
            md: "54px",
            lg: "54px",
        },
        fontWeight: "700",
        lineHeight: {
            xs: "39.5px",
            sm: "39.5px",
            md: "75.5px",
            lg: "75.5px",
        }
        
    },
    section1Icons: {
        width: {
            xs: "358px",
            sm: "358px",
            md: "850px",
            lg: "913px",
        },
        height: {
            xs: "335px",
            sm: "335px",
            md: "850px",
            lg: "934px",
        },
        marginTop: "46px",
    },
    growYourFanbaseText: {
        fontSize: {
            xs: "22px",
            sm: "22px",
            md: "32px",
            lg: "32px",
        },
        fontWeight: "700",
        letterSpacing: {
            xs: "-0.66px",
            sm: "-0.66px",
            md: "-0.96px",
            lg: "-0.96px",
        },
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.logoColor,
    },
    seamlessAccessToYourCatalogues: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.textColor,
    },
    mobileImageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    mobileImage: {
        width: {
            xs: "241px",
            sm: "241px",
            md: "429px",
            lg: "429px",
        },
        height: {
            xs: "525px",
            sm: "525px",
            md: "947px",
            lg: "947px",
        },
        marginTop: {
            xs: "35px",
            sm: "35px",
            md: "136px",
            lg: "136px",
        },
    },
    reviewsHeader: {
        fontSize: {
            xs: "22px",
            sm: "22px",
            md: "32px",
            lg: "32px",
        },
        fontWeight: "700",
        letterSpacing: {
            xs: "-0.66px",
            sm: "-0.66px",
            md: "-0.96px",
            lg: "-0.96px",
        },
        color: theme.palette.primary.logoColor,
        textAlign: "center",
    },
    reviewsSectionSubText: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
        textAlign: "center",
    },
    carouselContainerStyles: {
        display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
        }
    },
    faqSectionContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    reviewCarouselItemsOuter: {
        display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
        },
        marginTop: "55px",
        alignItems: "center",
        justifyContent: "center",
    },
    reaviewsCarouselItemsInner: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.primary.Lightbg2,
        padding: "61px 47px 25px",
        width: {
            xs: "384px",
            sm: "384px",
            md: "415px",
            lg: "415px",
        },
        borderRadius: "3px",
    },
    reaviewsCarouselItemsAvatar: {
        width: "90px",
        height: "90px",
        borderRadius: "90px",
    },
    reaviewsCarouselItemsHeader: {
        fontSize: "18px",
        fontWeight: "700",
        color: theme.palette.primary.logoColor,
        textAlign: "center",
        margin: "18px 0 11px",
    },
    reviewsCarouselItemsUserHandle: {
        fontSize: "14px",
        fontWeight: "500",
        color: theme.palette.primary.LightText3,
        textAlign: "center",
        marginBottom: "24px",
    },
    reviewsCarouselItemsComment: {
        fontSize: "16px",
        fontWeight: "500",
        color: theme.palette.primary.LightText3,
        textAlign: "center",
        lineHeight: "32px",
        marginBottom: "44px",
        letterSpacing: "0",
    },
    reviewsCarouselItemsImage: {
        width: "28px",
        height: "28px",
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    reviewsCarouselItemsUserName: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    faqsSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    faqsConainer: {
        width: "100%",
        marginTop: {
            xs: "0",
            sm: "0",
            md: "223px",
            lg: "223px",
        },
    },
    faqsHeader: {
        fontSize: {
            xs: "22px",
            sm: "22px",
            md: "32px",
            lg: "32px",
        },
        fontWeight: "700",
        letterSpacing: {
            xs: "-0.66px",
            sm: "-0.66px",
            md: "-0.96px",
            lg: "-0.96px",
        },
        color: theme.palette.primary.logoColor,
        textAlign: "center",
    },
    faqsSubtitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        color: theme.palette.primary.textColor,
        textAlign: "center",
    },
    faqTable: {
        marginTop: "72px",
        backgroundColor: theme.palette.primary.Lightbg2,
        borderRadius: {
            xs: "20px",
            sm: "20px",
            md: "64px",
            lg: "64px",
        },
        padding: {
            xs: "85px 20px",
            sm: "85px 20px",
            md: "107px 98px",
            lg: "107px 98px",
        },
        display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
        },
        
    },
    faqTabs: {
        borderRadius: "8px",
        backgroundColor: theme.palette.primary.white,
        padding: {
            xs: "14px 16px",
            sm: "14px 16px",
            md: "19px 17px",
            lg: "19px 17px",
        },
        marginBottom: "49px",
        marginRight: {
            xs: "12px",
            sm: "12px",
            md: "50px",
            lg: "50px",
        },
        cursor: "pointer",
    },
    faqTabsContainer: {
        width: {
            xs: "100%",
            sm: "100%",
            md: "562px",
            lg: "562px",
        },
        maxHeight: "606px",
        overflowY: {
            xs: "hidden",
            sm: "hidden",
            md: "scroll",
            lg: "scroll",
        },
        overflowX: {
            xs: "scroll",
            sm: "scroll",
            md: "hidden",
            lg: "hidden",
        },
        display: {
            xs: "flex",
            sm: "flex",
            md: "block",
            lg: "block",
        },
    },
    faqContent: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "400",
        lineHeight: {
            xs: "32px",
            sm: "32px",
            md: "49px",
            lg: "49px",
        },
        color: theme.palette.primary.TabText,
        marginLeft: {
            xs: "0",
            sm: "0",
            md: "50px",
            lg: "50px",
        },
        marginTop: {
            xs: "24px",
            sm: "24px",
            md: "0",
            lg: "0",
        },
    },
    faqContentContainer: {
        width: {
            xs: "100%",
            sm: "100%",
            md: "530px",
            lg: "530px",
        },
    }

}