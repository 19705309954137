import theme from "../../Theme";

export const styles = {
    avatar: {
        width: "48px",
        height: "48px",
    },
    fansHeaderContainer:{
    padding: "33px 0px",
        width: "100%",
            display: "flex",
                alignItems: "center",
                    justifyContent: "space-between",
                        gap: "20px",
        },
    dropDownIcon: {
        fontSize: "24px",
        lineHeight: "100%",
        color: '#0000004a',
    },
    profileButton: {
        p: '5px 10px',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        borderRadius: '0',
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    profileButtonContainer: {
        flexGrow: 0,
        visibility: "visible",
        marginLeft: "40px",

    },
    comingSoonPart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        marginRight: "80px",
        alignItems: "center",
        backgroundColor: theme.palette.primary.white,
        borderRadius: "10px",
        padding: "10px 16px",

    },
    eyeIconContainer: {
        fontSize: "24px",
        lineHeight: "100%",
        color: theme.palette.primary.logoColor,
        backgroundColor: '#f0f0f0',
        height: '48px',
        width: '48px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    comingSoonText: {
        fontSize: "16px",
        fontWeight: "800",
        lineHeight: "24px",
        paddingLeft: "12px",
        color: theme.palette.primary.logoColor,
    },
    tokenBalanceText: {
        fontSize: "16px",
        fontWeight: "800",
        lineHeight: "24px",
        color: theme.palette.primary.logoColor,
    },
    headerRightPart: {
        display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",

        },
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        visibility: "visible",
    },
    headerRightPartContainer: {
        display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "flex",
        },
        alignItems: "center",
        justifyContent: "space-between",

    },
    socialContainer: {
        display: {
            xs: "flex",
            sm: "flex",
            md: "none",
            lg: "none",
        },
       
    },
    logo: {
        width: {
            xs: "72px",
            sm: "72px",
            md: "100px",
            lg: "140px",
        },
        height: {
            xs: "22px",
            sm: "22px",
            md: "35px",
            lg: "43px",
        },
        objectFit: "contain",
    },
    logoContainer:  {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}