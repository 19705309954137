// Live Server
export const API_BASE_URL = "https://sound3.co.uk/admin/api/";
export const IMAGE_URL =  "https://sound3.co.uk/admin/storage/";
// Localhost
// export const API_BASE_URL = "http://localhost/sound3-back/api/";
// export const IMAGE_URL = "http://localhost/admin/";
//get artist
export const GET_ARTIST = API_BASE_URL + "tracks/";

export const GET_ARTIST_ALBUM = API_BASE_URL + "album/";

