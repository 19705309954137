import theme from "../../Theme"

export const styles = {
    SwapBg: {
        backgroundColor: theme.palette.primary.swapModalBg,
        padding: '20px',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1000',
        backdropFilter: 'blur(7px)',
    },
    SwapModalouter: {
        backgroundColor: theme.palette.primary.white,
        padding: '20px',
        borderRadius: '10px',
        width: '100%',
        maxWidth: '30%',
        position: 'relative',
        zIndex: '1001',
        boxShadow: '0px 4px 35px 0px rgba(82, 93, 107, 0.03)',
    },
    closedBtn: {
        position: 'absolute',
        top: '15px',
        right: '10px',
        backgroundColor: theme.palette.primary.white,
        boxShadow: '0px 4px 10px 0px rgb(0 0 0 / 22%)',
        color: theme.palette.primary.textColor,
        '&:hover': {
            backgroundColor: theme.palette.primary.white,
        },
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '20px',
    },
    button1: {
        maxWidth: '80px',
        borderRadius: '50px',
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.primary.logoColor}`,
        color: theme.palette.primary.logoColor,
        '&:hover': {
            backgroundColor: "transparent",
        },
    },
    button2: {
        maxWidth: '80px',
        borderRadius: '50px',
        color: theme.palette.primary.logoColor,
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: "transparent"
        },
    },
    SwapModalInnerBox: {
        padding: '20px 30px',
    },
    modalTitle: {
        color: theme.palette.primary.textColor,
        fontWeight: '600',
        fontSize: '16px',
        marginBottom: '20px',
    },
    SwapInbox: {
        backgroundColor: theme.palette.primary.Lightbg,
        padding: '10px',
        borderRadius: '10px',
        margin: '20px 0px',
        border: `1px solid ${theme.palette.primary.borderColor}`,
    },
    SwapInboxTitle: {
        color: theme.palette.primary.logoColor,
        fontWeight: '600',
        fontSize: '16px',
    },
    inboxRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
    SwapInboxtext1: {
        color: theme.palette.primary.logoColor,
        fontWeight: '600',
        fontSize: '18px',
    },
    swipDotBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    SwapInboxtext2: {
        color: theme.palette.primary.logoColor,
        fontWeight: '600',
        fontSize: '16px',
    },
    SwapDot: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: 'rgb(238 238 238)',
        display: 'inline-block',
    },
}