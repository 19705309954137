import theme from "../../Theme"

export const styles = {
    MainRow: {
        display: "flex",
        alignItems: "center",
        flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
        },
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'space-between',
        },
        
        gap: '30px',
        width: '100%',
    },
    Col6: {
        width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '50%',
        },
        padding: '20px',

    },

    nextArrow: {
        position: 'absolute',
        bottom: '-5%',
        right: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1,
        fontSize: '20px',
        lineHeight: '100%',
        "&:before": {
            display: 'none',
        },
    },
    prevArrow: {
        position: 'absolute',
        bottom: '-5%',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1,
        fontSize: '20px',
        lineHeight: '100%',
        "&:before": {
            display: 'none',
        },
    },
    Dashtitle: {
        fontSize: {
            xs: "22px",
            sm: "22px",
            md: "32px",
            lg: "32px",
        },
        fontWeight: "700",
        letterSpacing: {
            xs: "-0.66px",
            sm: "-0.66px",
            md: "-0.96px",
            lg: "-0.96px",
        },
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.logoColor,
        marginBottom: '10px'
    },
    DashSubTitle: {
        fontSize: {
            xs: "16px",
            sm: "16px",
            md: "22px",
            lg: "22px",
        },
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
        },
        color: theme.palette.primary.textColor,
    },
    DashLIst: {
        // display: 'grid',
        // gridTemplateColumns: {
        //     xs: 'repeat(3, 1fr)',
        //     sm: 'repeat(3, 1fr)',
        //     md: 'repeat(5, 1fr)',
        //     lg: 'repeat(5, 1fr)',
        //     xl: 'repeat(5, 1fr)',
        // },
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '10px',
        rowGap: '50px',
        marginTop: '50px',
    },
    DashLIstItem: {
        height: '80px',
        width: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderRadius: '50%',
        border: '3px solid rgba(206, 211, 228, 1)',
        overflow: 'hidden',
        margin: '0 auto',
        marginInline:'0px'
        

    },
    DashLIstItemIcon: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    },

}