import { Box, Button, ListItem, ListItemIcon, Typography, } from '@mui/material'
import React from 'react'
import theme from '../Theme';
const TrackLink = ({ artist }) => {
    return (
        <>
            {/* NFTempo */}
            {/* {artist && artist.nftempo_link && */}
            {artist?.album?.nftempo_link &&
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound1.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>NFTempo</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            <Box component={"img"} src={"./assets/images/sound14.png"} />
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>XRP</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.nftempo_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
            {/* //sound xyz link */}
            {/* {artist && artist.sound_xyz_link && */}
            {artist?.album?.sound_xyz_link &&
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound6.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>Sound xyz</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            <Box component={"img"} src={"./assets/images/sound13.png"} />
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>ETH</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.sound_xyz_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
            {/* //jukebox_link */}
            {artist?.album?.jukebox_link &&
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound9.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>Jukebox</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            <Box component={"img"} src={"./assets/images/sound15.png"} />
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>ADA</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.jukebox_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
            {/* //audius_link */}
            {artist?.album?.audius_link && 
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound3.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>Audius</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            <Box component={"img"} src={"./assets/images/sound16.png"} />
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>SOL</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.audius_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
            {/* /royalio_link */}
            {artist?.album?.royalio_link && 
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound4.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>Royalio</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            <Box component={"img"} src={"./assets/images/sound17.png"} />
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>MATIC</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.royalio_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
            {/* //demu_link */}
            {artist?.album?.demu_link &&
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound5.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>demuLink</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            {/* <WhatsAppIcon sx={{
                                fontSize: '20px',
                            }} /> */}
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>XRP</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.demu_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
            {/* //newm_link */}
            {artist?.album?.newm_link &&
                <ListItem sx={{
                    backgroundColor: theme.palette.primary.Lightbg,
                    borderRadius: '10px',
                    padding: {
                        xs: '10px 10px',
                        sm: '10px 10px',
                        md: '10px 20px',
                        lg: '10px 20px',

                    },
                    gap: '5px',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',

                    }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '50%',
                            width: '45px',
                            minWidth: '45px',
                            height: '45px',
                            minHeight: '45px',
                        }}>
                            <Box sx={{
                                height: '35px',
                                width: '35px',
                            }} component={"img"} src={"./assets/images/sound7.png"} />
                        </ListItemIcon>
                        <Typography sx={{
                            fontSize: {
                                xs: '16px',
                                sm: '16px',
                                md: '18px',
                                lg: '18px',
                            },
                            fontWeight: '600',
                            lineHeight: '1',
                            color: theme.palette.primary.logoColor,
                            marginLeft: '0px'
                        }} component={"p"}>newmLink</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: {
                                xs: '10px 15px',
                                sm: '10px 15px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            backgroundColor: theme.palette.primary.Lightbg,
                            borderRadius: '30px',
                            gap: '5px',
                        }}>
                            {/* <WhatsAppIcon sx={{
                                fontSize: '20px',
                            }} /> */}
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '17.07px',
                                color: theme.palette.primary.logoColor,
                                marginLeft: '0px'
                            }} component={"p"}>XRP</Typography>
                        </Box>
                    </Box>
                    <a href={artist?.album?.newm_link} target="_blank" rel="noopener noreferrer">
                        <Button sx={{
                            backgroundColor: theme.palette.primary.logoColor,
                            color: '#fff',
                            borderRadius: '10px',
                            padding: {
                                xs: '10px',
                                sm: '10px',
                                md: '10px 30px',
                                lg: '10px 30px',
                            },
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '17.07px',
                            textTransform: 'capitalize',
                            border: "1px solid transparent",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme.palette.primary.logoColor}`,
                                color: theme.palette.primary.logoColor,
                            }
                        }} variant="contained">Collect</Button>
                    </a>
                </ListItem>
            }
        </>
    )
}

export default TrackLink